import { FC, useRef, useState } from 'react';
import ModalCalendar from 'ui/Popups/ModalCalendar';
import { format } from 'date-fns';
import useOutsideClick from 'hooks/useOutsideClick';
import { SvgCalendar } from 'ui/SvgIcon/PopUpIcons';
import { getLastDayOfMonth } from './utils';
import { IDateFilter } from './types';

export const DateFilter: FC<IDateFilter> = ({
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	setTypeFilter,
}) => {
	const currentDate = new Date();
	const [monthDate, setMonthDate] = useState<Date>(new Date());
	const [openCalendar, setOpenCalendar] = useState(false);
	const calendarRef = useRef<HTMLDivElement | null>(null);
	useOutsideClick(calendarRef, () => setOpenCalendar(false));

	const handleToggleCalendar = () => setOpenCalendar((prev) => !prev);

	const handleStartDate = (_startDate: Date) => {
		setStartDate(_startDate);
		const newMonthDate = new Date(monthDate);
		newMonthDate.setMonth(_startDate.getMonth());
		newMonthDate.setFullYear(_startDate.getFullYear());
		setMonthDate(newMonthDate);
		setTypeFilter('range');
	};

	return (
		<div className="date-filters">
			<div ref={calendarRef} className="calendar-fields">
				<div className="calendar-fields__item">
					<div className="input input--no-mb">
						<div className="input-wrapper">
							<input
								className="input-item input-item--right-icon "
								onClick={handleToggleCalendar}
								type="text"
								placeholder="From"
								readOnly
								value={startDate ? format(startDate, 'yyyy.MM.dd') : ''}
							/>
							<button
								type="button"
								onClick={handleToggleCalendar}
								className="input-icon input-icon--calendar input-icon--right"
							>
								<SvgCalendar />
							</button>
						</div>
					</div>
				</div>
				<div className="calendar-fields__item">
					<div className="input input--no-mb">
						<div className="input-wrapper">
							<input
								className="input-item input-item--right-icon "
								type="text"
								onClick={handleToggleCalendar}
								placeholder="To"
								value={endDate ? format(endDate, 'yyyy.MM.dd') : ''}
								readOnly
							/>
							<button
								type="button"
								onClick={handleToggleCalendar}
								className="input-icon input-icon--calendar input-icon--right"
							>
								<SvgCalendar />
							</button>
						</div>
					</div>
				</div>
				<div className="calendar-container calendar-zindex">
					{openCalendar && (
						<ModalCalendar
							setStart={handleStartDate}
							setEnd={setEndDate}
							setOpenModal={setOpenCalendar}
							start={startDate}
							end={endDate}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
