import { auth } from './auth/index';
import { wallet } from './wallets';
import { tokens } from './tokens';
import { users } from './users';
import { IApi } from './types';
import { settings } from './settings';
import { tokensLp } from './tokensLp';
import { pools } from './pools';
import { exchange } from './exchange';
import { stats } from './stats';
import { staking } from './staking';
import { referrals } from './referrals/index';
import { dividents } from './dividents';

const api: IApi = {
	auth,
	wallet,
	tokens,
	users,
	settings,
	tokensLp,
	pools,
	exchange,
	stats,
	staking,
	referrals,
	dividents,
};

export { api };
