import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loginRequest } from 'redux/reducers/auth/reducer';
import { getLoginIsLoad } from 'redux/reducers/auth/selectors';
import { LoginForm } from 'components/Forms/LoginForm';
import { ILoginSubmitValue } from 'components/Forms/LoginForm/types';
import Loader from 'ui/Loader';
import { ILoginCredsProps } from './types';
/* eslint-disable */
export const LoginCreds: FC<ILoginCredsProps> = () => {
	const dispatch = useDispatch();

	const loginIsLoad = useSelector(getLoginIsLoad);

	const handleLoginSubmit = (values: ILoginSubmitValue): void => {
		if (!values) return;
		const data = {
			...values,
		};
		const params = { data };
		dispatch(loginRequest(params));
	};

	return (
		<section className="authorization-section">
			<div className="authorization-form">
				<LoginForm loginSubmit={handleLoginSubmit} />
				{loginIsLoad && (
					<div className="auth-loader-wrapper">
						<Loader />
					</div>
				)}
			</div>
		</section>
	);
};
