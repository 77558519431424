import { FC } from 'react';
import { ITokenCreationConfirm } from './types';

const TokenCreationConfirm: FC<ITokenCreationConfirm> = ({ token, logoSrc, onCancel }) => {
	const handleCancel = () => {
		onCancel && onCancel();
	};
	return (
		<div className="popup-window create-token-confirm-modal">
			<div className="popup-window__inside">
				<div className="popup popup--w345">
					<button type="button" onClick={handleCancel} className="popup__close popup__close--type2">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18 6L6 18"
								stroke="#1A203F"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M6 6L18 18"
								stroke="#1A203F"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">Creation of token</p>
					</div>
					<div className="popup-body">
						<div className="popup-info-container">
							<div className="coin-icon">{logoSrc && <img src={logoSrc} alt="" />}</div>
							<div className="create-token">
								<p className="create-token__name">Name</p>
								<p className="create-token__value">{token.name}</p>
							</div>
							<div className="create-token">
								<p className="create-token__name">Symbol</p>
								<p className="create-token__value">{token.symbol}</p>
							</div>
							<div className="create-token">
								<p className="create-token__name">Decimal</p>
								<p className="create-token__value">{token.decimals}</p>
							</div>
							<div className="create-token">
								<p className="create-token__name">Total supply value</p>
								<p className="create-token__value">{token.total_supply}</p>
							</div>
							<div className="create-token create-token--type-block">
								<p className="create-token__name">Wallet address</p>
								<p className="create-token__value text-break">{token.target_wallet_address}</p>
							</div>
							<div className="create-token">
								<p className="create-token__name">Website URL</p>
								<p className="create-token__value">{token.website_url}</p>
							</div>
							<div className="create-token create-token--type-block">
								<p className="create-token__name">Description</p>
								<p className="create-token__value">{token.description}</p>
							</div>
							<div className="buttons-group">
								<button type="submit" className="button button--full-width">
									Create
								</button>
								<button
									type="button"
									onClick={handleCancel}
									className="button button--type3 button--full-width"
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TokenCreationConfirm;
