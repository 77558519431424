/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
// import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
// import { notificationContainer } from 'services/utils/notificationContainer';
import { TWalletsRequest, TWalletsDataResponse } from './types';
import { walletsInitState, getWalletsRequest, getWalletsSuccess } from './reducer';
/* eslint-disable no-debugger */

// =============================================================:
function* getWalletsWorker(action: PayloadAction<TWalletsRequest>) {
	const { payload } = action;
	try {
		const response: TWalletsDataResponse = yield call(api.wallet.getWallets, payload);
		yield put(getWalletsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(walletsInitState());
			responseErrors(error);
		}
		yield put(walletsInitState());
	}
}
// =============================================================:
export function* walletsSaga() {
	yield takeEvery(getWalletsRequest.type, getWalletsWorker);
}
