import React, { useEffect, useMemo } from 'react';

import { ArrowBack, CopySvg } from 'assets/custom-icons';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userProfitRequest } from 'redux/reducers/users/reducer';
import { getProfits } from 'redux/reducers/users/selectors';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { shortNotation } from 'services/utils/textSlice';
import { format } from 'date-fns';
import { notificationContainer } from 'services/utils/notificationContainer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { WEB_URL } from 'services/constants/env';

const UserDetails = () => {
	const { userId } = useParams();
	const dispatch = useDispatch();
	const moneyFormat = useMoneyFormat();

	const profits = useSelector(getProfits);

	const gatoCoin =
		!!profits?.user.wallets.length &&
		profits?.user.wallets[0].balances.find(
			(item) => item.asset && item.asset.symbol === 'GATO' && item.asset.type === 'coin',
		);
	const gusdToken =
		!!profits?.user.wallets.length &&
		profits?.user.wallets[0].balances.find(
			(item) => item.asset && item.asset.symbol === 'GUSD' && item.asset.type === 'token',
		);

	const handleCopy = (text: string) => {
		notificationContainer(`${text} copied successfully!`, 'info');
	};

	const totalAmount = useMemo(() => {
		if (profits?.user.wallets.length) {
			return profits?.user.wallets[0].balances.reduce(
				(acBal, curBal) => acBal + Number(curBal.price_in_usd),
				0,
			);
		}
		return 0;
	}, [profits]);
	// /* eslint-disable */
	useEffect(() => {
		if (userId) {
			dispatch(userProfitRequest(userId));
		}
	}, [dispatch, userId]);

	return (
		<div className="main-content">
			<div className="back">
				<Link to="/" className="back__button">
					<ArrowBack />
				</Link>
				<p className="back__text">
					{profits?.user.data
						? `${profits?.user.data.first_name} ${profits?.user.data.last_name}`
						: profits?.user.email}
				</p>
			</div>
			<div className="content-block">
				<div className="block-header">
					<div className="user-title">
						{profits?.user.data && (
							<span className="user-title__icon">
								<img src={profits?.user.data.avatar_path} alt="" />
							</span>
						)}

						<p className="block-subtitle">User Data</p>
					</div>
				</div>
				<div className="user-info-blocks">
					<div className="user-info-block">
						<div className="info-line">
							<p className="info-line__name">User ID:</p>
							<p className="info-line__value">{profits?.user.id}</p>
						</div>
						<div className="info-line">
							<p className="info-line__name">Name:</p>
							<p className="info-line__value">
								{profits?.user.data ? profits?.user.data.first_name : '--/--'}
							</p>
						</div>
						<div className="info-line">
							<p className="info-line__name">Surname:</p>
							<p className="info-line__value">
								{profits?.user.data ? profits?.user.data.last_name : '--/--'}
							</p>
						</div>
						<div className="info-line">
							<p className="info-line__name">Email:</p>
							<p className="info-line__value">{profits?.user.email}</p>
						</div>
						<div className="info-line">
							<p className="info-line__name">Nickname:</p>
							<p className="info-line__value">
								{profits?.user.data ? profits?.user.data.nick_name : '--/--'}
							</p>
						</div>
					</div>
					<div className="user-info-block">
						<div className="info-line">
							<p className="info-line__name">Date of birth:</p>
							<p className="info-line__value">
								{profits?.user.data && profits?.user.data.birthday
									? format(Date.parse(profits?.user.data.birthday), 'MM/dd/yyy')
									: '--/--'}
							</p>
						</div>
						<div className="info-line">
							<p className="info-line__name">Citizenship:</p>
							<p className="info-line__value">
								{profits?.user.data ? profits?.user.data.nationality : '--/--'}
							</p>
						</div>
						<div className="info-line">
							<p className="info-line__name">Date of registering:</p>
							<p className="info-line__value">
								{profits?.user.created_at &&
									format(Date.parse(profits?.user.created_at), 'MM/dd/yyy')}
							</p>
						</div>
						<div className="info-line">
							<p className="info-line__name">User last log in:</p>
							<p className="info-line__value">
								{profits?.user.lastLogin
									? format(Date.parse(profits?.user.lastLogin.logged_at), 'MM/dd/yyy hh:mm')
									: '--/--'}
							</p>
						</div>
						<div className="info-line">
							<p className="info-line__name">Location:</p>
							<p className="info-line__value">
								{profits?.user.lastLogin ? profits?.user.lastLogin.country : '--/--'}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="content-block">
				<div className="block-header">
					<p className="block-subtitle">Balance</p>
				</div>
				<div className="balance-items">
					<div className="balance-item">
						<p className="balance-item__name">Total balance of last connected wallet:</p>
						<p className="balance-item__value">{moneyFormat(totalAmount, 2, 2)} GUSD</p>
					</div>
					<div className="balance-item">
						<p className="balance-item__name">Infinity referral b2c GATO:</p>
						<p className="balance-item__value">
							{gatoCoin && moneyFormat(profits?.referral_profit[gatoCoin?.asset.id], 2, 18)} GATO
						</p>
					</div>
					<div className="balance-item">
						<p className="balance-item__name">Infinity referal b2c GUSD:</p>
						<p className="balance-item__value">
							{gusdToken && moneyFormat(profits?.referral_profit[gusdToken?.asset.id], 2, 18)} GUSD
						</p>
					</div>
					<div className="balance-item">
						<p className="balance-item__name">Dividends GATO:</p>
						<p className="balance-item__value">
							{gatoCoin && moneyFormat(profits?.dividends[gatoCoin?.asset.id], 2, 18)} GATO
						</p>
					</div>
					<div className="balance-item">
						<p className="balance-item__name">Dividends GUSD:</p>
						<p className="balance-item__value">
							{gusdToken && moneyFormat(profits?.dividends[gusdToken?.asset.id], 2, 18)} GUSD
						</p>
					</div>
					<div className="balance-item">
						<p className="balance-item__name">Staking reward:</p>
						<p className="balance-item__value">
							{gatoCoin && moneyFormat(profits?.staking[gatoCoin?.asset.id], 2, 18)} GATO
						</p>
					</div>
				</div>
			</div>
			<div className="content-block">
				<div className="block-header">
					<p className="block-subtitle">Unclaimed reward</p>
				</div>
				<div className="balance-items balance-items__unclaimed">
					<div className="balance-item">
						<p className="balance-item__name">Dividends GATO:</p>
						<p className="balance-item__value">
							{moneyFormat(profits?.unclaimed.unclaimed_reward_dividends_gato, 2, 18)} GATO
						</p>
					</div>
					<div className="balance-item">
						<p className="balance-item__name">Dividends GUSD:</p>
						<p className="balance-item__value">
							{moneyFormat(profits?.unclaimed.unclaimed_reward_dividends_gusd, 2, 18)} GUSD
						</p>
					</div>
					<div className="balance-item">
						<p className="balance-item__name">B2c GATO:</p>
						<p className="balance-item__value">
							{moneyFormat(profits?.unclaimed.unclaimed_reward_b2c_gato, 2, 18)} GATO
						</p>
					</div>
					<div className="balance-item">
						<p className="balance-item__name">B2c GUSD:</p>
						<p className="balance-item__value">
							{moneyFormat(profits?.unclaimed.unclaimed_reward_b2c_gusd, 2, 18)} GUSD
						</p>
					</div>
				</div>
			</div>
			<div className="content-block">
				<div className="block-header">
					<p className="block-subtitle">My wallets</p>
				</div>
				<div className="wallet-inputs--flex">
					{profits?.wallets?.map((el, idx: number) => (
						<div className="wallet-inputs custom-inputs--width">
							{/* {profits?.wallets?.map((el, idx) => ( */}
							<div key={String(`${idx}ind`)} className="connected-wallet">
								<div className="input input--mb-0">
									<p className="input__name">
										{String(Object?.keys(el)) === 'eip-1193:' ? 'trust wallet' : Object?.keys(el)}
									</p>
									{Object?.values(el) &&
										Object?.values(el)?.map((item, id) => {
											return (
												<>
													{item.map((wall, idxn) => {
														return (
															<>
																<div style={{ margin: '10px 0px' }} className="input-wrapper">
																	<CopyToClipboard
																		text={String(el.address)}
																		onCopy={() => handleCopy('Wallet address')}
																	>
																		<button className="input-icon" type="button">
																			<CopySvg />
																		</button>
																	</CopyToClipboard>

																	<a
																		href={`${WEB_URL || ''}/address/${String(item)}`}
																		className="input-item input-item--type3"
																		target="_blank"
																		rel="noreferrer"
																	>
																		{item[id]}
																	</a>
																</div>
																{idxn === 0 && (
																	<div className="input-notification">Last wallet connected</div>
																)}
															</>
														);
													})}
												</>
											);
										})}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default UserDetails;
