// Important: decimals must be >= 1
export const toMaxDecimals = (value: number | string, decimals: number) => {
	return Number(value)
		.toFixed(decimals)
		.replace(/\.?0+$/, '');
};

export const checkAfterPoint = (value: number | string, decimals: number) => {
	const matchChar = String(value).match(/[,\\.]/);
	if (matchChar) {
		if (String(value).split(`${matchChar[0]}`)[1].length > decimals) {
			return true;
		}
	}
	return false;
};
export const toDecimalsAfterPoint = (value: number | string, decimals: number) => {
	const matchChar = String(value).match(/[,\\.]/);
	if (matchChar && String(value).split(`${matchChar[0]}`)[1].length > decimals) {
		const decimal = String(value).split(`${matchChar[0]}`)[1].slice(0, decimals);
		const newarr = [String(value).split(`${matchChar[0]}`)[0], String(decimal)];
		const newString = newarr.join('.');
		return newString;
	}
	return value;
};

export const numberOutDecimal = (from: number, decimal: number) => {
	return Number(from) / 10 ** Number(decimal);
};
export const numberInDecimal = (from: number | string, decimal: number) => {
	return Number(from) * 10 ** Number(decimal);
};
