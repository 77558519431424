import { IPoolTransactionsResponse } from 'redux/reducers/pools/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IPoolsApi } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const pools: IPoolsApi = {
	getPools: (params) => {
		return http.get<any>(endpoint.pools.GET_POOLS, params).then(({ data }) => data);
	},
	putPool: (payload) => {
		return http
			.put<any>(payload.type === 'swap' ? endpoint.pools.PUT_SWAP : endpoint.pools.PUT_POOL, {
				...payload.payload,
			})
			.then(({ data }) => data);
	},
	getHistoryTransaction: (params) => {
		return http
			.get<IPoolTransactionsResponse>(endpoint.pools.GET_TRANSACTION_HISTORY, params)
			.then(({ data }) => data);
	},
};
