import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IStatsApi } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const stats: IStatsApi = {
	getStats: async () => {
		return http.get<any>(endpoint.stats.GET_TOTAL_STATS).then(({ data }) => data);
	},
};
