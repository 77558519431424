import { FC, useEffect, useState } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import Loader from 'ui/Loader';
import { tryOnEmpty } from 'services/utils/custom';
import gusd from 'assets/custom-icons/gusd.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { ReactComponent as GatoChain2Icon } from 'assets/images/gatochain2.svg';
import { getWallets, getWalletsLoader } from 'redux/reducers/wallets/selectors';
import SelectDropdown from 'ui/SelectDropdown';
import { SortArrowButton } from 'ui/SortArrowTable';
import { Filter } from './Filter/Filter';
import { Item } from './Item';

export enum EFilterField {
	all = 'All',
	id = 'ID',
	wallet_balance_gusd = 'Balance GUSD',
	wallet_balance_gato = 'Balance GATO',
	wallet_balance_in_usd = 'Balance USD',
}

export const Wallets: FC = () => {
	const dispatch = useDispatch();
	const walletsList = useSelector(getWallets);
	const walletLoader = useSelector(getWalletsLoader);
	const { perPage, currentPage } = usePagination();
	const totalPages = walletsList?.last_page || 1;

	const [searchValue, setSearchValue] = useState('');
	const [searchField, setSearchField] = useState<any>(Object.keys(EFilterField)[0]);
	const [nameField, setNameField] = useState('');
	const [sortDirection, setSortDirection] = useState<{ [key: string]: string }>({});

	const debouncedSearch = useDebounce(searchValue, 500);

	useEffect(() => {
		// const body = {
		// 	params: debouncedSearch
		// 		? {
		// 				per_page: perPage,
		// 				current_page: currentPage,
		// 				search_field: searchField,
		// 				search: debouncedSearch,
		// 				sort_by: nameField || '',
		// 				sort_direction: sortDirection[nameField],
		// 		  }
		// 		: {
		// 				per_page: perPage,
		// 				current_page: currentPage,
		// 		  },
		// };
		const body = {
			params: tryOnEmpty({
				per_page: perPage,
				current_page: currentPage,
				search_field: searchField === 'all' ? '' : searchField,
				search_value: debouncedSearch,
				sort_by: nameField || '',
				sort_direction: sortDirection[nameField],
			}),
		};
		dispatch(getWalletsRequest(body));
	}, [dispatch, perPage, currentPage, debouncedSearch, nameField, searchField, sortDirection]);

	// const handlePageChange = (pageNumber: number) => {
	// 	setCurrentPage(pageNumber);
	// };

	return (
		<div className="main-content">
			<div className="panel-block">
				<div className="title-block">
					<p className="title">Wallets</p>
				</div>
				<div className="filter-group">
					<Filter searchValue={searchValue} setSearchValue={setSearchValue} />
					<div className="country-filter">
						<SelectDropdown values={EFilterField} onChange={setSearchField} value={searchField} />
					</div>
				</div>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--wallets position-relative">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Num.</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name td-name__flex td-name--position">
										<p>User ID</p>
										<SortArrowButton
											name="user_id"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Full name</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name td-name__flex td-name--position">
										<p>Wallet balance</p>
										<SortArrowButton
											name="wallet_balance_gusd"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
								</div>
								<div className="td td--icon-position">
									<div className="td-name td-name__flex td-name--position">
										<p>GATO balance</p>
										<SortArrowButton
											name="wallet_balance_gato"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
									<div className="tr-token tr-token--icon">
										<span className="td-token">
											<span className="token-box__icon-4 gato-token">
												<GatoChain2Icon />
											</span>{' '}
										</span>
									</div>
								</div>
								<div className="td td--icon-position">
									<div className="td-name td-name__flex td-name--position">
										<p>GUSD balance</p>
										<SortArrowButton
											name="wallet_balance_in_usd"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
									<div className="tr-token tr-token--icon">
										<span className="td-token">
											<span className="gato-token">
												<img className="icon-earn" src={gusd} alt="" />
											</span>
										</span>
									</div>
								</div>
								<div className="td">
									<div className="td-name ">
										<p>Email</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Country</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>User wallet address</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Date</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body table--position">
							{walletLoader && (
								<div className="table-loader-wrapper">
									<Loader />
								</div>
							)}
							{walletsList && walletsList?.data?.length
								? walletsList?.data?.map((wallet, id) => (
										<Item key={wallet.id} wallet={wallet} id={id + 1} />
								  ))
								: ''}
						</div>
					</div>
				</div>
			</div>
			{totalPages > 1 && <Pagination pageCount={totalPages} />}
		</div>
	);
};
