import { spawn } from 'redux-saga/effects';
import { authSaga } from './reducers/auth/saga';
import { settingsSaga } from './reducers/settings/saga';
import { tokensLpSaga } from './reducers/tokensLp/saga';
import { usersSaga } from './reducers/users/saga';
import { walletsSaga } from './reducers/wallets/saga';
import { tokensSaga } from './reducers/tokens/saga';
import { poolsSaga } from './reducers/pools/saga';
import { exchangeSaga } from './reducers/exchange/saga';
import { socketsSaga } from './reducers/sockets/saga';
import { StatsSaga } from './reducers/stats/saga';
import { stakingSaga } from './reducers/staking/saga';
import { referralSaga } from './reducers/referral/saga';
import { DividentsSaga } from './reducers/dividents/saga';

export function* rootSaga() {
	yield spawn(authSaga);
	yield spawn(usersSaga);
	yield spawn(walletsSaga);
	yield spawn(tokensSaga);
	yield spawn(settingsSaga);
	yield spawn(tokensLpSaga);
	yield spawn(poolsSaga);
	yield spawn(exchangeSaga);
	yield spawn(socketsSaga);
	yield spawn(StatsSaga);
	yield spawn(stakingSaga);
	yield spawn(referralSaga);
	yield spawn(DividentsSaga);
}
