import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetRequest, getAssetsRequest } from 'redux/reducers/tokens/reducer';
import { getAsset, getAssets, getAssetsLoader } from 'redux/reducers/tokens/selectors';
import Loader from 'ui/Loader';
import FeeItem from './FeeItem';

export enum ETypeField {
	// percent = 'Percentage',
	flat = 'Fixed/Flat',
}

export enum EFeeCategory {
	swap = 'Swap',
	transfer = 'Transfer',
	add_liquidity = 'Add liquidity',
	remove_liquidity = 'Remove liquidity',
	stake = 'Staking',
	stake_claim = 'Claim Staking reward',
	referral_claim = 'Claim b2c reward',
	referral_claim_b2b = 'Claim b2b reward',
}

const FeeManagement = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getAssetsLoader);
	const assetsList = useSelector(getAssets);

	const gatoCoin = useMemo(() => {
		return assetsList?.assets.find((item) => item.symbol === 'GATO' && item.type === 'coin');
	}, [assetsList]);

	const asset = useSelector(getAsset);

	const tokenFee = asset?.token_fee;

	useEffect(() => {
		if (gatoCoin) {
			dispatch(getAssetRequest(String(gatoCoin.id)));
		}
	}, [dispatch, gatoCoin]);

	useEffect(() => {
		dispatch(getAssetsRequest({ per_page: 1000 }));
	}, [dispatch]);

	return (
		<div className="main-content">
			<div className="title-block title-block--mb24">
				<p className="title">Fees in Native Coin</p>
			</div>
			{tokenFee && (
				<div className="content-block content-block--mt0">
					<div className="fee-container table--position">
						{loading && (
							<div className="loader-wrapper loader-wrapper__cover">
								<Loader />
							</div>
						)}

						{Object.keys(EFeeCategory).map((item) => (
							<FeeItem key={item} feeName={item} asset={asset} />
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default FeeManagement;
