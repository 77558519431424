import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExpensesRequest } from 'redux/reducers/dividents/reducer';
import { getExpencies, getLoaderExpencies } from 'redux/reducers/dividents/selectors';
import Loader from 'ui/Loader';
import usePagination from 'hooks/usePagination';
import { Item } from './item';
import { TExpencies } from './types';

export const Expencies: FC<TExpencies> = ({ search, setTotalPages }) => {
	const dispatch = useDispatch();
	const list = useSelector(getExpencies);
	const listLoader = useSelector(getLoaderExpencies);
	const { perPage, currentPage } = usePagination();
	// const totalPages = list?.last_page || 1;
	// useEffect(() => {
	// 	setTotalPages && setTotalPages(totalPages);
	// 	// eslint-disable-next-line
	// }, [totalPages]);

	useEffect(() => {
		const body = {
			status: '' || undefined,
			sort_by: '' || undefined,
			sort_direction: '' || undefined,
		};
		dispatch(getExpensesRequest(body));
	}, [dispatch]);
	return (
		<div className="table table--dividents">
			<div className="table-header">
				<div className="tr">
					<div className="td td--left">
						<div className="td-name">
							<p>id</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Expenses description</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Amount (USD)</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Amount get</p>
						</div>
					</div>

					<div className="td">
						<div className="td-name">
							<p>Date from</p>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name">
							<p>Action</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body table--position">
				{listLoader && (
					<div className="table-loader-wrapper">
						<Loader />
					</div>
				)}
				{!listLoader && list?.length ? list?.map((el) => <Item key={el.id} el={el} />) : null}
			</div>
		</div>
	);
};
