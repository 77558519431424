import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import { CopySvg } from 'assets/custom-icons';
import { WEB_URL } from 'services/constants/env';
import { shortNotation } from 'services/utils/textSlice';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { TWallet } from './type';

export const Item: FC<TWallet> = ({ referral }) => {
	const {
		id,
		email,
		number_of_referrals,
		invite_key,
		user,
		referrals,
		total_revenue_gusd,
		fee_collected_gusd,
		total_revenue_gato,
		fee_collected_gato,
		number_of_referrals_by_level,
	} = referral;

	const moneyFormat = useMoneyFormat();

	const handleCopy = (text: string) => {
		notificationContainer(`${text} copied successfully!`, 'info');
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>
					<NavLink to={`/referral-management/${id}`} className="link link--type2 ">
						{id}
					</NavLink>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Referral ID</p>
				<div className="td-two-items">
					<CopyToClipboard text={String(invite_key)} onCopy={() => handleCopy('Referral ID')}>
						<p>
							<span className="link link--type2 ">{invite_key}</span>
						</p>
					</CopyToClipboard>
					<CopyToClipboard text={String(invite_key)} onCopy={() => handleCopy('Referral ID')}>
						<button className="copy-btn" type="button">
							<CopySvg />
						</button>
					</CopyToClipboard>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<div className="td-two-items">
					<CopyToClipboard text={String(email)} onCopy={() => handleCopy('Email')}>
						<p>
							<span className="link link--type2 ">{email}</span>
						</p>
					</CopyToClipboard>
					<CopyToClipboard text={String(email)} onCopy={() => handleCopy('Email')}>
						<button className="copy-btn" type="button">
							<CopySvg />
						</button>
					</CopyToClipboard>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total users</p>
				{number_of_referrals}
			</div>
			<div className="td">
				<p className="td-hidden-name">Level 2</p>
				{number_of_referrals_by_level?.length &&
					number_of_referrals_by_level?.length > 1 &&
					number_of_referrals_by_level[1]?.total}
			</div>
			<div className="td">
				<p className="td-hidden-name">Level 3</p>
				{number_of_referrals_by_level?.length &&
					number_of_referrals_by_level?.length > 2 &&
					number_of_referrals_by_level[2]?.total}
			</div>
			<div className="td">
				<p className="td-hidden-name">Level 4</p>
				{number_of_referrals_by_level?.length &&
					number_of_referrals_by_level?.length > 3 &&
					number_of_referrals_by_level[3]?.total}
			</div>
			<div className="td">
				<p className="td-hidden-name">Total</p>
				<div className="tr-token">
					<span className="td-token">{fee_collected_gato || '0'}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total</p>
				<div className="tr-token">
					<span className="td-token">{fee_collected_gusd || '0'}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total rewards</p>
				<div className="tr-token">
					<span className="td-token">{total_revenue_gato || '0'}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total rewards</p>
				<div className="tr-token">
					<span className="td-token">{total_revenue_gusd || '0'}</span>
				</div>
			</div>
		</div>
	);
};
