import { FC, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import { CopySvg, ActionMove } from 'assets/custom-icons';
import { useDispatch } from 'react-redux';
import { WEB_URL } from 'services/constants/env';
import { shortNotation } from 'services/utils/textSlice';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import {
	getReferralUsersListRequest,
	postReferralUsersChangeRequest,
} from 'redux/reducers/referral/reducer';
import { MoveUser } from '../PopUp/MoveUser';
import { TWallet } from './type';

export const ItemReferral: FC<TWallet> = ({ item }) => {
	const { parent_id, user_id, email, logged_at, referral_id } = item;
	const [openModal, setOpenModal] = useState(false);
	const dispatch = useDispatch();

	const closeModal = () => {
		setOpenModal(false);
	};
	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleSubmit = (valueId: string) => {
		if (user_id && valueId)
			dispatch(
				postReferralUsersChangeRequest({ referralId: String(user_id), id: String(valueId) }),
			);
		closeModal();
	};

	const handleCopy = (text: string) => {
		notificationContainer(`${text} copied successfully!`, 'info');
	};
	useEffect(() => {
		if (openModal && user_id) {
			const payload = {
				params: {
					exceptionId: String(user_id),
					// id: '' || undefined,
				},
			};
			dispatch(getReferralUsersListRequest(payload));
		}
	}, [dispatch, user_id, openModal]);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>{user_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<div className="td-two-items">
					<CopyToClipboard text={String(email)} onCopy={() => handleCopy('Email')}>
						<p>{email}</p>
					</CopyToClipboard>
					<CopyToClipboard text={String(email)} onCopy={() => handleCopy('Email')}>
						<button className="copy-btn" type="button">
							<CopySvg />
						</button>
					</CopyToClipboard>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Referral ID</p>
				<p>{referral_id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Data</p>
				<p>{logged_at ? format(Date.parse(logged_at), 'MM/dd/yyy hh:mm') : '--/--'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Action</p>
				<button className="button-referral" type="button" onClick={() => handleOpenModal()}>
					<ActionMove className="action-center" />
				</button>
			</div>
			<MoveUser openModal={openModal} handleSubmit={handleSubmit} closeModal={closeModal} />
		</div>
	);
};
