import { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUp } from '../types';

interface IResetPasswordPopUpProps {
	handleResetPassword: () => void;
}

const ResetPasswordPopUp: FC<IPopUp & IResetPasswordPopUpProps> = ({
	open,
	closeModal,
	handleResetPassword,
}) => {
	return (
		<Popup open={open} onClose={closeModal} closeOnDocumentClick>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--w360">
						<button type="button" className="popup__close" onClick={closeModal}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M18 6L6 18"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6 6L18 18"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						<div className="popup-header">
							<p className="popup-header__title">Password reset</p>
						</div>

						<div className="popup-body">
							<p className="popup-text popup-text--center">
								User will be forced to change his password during next log in
							</p>
						</div>

						<div className="popup-footer">
							<button
								onClick={handleResetPassword}
								type="button"
								className="button button--full-width"
							>
								Ok
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ResetPasswordPopUp;
