import { FC, useEffect, useState } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import SelectDropdown from 'ui/SelectDropdown';
import { CSearchInput } from 'ui/CSearchInputRef';
import { IAsset } from 'redux/reducers/tokens/types';
import { CloseButtonPopupIcon } from 'assets/custom-icons';
import { IReferralUser } from 'redux/reducers/referral/types';
import { useDispatch, useSelector } from 'react-redux';
import { getUserReferralList } from 'redux/reducers/referral/selectors';
import { IPropsModal } from './types';

export const MoveUser: FC<IPropsModal> = ({ openModal, handleSubmit, closeModal, userId }) => {
	const dispatch = useDispatch();
	const list = useSelector(getUserReferralList);
	const [selectedAsset, setSelectedAsset] = useState<IReferralUser | null>(null);

	const handleAssetChange = (item: IReferralUser) => {
		setSelectedAsset(item);
	};
	return (
		<ModalWarapper openModal={openModal} setOpenModal={closeModal}>
			<div className="popup popup--w360">
				<button type="button" className="popup__close" onClick={closeModal}>
					<CloseButtonPopupIcon />
				</button>
				<div className="popup-header">
					<p className="popup-header__title">Move user</p>
				</div>

				<div className="popup-body">
					{/* <p className="popup-text popup-text--center">
						If you delete user all informtion is deleted
					</p> */}
					<div className="input">
						<p className="input__name ">User ID</p>
						<CSearchInput
							listData={list || []}
							onSelect={handleAssetChange}
							dataItem={selectedAsset}
						/>
					</div>
				</div>

				<div className="popup-footer">
					{/* <button
						onClick={() => openSetConfirm(false)}
						type="button"
						className="button button--full-width"
					>
						Cancel
					</button> */}
					<button
						onClick={() => handleSubmit(String(selectedAsset?.id))}
						type="button"
						className="button button--full-width"
					>
						Move
					</button>
				</div>
			</div>
		</ModalWarapper>
	);
};
