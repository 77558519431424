import { FC } from 'react';
import { toDecimalsAfterPoint } from 'services/utils/numbers';
import { TSwap } from './type';

export const Item: FC<TSwap> = ({ pool }) => {
	const {
		asset_base,
		asset_quote,
		total_transactions,
		id,
		volume_in_usd,
		total_commission_in_gato,
		user_commissions_in_gato,
		platform_commissions_in_gato,
	} = pool;

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Num.</p>
				<p>{id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pairs</p>
				<div className="token-line">
					<img className="token-line__icon" src={asset_base.logo} alt={asset_base.name} />
					<span className="token-line__text">{asset_base.symbol}</span>
					<span className="token-line__text">/</span>
					<img className="token-line__icon" src={asset_quote.logo} alt={asset_quote.name} />
					<span className="token-line__text">{asset_quote.symbol}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Volume</p>
				<p>{volume_in_usd} GUSD</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total number of transactions</p>
				<p>{total_transactions}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total comission amount</p>
				<p>{toDecimalsAfterPoint(total_commission_in_gato, 16) || 0}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total comission distrbuted</p>
				<p>{toDecimalsAfterPoint(user_commissions_in_gato, 16) || 0}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Gatochain profit</p>
				<p>{toDecimalsAfterPoint(platform_commissions_in_gato, 16) || 0}</p>
			</div>
		</div>
	);
};
