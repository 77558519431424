import { FC, useEffect, useState } from 'react';
import usePagination from 'hooks/usePagination';
import Loader from 'ui/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionHistorys, getHistoryLoader } from 'redux/reducers/pools/selectors';
import { getHIstoryRequest } from 'redux/reducers/pools/reducer';
import { Item } from './Item';
import { TLiquidity } from './types';

export const TransactionHistory: FC<TLiquidity> = ({ search, period, setTotalPages }) => {
	const historyList = useSelector(getTransactionHistorys);
	const historyLoader = useSelector(getHistoryLoader);
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	const totalPages = historyList?.last_page || 1;
	useEffect(() => {
		setTotalPages(totalPages);
		// eslint-disable-next-line
	}, [totalPages]);

	useEffect(() => {
		const body = {
			params: search
				? {
						per_page: perPage,
						current_page: currentPage,
						search,
						period,
				  }
				: {
						per_page: perPage,
						current_page: currentPage,
						period,
				  },
		};
		dispatch(getHIstoryRequest(body));
	}, [dispatch, currentPage, search, period, perPage]);
	return (
		<div className="table table--pool-liquidity-transaction-history">
			<div className="table-header">
				<div className="tr">
					<div className="td td--left">
						<div className="td-name">
							<p>User ID</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Time</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Pair</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Type</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Fee</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Status</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body table--position">
				{historyLoader && (
					<div className="table-loader-wrapper">
						<Loader />
					</div>
				)}
				{!historyLoader && historyList?.data?.length
					? historyList?.data?.map((data) => <Item key={data.id} data={data} />)
					: null}
			</div>
		</div>
	);
};
