/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAuthStore,
	ICreateNewPasswordPayload,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IForgotPasswordPayload,
	IGenerateSmsRequestPayload,
	IipConfirmPayload,
	ILoginPayload,
	ILoginResponse,
	ILogoutPayload,
	IRegistrationPayload,
	IResetTwoFaPayload,
	IRegistrResponse,
	IResendEmail,
	IResendPhone,
	IGenerateSmsChecktPayload,
} from './types';

// ==========================================:
export const initialState: IAuthStore = {
	accessToken: null,
	socketToken: null,
	refreshToken: null,
	isAuthenticated: false,
	registrLoader: false,
	logoutLoader: false,
	loginLoader: false,
	forgotPasswordLoader: false,
	createNewPasswordLoader: false,
	ipConfirmLoader: false,
	emailConfirm: false,
	emailConfirmLoader: false,
	emailResetConfirmTokenLoader: false,
	emailResetConfirmTokenMessage: false,
	generateSmsLoader: false,
	registerUser: null,
};

// ==========================================:
const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		loginRequest: (state, action: PayloadAction<ILoginPayload>) => {
			const loginRequestState = state;

			loginRequestState.loginLoader = true;
		},
		loginSuccess: (state, action: PayloadAction<ILoginResponse>) => {
			const { payload } = action;
			const loginState = state;

			loginState.isAuthenticated = true;
			loginState.loginLoader = false;
			loginState.accessToken = payload.token;
			loginState.socketToken = payload.socket_token;
			loginState.registerUser = payload;
		},
		loginGoTo2FASuccess: (state) => {
			const loginState = state;
			loginState.loginLoader = false;
		},
		logoutRequest: (state, action: PayloadAction<ILogoutPayload>) => {
			const logoutState = state;

			logoutState.logoutLoader = true;
		},
		forgotPasswordRequest: (state, action: PayloadAction<IForgotPasswordPayload>) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = true;
		},
		forgotPasswordSuccess: (state) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = false;
		},
		google2faEnabled: (state, { payload }: PayloadAction<boolean>) => {
			const userData = state.registerUser?.user_data;
			if (userData) userData.google2fa_enabled = payload;
		},

		authInitState: () => initialState,
	},
});

export default auth.reducer;
export const {
	loginRequest,
	loginSuccess,
	loginGoTo2FASuccess,
	authInitState,
	logoutRequest,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	google2faEnabled,
} = auth.actions;
