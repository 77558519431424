import React from 'react';

import { TokenLayout as Token } from 'layouts/Token';
import TokenFee from 'components/Tokens/EditFee/TokenFee';

const TokenFeePage = () => {
	return (
		<Token title="Tokens">
			<TokenFee />
		</Token>
	);
};

export default TokenFeePage;
