import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { WEB_URL } from 'services/constants/env';
import {
	getTimeParserDateFromTimestamp,
	getTimeParserTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { toMaxDecimals, numberOutDecimal } from 'services/utils/numbers';
import { THistory } from './type';

export const Item: FC<THistory> = ({ data }) => {
	const {
		status,
		user_id,
		created_at,
		id,
		base_amount,
		quote_amount,
		quote_asset,
		base_asset,
		tx,
		fee,
		gas_asset,
	} = data;

	const handleStatus = (value: string) => {
		if (value === 'completed') {
			return <p className="status-tag status-tag--success">Completed</p>;
		}
		if (value === 'pending') {
			return <p className="status-tag status-tag--success">Pending</p>;
		}
		return <p className="status-tag status-tag--error">Faild</p>;
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Transaction number</p>
				<p>{id}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>
					<NavLink className="link" to={`/user-details/${String(user_id)}`}>
						{user_id}
					</NavLink>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Time</p>
				<p>
					{getTimeParserDateFromTimestamp(created_at)} {getTimeParserTimeFromTimestamp(created_at)}{' '}
					UTC
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pairs</p>
				<div className="token-line">
					<img className="token-line__icon" src={base_asset?.logo} alt={base_asset?.name} />
					<span className="token-line__text">{base_asset?.symbol}</span>
					<span className="token-line__text">/</span>
					<img className="token-line__icon" src={quote_asset?.logo} alt={quote_asset?.name} />
					<span className="token-line__text">{quote_asset?.symbol}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Buy Price</p>
				<p>{toMaxDecimals(base_amount, 9)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Sell Price</p>
				<p>{toMaxDecimals(quote_amount, 9)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Transaction fee</p>
				<p>
					{fee || '--'} {gas_asset?.symbol || ''}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">TxHash</p>
				<p>
					<a
						target="_blank"
						className="link"
						href={`${WEB_URL || ''}/transactions/${String(tx)}`}
						rel="noreferrer"
					>
						{tx.slice(0, 12)}...
					</a>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{handleStatus(status)}
			</div>
		</div>
	);
};
