import { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { format } from 'date-fns';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import { CopySvg, ArrowSelect } from 'assets/custom-icons';
import { WEB_URL } from 'services/constants/env';
import { shortNotation } from 'services/utils/textSlice';
import { useMoneyFormat } from 'hooks/useMoneyFormat';

import { ItemReferral } from '../ItemReferral/ItemReferral';
import { TWallet } from './type';

export const Item: FC<TWallet> = ({ referral }) => {
	const { level, total, referrals } = referral;
	const [show, setShow] = useState(false);

	const moneyFormat = useMoneyFormat();

	const handleCopy = (text: string) => {
		notificationContainer(`${text} copied successfully!`, 'info');
	};
	const handleShowChild = () => {
		setShow((prev) => !prev);
	};

	return (
		<div className="tr-group ">
			<div className={classNames('tr tr--level tr--referral-row', { 'active--row': show })}>
				<div className="td">
					<p className="td-hidden-name">Level</p>
					<p>{level}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Total members</p>
					<p>{total}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Details</p>
					<button className="button--referral" type="button" onClick={handleShowChild}>
						<ArrowSelect className={classNames('button-arrow', { 'button--rotate': show })} />
					</button>
				</div>
			</div>
			<div className={classNames('tr-drop-list', { active: show })}>
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>User ID</p>
						</div>
					</div>

					<div className="td">
						<div className="td-name">
							<p>Email</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Referral ID</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Data</p>
						</div>
					</div>
					<div className="td td--rigth">
						<div className="td-name">
							<p>Action</p>
						</div>
					</div>
				</div>
				{referrals.length &&
					referrals?.map((item) => <ItemReferral key={item.referral_id} item={item} />)}
			</div>
		</div>
	);
};
