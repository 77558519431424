import { FC, useState, ChangeEvent } from 'react';
import { Page } from 'layouts/page';
import { navTabsRoutes } from 'layouts/Token/constants';
import { NavTabs } from 'layouts-elements/NavTabs';
import StatusFilter from 'components/Tokens/Verify/StatusFilter';
import TokensTable from 'components/Tokens/Verify/TokensTable';
import { TAssetStatus } from 'redux/reducers/tokens/types';
import { useLocation } from 'react-router';

export const VerifyTokenPage: FC = () => {
	const [status, setStatus] = useState<TAssetStatus | null>('pending');
	const [search, setSearch] = useState<string | null>(null);
	const location = useLocation();
	const handleStatusChange = (value: TAssetStatus) => {
		setStatus(value);
	};

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value },
		} = e;
		setSearch(value);
		location.search = '1';
	};

	return (
		<Page>
			<div className="main-content token-verify">
				<div className="panel-block">
					<div className="title-block">
						<p className="title">All Tokens in Chain</p>
					</div>
					<div className="filter-group">
						<div className="country-filter">
							<StatusFilter value={status || undefined} onChange={handleStatusChange} />
						</div>
						<div className="panel-search">
							<div className="input-wrapper">
								<input
									className="input-item input-item--left-icon input-item--type2"
									type="text"
									placeholder="Search be token name or symbol"
									value={search || ''}
									onChange={handleSearchChange}
								/>
								<span className="input-icon input-icon--left input-icon--color2">
									<svg
										width="17"
										height="17"
										viewBox="0 0 17 17"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z"
											stroke="#8E8EA9"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M16.5 16.5L15 15"
											stroke="#8E8EA9"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
							</div>
						</div>
					</div>
				</div>
				<NavTabs routes={navTabsRoutes} />
				<TokensTable statusFilter={status} search={search} />
			</div>
		</Page>
	);
};
