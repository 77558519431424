import { FC, useEffect, useState } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import { SearchIcon } from 'assets/custom-icons';
import gusd from 'assets/custom-icons/gusd.jpg';
import { ReactComponent as GatoChain2Icon } from 'assets/images/gatochain2.svg';
import { SortArrowButton } from 'ui/SortArrowTable';
import { tryOnEmpty } from 'services/utils/custom';
import Loader from 'ui/Loader';
import SelectDropdown from 'ui/SelectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getReferralUsersRequest } from 'redux/reducers/referral/reducer';
import { getReferral, getReferralLoader } from 'redux/reducers/referral/selectors';
import { Item } from './Item';
import { Filter } from './Filter/Filter';
import { referalMoks } from './data';

export enum EFilterField {
	all = 'All',
	id = 'ID',
	number_of_referrals = 'Users',
	fee_collected_gusd = 'Fee GUSD',
	fee_collected_gato = 'Fee GATO',
	revenue_gusd = 'Rewards GUSD',
	revenue_gato = 'Rewards GATO',
}

export const ReferralManagement: FC = () => {
	const dispatch = useDispatch();
	const referralList = useSelector(getReferral);
	const referralLoader = useSelector(getReferralLoader);
	const { perPage, currentPage } = usePagination();
	const totalPages = referralList?.last_page || 1;

	// const repalaceData = referralList || referalMoks;
	const repalaceData = referralList;
	const [searchValue, setSearchValue] = useState('');
	const [searchField, setSearchField] = useState<any>(Object.keys(EFilterField)[0]);
	const [nameField, setNameField] = useState('');
	const [sortDirection, setSortDirection] = useState<{ [key: string]: string }>({});

	const debouncedSearch = useDebounce(searchValue, 500);

	useEffect(() => {
		const payload = {
			per_page: perPage,
			current_page: currentPage,
			search_field: searchField === 'all' ? '' : searchField,
			search_value: debouncedSearch,
			sort_by: nameField || '',
			sort_direction: sortDirection[nameField],
		};

		dispatch(getReferralUsersRequest(tryOnEmpty(payload)));
	}, [dispatch, nameField, perPage, currentPage, debouncedSearch, searchField, sortDirection]);

	return (
		<div className="main-content">
			<div className="panel-block">
				<div className="title-block">
					<p className="title">Referral Management Overview</p>
				</div>
				<div className="filter-group">
					<Filter searchValue={searchValue} setSearchValue={setSearchValue} />
					<div className="country-filter">
						<SelectDropdown values={EFilterField} onChange={setSearchField} value={searchField} />
					</div>
				</div>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--referral-management">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name td-name__flex td-name--position">
										<p>ID</p>
										<SortArrowButton
											name="id"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Referral ID</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Email</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name td-name__flex td-name--position">
										<p>Total users</p>
										<SortArrowButton
											name="number_of_referrals"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
								</div>
								<div className="td td--icon-position">
									<div className="td-name">
										<p>Level</p>
									</div>
									<div className="td-sub">
										<span className="td-text">2</span>
									</div>
								</div>
								<div className="td td--icon-position">
									<div className="td-name">
										<p>Level</p>
									</div>
									<div className="td-sub">
										<span className="td-text">3</span>
									</div>
								</div>
								<div className="td td--icon-position">
									<div className="td-name">
										<p>Level</p>
									</div>
									<div className="td-sub">
										<span className="td-text">4</span>
									</div>
								</div>
								<div className="td td--icon-position">
									<div className="td-name td-name__flex td-name--position">
										<p>Total Fee Gen</p>
										<SortArrowButton
											name="fee_collected_gato"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
									<div className="tr-token tr-token--icon">
										<span className="td-token">
											<span className="token-box__icon-4 gato-token">
												<GatoChain2Icon />
											</span>{' '}
											{/* GATO */}
										</span>
									</div>
								</div>
								<div className="td td--icon-position">
									<div className="td-name td-name__flex td-name--position">
										<p>Total Fee Gen</p>
										<SortArrowButton
											name="fee_collected_gusd"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
									<div className="tr-token tr-token--icon">
										<span className="td-token">
											<span className="gato-token">
												<img className="icon-earn" src={gusd} alt="" />
											</span>
											{/* GUSD */}
										</span>
									</div>
								</div>
								<div className="td td--icon-position">
									<div className="td-name td-name__flex td-name--position">
										<p>Total rewards</p>
										<SortArrowButton
											name="revenue_gato"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
									<div className="tr-token tr-token--icon">
										<span className="td-token">
											<span className="token-box__icon-4 gato-token">
												<GatoChain2Icon />
											</span>{' '}
											{/* GATO */}
										</span>
									</div>
								</div>
								<div className="td td--icon-position">
									<div className="td-name td-name__flex td-name--position">
										<p>Total rewards</p>
										<SortArrowButton
											name="revenue_gusd"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
									<div className="tr-token tr-token--icon">
										<span className="td-token">
											<span className="gato-token">
												<img className="icon-earn" src={gusd} alt="" />
											</span>
											{/* GUSD */}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body table--position">
							{referralLoader && (
								<div className="table-loader-wrapper">
									<Loader />
								</div>
							)}
							{repalaceData?.data?.length
								? repalaceData?.data?.map((referra: any, id) => (
										<Item key={referra.id} referral={referra} id={id + 1} />
								  ))
								: ''}
						</div>
					</div>
				</div>
			</div>
			{totalPages > 1 && <Pagination pageCount={totalPages} />}
		</div>
	);
};
