import classNames from 'classnames';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import TwoFactorAuthentication from './TwoFactorAuthentication';

const AdministrativeRoles = () => {
	const { tab } = useParams();
	return (
		<div className="main-content">
			<div className="panel-block">
				<div className="title-block">
					<p className="title">Administrative roles</p>
				</div>
				<button type="button" className="button button--fw-500 button--width-auto2">
					Add New Admin
					<span className="button__icon">
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4.5 9H13.5"
								stroke="white"
								strokeWidth="1.2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M9 13.5V4.5"
								stroke="white"
								strokeWidth="1.2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>
				</button>
			</div>
			<div className="tabs">
				<Link
					to="/administrative-roles/change-password"
					className={classNames('tabs__item', { active: tab !== 'two-factor-authentication' })}
				>
					Change Password
				</Link>
				<Link
					to="/administrative-roles/two-factor-authentication"
					className={classNames('tabs__item', { active: tab === 'two-factor-authentication' })}
				>
					Two-Factor Authentication
				</Link>
			</div>
			{tab === 'two-factor-authentication' ? <TwoFactorAuthentication /> : <ChangePassword />}
		</div>
	);
};

export default AdministrativeRoles;
