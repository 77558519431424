import { FC } from 'react';
import { TSwitcher } from './types';

export const TableSwitch: FC<TSwitcher> = ({ children, edit, checked, disabled = false }) => {
	return (
		<div className="switch switch--center">
			{edit ? (
				<>
					<label className="switch__label" style={disabled ? { cursor: 'wait' } : {}}>
						{children}

						<span className="switch__toggler" />
					</label>
				</>
			) : (
				<span>{checked ? 'on' : 'off'}</span>
			)}
		</div>
	);
};
