import { FC } from 'react';
import { Formik, Form, Field } from 'formik';

import * as yup from 'yup';
import Input from 'ui/Formik/Input';
import { IForgotPasswordForm } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const ForgotPassForm: FC<IForgotPasswordForm> = ({ emailSubmit }) => {
	const initialValues = {
		text: '',
	};

	const validationSchema = yup.object().shape({
		text: yup
			.string()
			.required('Required field')
			// .email('Email format')
			.max(60, 'Email max characters'),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: any, { resetForm, setSubmitting }) => {
				emailSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<Form>
						<>
							<div className="authorization-form__header">
								<p className="form-title form-title--center">Password reset</p>
								<div className="form-text form-text--center">
									<p>Input an email or phone so we can send you the link to reset your password</p>
								</div>
							</div>
							<div className="authorization-form__body">
								<div className="authorization-form__group">
									<Field
										type="text"
										placeholder="Email"
										name="text"
										title="Email"
										required
										component={Input}
									/>
								</div>
							</div>
							<div className="form-footer">
								<button
									disabled={!(isValid && dirty) || isSubmitting}
									type="submit"
									aria-label="form-submit"
									className="button button--big-height button--full-width"
								>
									Reset password
								</button>
							</div>
						</>
					</Form>
				);
			}}
		</Formik>
	);
};
