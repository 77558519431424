/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStakingStore, TStakingRequest, TStakingTransactionsResponse } from './types';

// ==========================================:
export const initialState: IStakingStore = {
	transactions: null,
	transactionsLoading: false,
};

// ==========================================:
const staking = createSlice({
	name: '@@staking',
	initialState,
	reducers: {
		getStakingRequest: (state, action: PayloadAction<TStakingRequest>) => {
			const stakingRequestState = state;

			stakingRequestState.transactionsLoading = true;
		},
		getStakingSuccess: (state, action: PayloadAction<TStakingTransactionsResponse>) => {
			const { payload } = action;
			const stakingRequestState = state;

			stakingRequestState.transactions = payload;
			stakingRequestState.transactionsLoading = false;
		},

		StakingInitState: () => initialState,
	},
});

export default staking.reducer;
export const { getStakingRequest, getStakingSuccess, StakingInitState } = staking.actions;
