import { FC, useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { TokenLayout as Token } from 'layouts/Token';
import { CreateTokenForm } from 'components/Forms/CreateTokenForm';
import TokensTable from 'components/Tokens/Create/TokensTable';
import { useDebounce } from 'hooks/useDebounce';
import usePagination from 'hooks/usePagination';
import { getAssetsRequest } from 'redux/reducers/tokens/reducer';
import { useLocation } from 'react-router';

export const TabTokenPage: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [search, setSearch] = useState('');
	const { perPage, currentPage } = usePagination();
	const debouncedSearch = useDebounce(search, 500);
	useEffect(() => {
		dispatch(
			getAssetsRequest({
				per_page: perPage,
				current_page: currentPage,
				search_value: debouncedSearch || undefined,
			}),
		);
	}, [currentPage, dispatch, perPage, debouncedSearch]);

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
		location.search = '1';
	};

	return (
		<Token title="All Tokens in Chain">
			<TokensTable />
		</Token>
	);
};
