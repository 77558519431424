import { Dividents } from 'components/Dividents';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const DividentsPage: FC = () => {
	return (
		<Page>
			<Dividents />
		</Page>
	);
};
