export const downloadCVSFunc = (nameFile: string, text: string) => {
	/* eslint-disable */
	const xml = `Name,Title\n + ${String(text)}`;
	//const xml = `${String(text)}`;
	
	const hiddenElement = document.createElement('a');
	hiddenElement.href = `data:text/xml;charset=utf-8,${encodeURI(xml)}`;
	hiddenElement.target = '_blank';
	hiddenElement.download = `${nameFile}.xlsx`;
	hiddenElement.click();
};
