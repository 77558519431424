// import { isAuth, isGuest } from 'routes/authMiddlewares';
import { useSelector } from 'react-redux';
import { useRoutes, Navigate } from 'react-router-dom';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import Login from 'pages/Auth/Login';
import { HomePage } from 'pages/HomePage';
import { PoolPage } from 'pages/PoolPage';
import { ResetPassword } from 'pages/Auth/ResetPassword';
import { WalletPage } from 'pages/WalletPage';
import { TokenListPage } from 'pages/TokensPage/list';
import { TokenCreatePage } from 'pages/TokensPage/create';
import { TransactionFeePage } from 'pages/TokensPage/fee';
import { VerifyTokenPage } from 'pages/TokensPage/verify';
import { TabTokenPage } from 'pages/TokensPage/tab';
import { AssetItem } from 'pages/TokensPage/item';
import { ExchangePage } from 'pages/ExchangePage';
import UserDetailsPage from 'pages/UserDetailsPage';
import AdministrativeRolesPage from 'pages/AdministrativeRolesPage';
import LpTokensPage from 'pages/LpTokensPage';
import FeeManagementPage from 'pages/FeeManagementPage';
import TokenFeePage from 'pages/TokenFeePage';
import { TotalStatsPage } from 'pages/TotalStatsPage';
import { StakingPage } from 'pages/StakingPage';
import { DividentsPage } from 'pages/DividentsPage';
import ReferralManagementPage from 'pages/ReferralManagementPage';
import { ReferralUserPage } from 'pages/ReferralUserPage';

export const NavigateList = () => {
	const authenticated = useSelector(getAuthIsAuthenticated);

	const routes = useRoutes([
		{
			path: `/`,
			element: authenticated ? <HomePage /> : <Login />,
		},
		{
			path: `login`,
			element: !authenticated ? <Login /> : <Navigate to="/" />,
		},
		{
			path: `reset-password`,
			element: !authenticated ? <ResetPassword /> : <Navigate to="/" />,
		},
		{
			path: `wallets`,
			element: authenticated ? <WalletPage /> : <Navigate to="/" />,
		},
		{
			path: `pools`,
			element: authenticated ? <PoolPage /> : <Navigate to="/" />,
		},
		{
			path: 'tokens',
			element: authenticated ? <Navigate to="/tokens/list" /> : <Navigate to="/" />,
		},
		{
			path: 'tokens/list',
			element: authenticated ? <TokenListPage /> : <Navigate to="/" />,
		},
		{
			path: 'tokens/fee',

			children: [
				{ index: true, element: authenticated ? <TransactionFeePage /> : <Navigate to="/" /> },
				{
					element: authenticated ? <TokenFeePage /> : <Navigate to="/" />,
					path: ':assetId',
				},
			],
		},
		{
			path: 'tokens/verify',
			element: authenticated ? <VerifyTokenPage /> : <Navigate to="/" />,
		},
		{
			path: 'tokens/:assetId',
			element: authenticated ? <AssetItem /> : <Navigate to="/" />,
		},
		{
			path: 'tokens/tab',
			element: authenticated ? <TabTokenPage /> : <Navigate to="/" />,
		},
		{
			path: 'token-create',
			element: authenticated ? <TokenCreatePage /> : <Navigate to="/" />,
		},
		{
			path: 'user-details/:userId',
			element: authenticated ? <UserDetailsPage /> : <Navigate to="/" />,
		},
		{
			path: 'administrative-roles',
			children: [
				{ index: true, element: authenticated ? <AdministrativeRolesPage /> : <Navigate to="/" /> },
				{
					path: ':tab',
					element: authenticated ? <AdministrativeRolesPage /> : <Navigate to="/" />,
				},
			],
		},
		{
			path: 'lp-tokens',
			element: authenticated ? <LpTokensPage /> : <Navigate to="/" />,
		},
		{
			path: 'fee-management',
			element: authenticated ? <FeeManagementPage /> : <Navigate to="/" />,
		},
		{
			path: 'referral-management',
			children: [
				{ index: true, element: authenticated ? <ReferralManagementPage /> : <Navigate to="/" /> },
				{
					path: ':id',
					element: authenticated ? <ReferralUserPage /> : <Navigate to="/" />,
				},
			],
			// element: authenticated ? <ReferralManagementPage /> : <Navigate to="/" />,
		},
		{
			path: `pool`,
			element: authenticated ? <PoolPage /> : <Navigate to="/" />,
		},
		{
			path: `exchange`,
			element: authenticated ? <ExchangePage /> : <Navigate to="/" />,
		},
		{
			path: `total-stats`,
			element: authenticated ? <TotalStatsPage /> : <Navigate to="/" />,
		},
		{
			path: `staking`,
			element: authenticated ? <StakingPage /> : <Navigate to="/" />,
		},
		{
			path: `dividends`,
			element: authenticated ? <DividentsPage /> : <Navigate to="/" />,
		},
	]);
	return routes;
};
