/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	TDividentsResponse,
	TDividentsRequest,
	IExpensesRequest,
	IExpensesResponse,
	IExpensesSaveRequest,
} from './types';
import {
	dividentsInitState,
	getDividentsRequest,
	getDividentsSuccess,
	getExpensesRequest,
	getExpensesSuccess,
	saveExpensesRequest,
	updateExpensesRequest,
} from './reducer';
/* eslint-disable no-debugger */

// =============================================================:
function* getDividentsWorker({ payload }: PayloadAction<TDividentsRequest>) {
	try {
		const { params } = payload;
		const response: TDividentsResponse = yield call(api.dividents.getDiviedents, params);
		yield put(getDividentsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(dividentsInitState());
			responseErrors(error);
		}
		yield put(dividentsInitState());
	}
}

// =============================================================:
function* getExpensesWorker({ payload }: PayloadAction<IExpensesRequest>) {
	try {
		const response: IExpensesResponse = yield call(api.dividents.getExpenses, payload);
		yield put(getExpensesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(dividentsInitState());
			responseErrors(error);
		}
		yield put(dividentsInitState());
	}
}

function* getExpensesSaveWorker({ payload }: PayloadAction<IExpensesSaveRequest>) {
	try {
		yield call(api.dividents.postExpenses, payload);
		// yield put(getExpensesSuccess(response));
		yield put(
			getExpensesRequest({ sort_by: undefined, status: undefined, sort_direction: undefined }),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(dividentsInitState());
			responseErrors(error);
		}
		yield put(dividentsInitState());
	}
}
// =============================================================:
export function* DividentsSaga() {
	yield takeEvery(getDividentsRequest.type, getDividentsWorker);
	yield takeEvery(getExpensesRequest.type, getExpensesWorker);
	yield takeEvery([saveExpensesRequest.type, updateExpensesRequest.type], getExpensesSaveWorker);
}
