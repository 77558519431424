import { FC, useState, ChangeEvent } from 'react';
import { TableSwitch } from 'ui/TableSwitch';
import { useDispatch } from 'react-redux';
import { putLiquidity } from 'redux/reducers/pools/reducer';
import { toMaxDecimals, numberOutDecimal } from 'services/utils/numbers';
import { TPool } from './type';

export const Item: FC<TPool> = ({ pool }) => {
	const {
		asset_base,
		asset_quote,
		count_user_in_pool,
		id,
		top_pair,
		base_reserve_usd,
		quote_reserve_usd,
		reserve_base,
		reserve_quote,
	} = pool;
	const dispatch = useDispatch();
	const [data, setData] = useState(pool);

	const handleLock = (lock: number) => {
		return lock === 1 ? (
			<p className="status-tag status-tag--error">Lock</p>
		) : (
			<p className="status-tag status-tag--success">Unlock</p>
		);
	};

	const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.currentTarget;
		const check = Number(checked) === 0 ? 1 : 0;
		setData({ ...data, [name]: check });
		const type = name === 'lock_swap' ? 'swap' : 'liquidity';
		const payload = {
			pair_id: data?.id,
			lock: check,
		};
		dispatch(putLiquidity({ payload, type }));
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Num.</p>
				<p>{id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Tokens/pairs</p>
				<div className="token-line">
					<img className="token-line__icon" src={asset_base?.logo} alt={asset_base?.name} />
					<span className="token-line__text">{asset_base?.symbol}</span>
					<span className="token-line__text">/</span>
					<img className="token-line__icon" src={asset_quote?.logo} alt={asset_quote?.name} />
					<span className="token-line__text">{asset_quote?.symbol}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Users in pool</p>
				<p>{count_user_in_pool}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Balance token 1</p>
				<p>{numberOutDecimal(reserve_base, asset_base?.decimals)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Balance token 2</p>
				<p>{numberOutDecimal(reserve_quote, asset_quote?.decimals)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount of swap</p>
				<p>{toMaxDecimals(top_pair?.swap_size_in_usd || 0, 9)} GUSD</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total comission</p>
				<p>{toMaxDecimals(top_pair?.total_commission_in_gato || 0, 9)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Comission distributed</p>
				<p>{toMaxDecimals(top_pair?.user_commissions_in_gato || 0, 9)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Gatochain profit</p>
				<p>{toMaxDecimals(top_pair?.platform_commissions_in_gato || 0, 9)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{handleLock(data?.lock_add_liquidity)}
			</div>
			<div className="td ">
				<p className="td-hidden-name">Lock/Unlock</p>
				<TableSwitch edit={id} checked={data?.lock_add_liquidity}>
					<input
						name="lock_add_liquidity"
						type="checkbox"
						className="hidden"
						checked={data?.lock_add_liquidity === 0}
						onChange={handleCheckbox}
					/>
				</TableSwitch>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Hide in exchange </p>
				<TableSwitch edit={id} checked={data?.lock_swap}>
					<input
						name="lock_swap"
						type="checkbox"
						className="hidden"
						checked={data?.lock_swap === 0}
						onChange={handleCheckbox}
					/>
				</TableSwitch>
			</div>
		</div>
	);
};
