import { FC } from 'react';
import { Link } from 'react-router-dom';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	getTimeParserDateFromTimestamp,
	getTimeParserTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { THistory } from './type';

export const Item: FC<THistory> = ({ data }) => {
	const { status, user_id, type, created_at, base_asset, quote_asset, gas_asset, fee } = data;

	const changeType = (value: string) => {
		if (value === 'add_liquidity') {
			return 'Add liquidity';
		}
		if (value === 'delete_liquidity') {
			return 'Delete liquidity';
		}
		return 'Add liquidity';
	};

	const handleStatus = (value: string) => {
		if (value === 'completed') {
			return <p className="status-tag status-tag--success">Completed</p>;
		}
		if (value === 'pending') {
			return <p className="status-tag status-tag--success">Pending</p>;
		}
		return <p className="status-tag status-tag--error">Faild</p>;
	};

	const handleCopy = () => {
		notificationContainer('Copied successfully!', 'info');
	};
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">User ID</p>
				<p>
					<Link className="link" to={`/user-details/${String(user_id)}`}>
						{user_id}
					</Link>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Time</p>
				<p>
					{getTimeParserDateFromTimestamp(created_at)} {getTimeParserTimeFromTimestamp(created_at)}{' '}
					UTC
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<div className="token-line">
					<img className="token-line__icon" src={base_asset?.logo} alt={base_asset?.name} />
					<span className="token-line__text">{base_asset?.symbol}</span>
					<span className="token-line__text">/</span>
					<img className="token-line__icon" src={quote_asset?.logo} alt={quote_asset?.name} />
					<span className="token-line__text">{quote_asset?.symbol}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{changeType(type)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>
					{fee} {gas_asset?.symbol}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{handleStatus(status)}
			</div>
		</div>
	);
};
