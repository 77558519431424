/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
// import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
// import { notificationContainer } from 'services/utils/notificationContainer';
import {
	TPoolsRequest,
	TPoolsDataResponse,
	TLockLiquidityPayload,
	IPoolTransactionsResponse,
} from './types';
import {
	poolsInitState,
	getPoolsRequest,
	getPoolsSuccess,
	putLiquidity,
	getHIstoryRequest,
	getHistorySuccess,
} from './reducer';
/* eslint-disable no-debugger */

// =============================================================:
function* getPoolsWorker(action: PayloadAction<TPoolsRequest>) {
	const { payload } = action;

	try {
		const response: TPoolsDataResponse = yield call(api.pools.getPools, payload);
		yield put(getPoolsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(poolsInitState());
			responseErrors(error);
		}
		yield put(poolsInitState());
	}
}

// =============================================================:
function* putPoolsLiqudity(action: PayloadAction<TLockLiquidityPayload>) {
	const { payload } = action;

	try {
		yield call(api.pools.putPool, payload);
		// yield put(getPoolsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(poolsInitState());
			responseErrors(error);
		}
		yield put(poolsInitState());
	}
}

// =============================================================:
function* getHistoryWorker(action: PayloadAction<TPoolsRequest>) {
	const { payload } = action;

	try {
		const response: IPoolTransactionsResponse = yield call(
			api.pools.getHistoryTransaction,
			payload,
		);
		yield put(getHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(poolsInitState());
			responseErrors(error);
		}
		yield put(poolsInitState());
	}
}
// =============================================================:
export function* poolsSaga() {
	yield takeEvery(getPoolsRequest.type, getPoolsWorker);
	yield takeEvery(putLiquidity.type, putPoolsLiqudity);
	yield takeEvery(getHIstoryRequest.type, getHistoryWorker);
}
