import { FC, useEffect, useState } from 'react';
import { ArrowBack, SearchIcon } from 'assets/custom-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRefarralRequest } from 'redux/reducers/referral/reducer';
import { TUserReferralRequest } from 'redux/reducers/referral/types';
import { getUserReferral } from 'redux/reducers/referral/selectors';

import { useDebounce } from 'hooks/useDebounce';
import { Item } from './Item';
import { MoveUser } from './PopUp/MoveUser';

// /* eslint-disable */
export const UserReferral: FC = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const userReferral = useSelector(getUserReferral);
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearch = useDebounce(searchValue, 500);

	const navigate = useNavigate();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};
	useEffect(() => {
		if (id) {
			const params: TUserReferralRequest = {
				id,
				params: { current_page: 1, search: debouncedSearch },
			};
			dispatch(getUserRefarralRequest(params));
		}
	}, [dispatch, id, debouncedSearch]);

	// useEffect(() => {
	// 	if (openModal && userId) {
	// 		const payload = {
	// 			params: {
	// 				exceptionId: String(userId),
	// 				// id: '' || undefined,
	// 			},
	// 		};
	// 		dispatch(getReferralUsersListRequest(payload));
	// 	}
	// }, [dispatch, userId, openModal]);

	return (
		<div className="main-content">
			<div className="wrapper-referral">
				{/* <div className="back">
					<a className="back__button" href="/">
						<ArrowBack />
					</a>
					<p className="back__text">{id}</p>
				</div> */}
				<div className="panel-block">
					<div className="title-block">
						<p className="title">Referral</p>
					</div>
					<div className="filter-group">
						<div className="panel-search">
							<div className="input-wrapper">
								<input
									className="input-item input-item--left-icon input-item--type2"
									type="text"
									placeholder="Search by email"
									value={searchValue}
									onChange={handleChange}
								/>
								<span className="input-icon input-icon--left input-icon--color2">
									<SearchIcon />
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="content-block">
					<div className="user-info info-referral--grid">
						<div className="info-block">
							<button onClick={() => navigate(-1)} type="button" className="back__button">
								<ArrowBack />
							</button>
						</div>
						<div className="info-block">
							<p className="info-line__name">Referral ID:</p>
							<p className="info-line__value">{userReferral?.id}</p>
						</div>
						<div className="info-block">
							<p className="info-line__name">Full Name:</p>
							<p className="info-line__value">--/--</p>
						</div>
						<div className="info-block">
							<p className="info-line__name">Email:</p>
							<p className="info-line__value">{userReferral?.email}</p>
						</div>
						<div className="info-block">
							<p className="info-line__name">User came to gato platform:</p>
							<p className="info-line__value">{userReferral?.number_of_referrals || 0}</p>
						</div>
						<div className="info-block">
							<p className="info-line__name">User total referrals number:</p>
							<p className="info-line__value">{userReferral?.referrals?.total}</p>
						</div>
					</div>
				</div>
				<div className="table-block table-block--margin">
					<div className="table-wrapper">
						<div className="table table--user-referral">
							<div className="table-header">
								<div className="tr tr--referral-row">
									<div className="td">
										<div className="td-name">
											<p>Level</p>
										</div>
									</div>

									<div className="td">
										<div className="td-name">
											<p>Total members</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Details</p>
										</div>
									</div>
								</div>
							</div>
							{/* <div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name td-name__flex td-name--position">
											<p>User ID</p>
											
										</div>
									</div>

									<div className="td">
										<div className="td-name">
											<p>Email</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Referral ID</p>
										</div>
									</div>
									<div className="td td--icon-position">
										<div className="td-name">
											<p>Level</p>
										</div>
									</div>
									<div className="td td--icon-position">
										<div className="td-name">
											<p>Data</p>
										</div>
									</div>

									<div className="td td--icon-position">
										<div className="td-name td-name__flex td-name--position">
											<p>Actions</p>
										</div>
									</div>
								</div>
							</div> */}
							{/* <div className="table-body table--position">
							{referralLoader && (
								<div className="table-loader-wrapper">
									<Loader />
								</div>
							)} */}
							{userReferral?.referrals?.response?.length
								? userReferral?.referrals?.response?.map((referra: any, ids) => (
										<Item key={`${String(referra?.level)} ${String(ids)}`} referral={referra} />
								  ))
								: ''}
							{/* </div>  */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
