import { FC, useEffect, useState } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import SelectDropdown from 'ui/SelectDropdown';
import { Formik, Form, Field } from 'formik';
import yup from 'services/utils/capsLockValidate';
import { IAsset } from 'redux/reducers/tokens/types';
import { CloseButtonPopupIcon } from 'assets/custom-icons';
import { IReferralUser } from 'redux/reducers/referral/types';
import { useDispatch, useSelector } from 'react-redux';
import { IExpensesSaveRequest } from 'redux/reducers/dividents/types';
import { saveExpensesRequest } from 'redux/reducers/dividents/reducer';
import { getUserReferralList } from 'redux/reducers/referral/selectors';
import Input from 'ui/Formik/Input';
import { IPropsModal } from './types';

export const Expenses: FC<IPropsModal> = ({ openModal, closeModal }) => {
	const dispatch = useDispatch();

	const initialValues = {
		description: '',
		amount: '',
	};
	/* eslint-disable no-debugger */
	const validationSchema = yup.object().shape({
		description: yup.string().required('required description'),
		amount: yup.string().required('amount required'),
	});

	const onSubmit = (value: IExpensesSaveRequest) => {
		dispatch(saveExpensesRequest(value));
		closeModal();
	};

	return (
		<ModalWarapper openModal={openModal} setOpenModal={closeModal}>
			<div className="popup popup--w360">
				<button type="button" className="popup__close" onClick={closeModal}>
					<CloseButtonPopupIcon />
				</button>
				<div className="popup-header">
					<p className="popup-header__title">Add Expenses</p>
				</div>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, { resetForm, setSubmitting }) => {
						onSubmit({ ...values });
						setSubmitting(false);
						resetForm();
					}}
					validateOnBlur
					enableReinitialize
				>
					{({ isSubmitting, isValid, dirty }) => (
						<Form>
							<div className="popup-body">
								<Field
									className="input-item"
									type="text"
									name="description"
									title="Description"
									placeholder="Enter description"
									component={Input}
								/>
								<Field
									className="input-item"
									type="text"
									name="amount"
									title="Amount (USD)"
									placeholder="Enter amount"
									component={Input}
								/>
							</div>
							<div className="popup-footer">
								<button type="submit" className="button button--full-width">
									Confirm
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</ModalWarapper>
	);
};
