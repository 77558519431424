import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	IPoolsStore,
	IPoolTransactionsResponse,
	TPoolsDataResponse,
} from 'redux/reducers/pools/types';

// ==========================================:
const getWalletsState = (state: TStoreState): IPoolsStore => state.pools;

// ====================================================:
export const getPools = createSelector(
	[getWalletsState],
	(pools: IPoolsStore): TPoolsDataResponse | null => pools.pools,
);

export const getPoolsLoader = createSelector(
	[getWalletsState],
	(pools: IPoolsStore): boolean => pools.poolsLoading,
);
export const getTransactionHistorys = createSelector(
	[getWalletsState],
	(pools: IPoolsStore): IPoolTransactionsResponse | null => pools.history,
);

export const getHistoryLoader = createSelector(
	[getWalletsState],
	(pools: IPoolsStore): boolean => pools.historyLoader,
);
