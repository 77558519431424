import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';
import { useNavigate } from 'react-router';
import { logoutRequest } from 'redux/reducers/auth/reducer';

export const AuthHeader: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userData = useSelector(getUserData);

	const handleSelect = (link: string): void => {
		if (link === '/logout') {
			dispatch(logoutRequest({ navigate }));
		}

		navigate(link);
	};
	return (
		<div className="admin">
			<p className="admin__name"> {userData?.email} </p>
			<button type="button" onClick={() => handleSelect('/logout')} className="admin__logout">
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M14.4399 11.62L16.9999 9.06L14.4399 6.5"
						stroke="#070708"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M6.76001 9.06006H16.93"
						stroke="#070708"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M8.76001 17C4.34001 17 0.76001 14 0.76001 9C0.76001 4 4.34001 1 8.76001 1"
						stroke="#070708"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
		</div>
	);
};
