/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IUser,
	IUsersRequest,
	IUsersResponse,
	IUsersStore,
	IProfitResponse,
	IDownloadList,
	IDeleteRequest,
} from './types';

export const initialState: IUsersStore = {
	users: null,
	user: null,
	userLoading: false,
	token: null,
	userProfit: null,
};

const users = createSlice({
	name: '@@users',
	initialState,
	reducers: {
		usersRequest: (state, { payload }: PayloadAction<IUsersRequest>) => {
			state.userLoading = true;
		},
		usersSuccess: (state, { payload }: PayloadAction<IUsersResponse>) => {
			state.userLoading = false;
			state.users = payload;
		},
		userRequest: (state, { payload }: PayloadAction<string>) => {
			state.userLoading = true;
		},
		userSuccess: (state, { payload }: PayloadAction<IUser>) => {
			state.userLoading = false;
			state.user = payload;
		},
		resetPassword: (state, { payload }: PayloadAction<string>) => {
			const usersState = state.users?.users;
			if (usersState) {
				const currentUser = usersState.find((el) => el.id === payload);
				if (currentUser) {
					currentUser.password_reset_recommendation = true;
				}
			}
		},
		userProfitRequest: (state, action: PayloadAction<string>) => {},
		userProfitSuccess: (state, { payload }: PayloadAction<IProfitResponse>) => {
			state.userProfit = payload;
		},
		generateSecretKeyRequest: (state, action: PayloadAction<IDownloadList>) => {},
		generateSecretKeySuccess: (state, { payload }: PayloadAction<any>) => {
			state.token = payload;
		},
		getDownloadsListRequest: (state, action: PayloadAction<string>) => {},
		deleteUserRequest: (state, action: PayloadAction<IDeleteRequest>) => {},
		deleteUserSuccess: (state, { payload }: PayloadAction<string>) => {
			const usersState = state.users?.users;
			if (usersState) {
				usersState.filter((el) => +el.id !== +payload);
			}
		},
	},
});

export default users.reducer;
export const {
	usersRequest,
	usersSuccess,
	userRequest,
	userSuccess,
	resetPassword,
	userProfitRequest,
	userProfitSuccess,
	generateSecretKeyRequest,
	getDownloadsListRequest,
	generateSecretKeySuccess,
	deleteUserRequest,
	deleteUserSuccess,
} = users.actions;
