import classNames from 'classnames';
import { sanitize } from 'dompurify';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';
import {
	disable2faRequest,
	enable2faRequest,
	generate2faKeyRequest,
} from 'redux/reducers/settings/reducer';
import { get2faData } from 'redux/reducers/settings/selectors';

import appleButton from '../../../assets/images/apple-button.svg';
import googleButton from '../../../assets/images/google-button.svg';

const TwoFactorAuthentication = () => {
	const dispatch = useDispatch();
	const [open2Fa, setOpen2Fa] = useState<boolean>(false);
	const userData = useSelector(getUserData);
	const twoFaData = useSelector(get2faData);

	const handleOpen = () => {
		setOpen2Fa(!open2Fa);
	};
	const [inputValue, setInputValue] = useState<string>('');

	const handleText = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 6) return;
		setInputValue(e.target.value);
	};

	const handleGenerate2faKey = () => {
		if (!userData?.google2fa_enabled) dispatch(generate2faKeyRequest());
		setOpen2Fa(true);
	};

	const handleTwoFa = () => {
		userData?.google2fa_enabled
			? dispatch(disable2faRequest({ totp: inputValue }))
			: dispatch(enable2faRequest({ totp: inputValue }));
	};

	return (
		<div className="content-block content-block--mt0">
			{!open2Fa ? (
				<>
					<div className="block-header block-header--border-bottom">
						<p className="block-subtitle block-subtitle--fz16">Two-Factor Authentication</p>
					</div>
					<div className="block-body">
						<p className="block-text">
							For your security, we&nbsp;strongly recommend enabling 2FA&nbsp;on&nbsp;your account.
							After that, you will need to&nbsp;enter a&nbsp;one-time 6-digit code each time you are
							logging into your account, withdrawing funds or&nbsp;changing the password.
						</p>
						<button
							type="button"
							onClick={handleGenerate2faKey}
							className={classNames('button button--full-width button--size2', {
								'button--type4': userData?.google2fa_enabled,
							})}
						>
							{userData?.google2fa_enabled ? 'Disable 2FA' : 'Enable 2FA'}
						</button>
					</div>
				</>
			) : (
				<>
					<div className="block-header block-header--border-bottom">
						<p className="block-subtitle block-subtitle--fz16">
							{userData?.google2fa_enabled ? 'Disable 2FA' : 'Two-Factor Authentication'}
						</p>
					</div>
					<div className="block-body block-body--type2">
						{userData?.google2fa_enabled ? (
							<>
								<p className="block-text">
									Two-factor Authentication is enabled. If you want to disable it,please input the
									6-digit on your google authenticator
								</p>
								<div className="input">
									<p className="input__name input__name--fw500">2FA Code from Authenticator App</p>
									<div className="input-wrapper">
										<input
											onChange={handleText}
											className="input-item"
											type="text"
											placeholder="Enter a 6-digit 2FA code"
										/>
									</div>
								</div>
							</>
						) : (
							<>
								<p className="block-text">
									For your security, we strongly recommend enabling 2FA on your account. After that,
									you will need to enter a one-time 6-digit code each time you are logging into your
									account, withdrawing funds or changing the password.
								</p>
								<div className="tfa-container">
									<div className="tfa-item">
										<div className="tfa-item-header">
											<div className="tfa-item-icon">1</div>
											<p className="block-subtitle block-subtitle--fz16">Download 2FA App</p>
										</div>
										<p className="block-text block-text--mb24">
											You should download and install an authenticator application, such as Google
											Authenticator or Authy, on your mobile device. This app will generate one-time
											6-digit access codes, which are required for logging into your account.
										</p>
										<div className="get-it-links">
											<a href="!#" className="get-it-link">
												<img src={appleButton} alt="apple-download" />
											</a>
											<a href="!#" className="get-it-link">
												<img src={googleButton} alt="google-download" />
											</a>
										</div>
									</div>
									<div className="tfa-item">
										<div className="tfa-item-header">
											<div className="tfa-item-icon">2</div>
											<p className="block-subtitle block-subtitle--fz16">
												Save 2FA Backup Key and Scan QR Code
											</p>
										</div>
										<p className="block-text block-text--mb24">
											Please, backup your 2FA&nbsp;recovery key to&nbsp;some offline storage, write
											down on&nbsp; a&nbsp;paper. If&nbsp;your mobile device gets lost, stolen
											or&nbsp;erased, you will need this key to&nbsp;recover your 2FA&nbsp;access.
										</p>
										<div className="qr-container">
											<div
												// eslint-disable-next-line
												dangerouslySetInnerHTML={{ __html: sanitize(twoFaData?.QR_Image || '') }}
											/>
											{/* <img className="qr-img" src={twoFaData?.QR_Image} alt="qr-code" /> */}
											<div className="qr-info">
												<div className="input">
													<p className="input__name input__name--fw500">Backup / Secret Key</p>
													<div className="input-wrapper">
														<input
															className="input-item input-item--type4"
															type="text"
															placeholder="Secret Key"
															defaultValue={twoFaData?.secret}
														/>
													</div>
												</div>
												<div className="block-notification">
													<div className="block-notification-item block-notification-item--warning block-notification-item--type2 block-notification-item--low-gap">
														<span className="block-notification-item__icon">
															<svg
																width="18"
																height="18"
																viewBox="0 0 18 18"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z"
																	stroke="#FD3232"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M9 6V9.75"
																	stroke="#FD3232"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8.99609 12H9.00283"
																	stroke="#FD3232"
																	strokeWidth="2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</span>
														<p className="block-notification-item__text">
															Please, write down or print a copy of the key above and store it
															safely.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="tfa-item">
										<div className="tfa-item-header">
											<div className="tfa-item-icon">3</div>
											<p className="block-subtitle block-subtitle--fz16">Confirm 2FA Activation</p>
										</div>
										<div className="input">
											<p className="input__name">2FA Code from Authenticator App</p>
											<div className="input-wrapper">
												<input
													className="input-item"
													type="text"
													placeholder="Enter a 6-digit 2FA code"
													onChange={handleText}
													value={inputValue}
												/>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
						<div className="buttons-group">
							<button
								type="button"
								onClick={handleOpen}
								className="button button--size4 button--fw500 button--type3"
							>
								Cancel
							</button>
							<button
								type="button"
								onClick={handleTwoFa}
								className="button button--fw500 button--size4"
							>
								{userData?.google2fa_enabled ? 'Disable 2FA' : 'Enable 2FA'}
							</button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default TwoFactorAuthentication;
