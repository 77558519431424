import { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { toMaxDecimals, numberOutDecimal } from 'services/utils/numbers';
import { updateExpensesRequest } from 'redux/reducers/dividents/reducer';
import { TPool } from './type';
import { Actions } from '../../Actions/Actions';
import { EditField } from '../EditField/EditField';

export const Item: FC<TPool> = ({ el }) => {
	const { amount, created_at, description, id, paid_amount } = el;
	const dispatch = useDispatch();
	const [edit, setEdit] = useState('');
	const [descript, setDescript] = useState(description || '');
	const [amounts, setAmounts] = useState(amount || '');

	const handleLock = (lock: number) => {
		return lock === 1 ? (
			<p className="status-tag status-tag--error">Lock</p>
		) : (
			<p className="status-tag status-tag--success">Unlock</p>
		);
	};

	const handleSubmit = () => {
		const payload = {
			description: descript,
			amount: amounts,
			id,
			type: 'put',
		};
		dispatch(updateExpensesRequest(payload));
	};
	const handleEdit = (vel: string) => {
		setEdit(vel);
	};
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">id</p>
				<p>{id}</p>
			</div>
			<EditField
				edit={edit}
				title="Expenses description"
				field={description}
				playholder="Enter description"
				value={descript}
				onChange={setDescript}
			/>
			<EditField
				edit={edit}
				title="Amount"
				field={amount}
				value={amounts}
				onChange={setAmounts}
				playholder="Enter amount"
			/>
			<div className="td">
				<p className="td-hidden-name">Amount get</p>
				<p>{paid_amount}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date from</p>
				<p>{created_at ? format(Date.parse(created_at), 'MM/dd/yyy hh:mm') : '--/--'}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<Actions edit={edit} setAction={handleEdit} handleSubmit={handleSubmit} />
			</div>
		</div>
	);
};
