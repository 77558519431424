import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { MainNav } from 'layouts-elements/MainNav';
import { AuthHeader } from 'layouts-elements/Header/AuthHeader';
import { AppLogo } from 'ui/AppLogo';
import { StaticHeader } from './StaticHeader';
/* eslint-disable no-debugger */

// ==========================================:
export const Header: FC = () => {
	const isAuth = useSelector(getAuthIsAuthenticated);
	// debugger;
	const [open, setOpen] = useState<boolean>(false);

	const handleMenu = () => {
		setOpen((prev) => {
			return !prev;
		});
	};

	return (
		<header className={`header ${isAuth ? 'header--autorized' : ''}`}>
			<AppLogo />
			{/* <MainNav open={open} /> */}
			{isAuth && <AuthHeader />}
		</header>
	);
};
