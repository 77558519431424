export { ReactComponent as CrossIcon } from './cross.svg';
export { ReactComponent as GearIcon } from './gear.svg';
export { ReactComponent as MagnifierIcon } from './magnifier.svg';
export { ReactComponent as RefreshIcon } from './refresh.svg';
export { ReactComponent as ReverseIcon } from './reverse.svg';
export { ReactComponent as TriangularArrowIcon } from './triangular-arrow.svg';
export { ReactComponent as QuestionIcon } from './question.svg';
export { ReactComponent as OpenEye } from './eye-icon/open.svg';
export { ReactComponent as CloseEye } from './eye-icon/close.svg';
export { ReactComponent as SwapIcon } from './type-icon/swap.svg';
export { ReactComponent as CloseButtonIcon } from './buttons-icon/close.svg';
export { ReactComponent as CloseButtonPopupIcon } from './buttons-icon/close-popup.svg';
export { ReactComponent as ArrowBack } from './buttons-icon/arrow-back.svg';
export { ReactComponent as PlusButton } from './buttons-icon/plus.svg';
export { ReactComponent as PlusGrayButton } from './buttons-icon/plus-gray.svg';
export { ReactComponent as SearchIcon } from './buttons-icon/search.svg';
export { ReactComponent as ArrowLeft } from './arrows/arrowLeft.svg';
export { ReactComponent as ArrowRight } from './arrows/arrowRight.svg';
export { ReactComponent as TransactionIcon } from './type-icon/transaction.svg';
export { ReactComponent as ArrowUp } from './arrows/arrowUp.svg';
export { ReactComponent as TooltipNotation } from './tooltip-notation.svg';
export { ReactComponent as TooltipInformation } from './tooltip-info.svg';
export { ReactComponent as CopySvg } from './buttons-icon/copy.svg';
export { ReactComponent as ClockSvg } from './clock.svg';
export { ReactComponent as CheckCircleSvg } from './check-circle.svg';
export { ReactComponent as AttentionY } from './notifications/attention.svg';
export { ReactComponent as AttentionRed } from './notifications/attentionRed.svg';
export { ReactComponent as ArrowDown } from './arrows/arrow-down.svg';
export { ReactComponent as ArrowSelect } from './arrows/arrow-select.svg';
export { ReactComponent as PercentSvg } from './percent.svg';
export { ReactComponent as B2cIcon } from './b2c.svg';
export { ReactComponent as B2BIcon } from './b2b.svg';
export { ReactComponent as Coins } from './coins.svg';
export { ReactComponent as Monintor } from './monitorIcon.svg';
export { ReactComponent as Diamant } from './diamant.svg';
export { ReactComponent as Miracl } from './miracl.svg';
export { ReactComponent as RewardClaimIcon } from './reward-claim.svg';
export { ReactComponent as RewardPoolIcon } from './reward-pool.svg';
export { ReactComponent as RewardUnclaimIcon } from './reward-unclaim.svg';
export { ReactComponent as ArrowSortDown } from './buttons-icon/arrow-sort-down.svg';
export { ReactComponent as ArrowSortUp } from './buttons-icon/arrow-sort-up.svg';
export { ReactComponent as ArrowDot } from './buttons-icon/arrow-dot.svg';
export { ReactComponent as ReferralManagIcon } from './sidebar/referral-icon.svg';
export { ReactComponent as ArrowD } from './arrows/arrowD.svg';
export { ReactComponent as ArrowU } from './arrows/arrowU.svg';
export { ReactComponent as LineDefault } from './arrows/line-default.svg';
export { ReactComponent as ActionMove } from './buttons-icon/user-cirlce-add.svg';
export { ReactComponent as LinkStaking } from './sidebar/staking-icon.svg';
export { ReactComponent as ButtonIcon } from './buttons-icon/plus-icon.svg';
export { ReactComponent as Edit } from './buttons-icon/edit.svg';
export { ReactComponent as Check } from './buttons-icon/tick-square.svg';
export { ReactComponent as CloseCrest } from './buttons-icon/close-square.svg';
