/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { TStatsResponse } from './types';
import { statsInitState, getStatsRequest, getStatsSuccess } from './reducer';
/* eslint-disable no-debugger */

// =============================================================:
function* getStatsWorker() {
	try {
		const response: TStatsResponse = yield call(api.stats.getStats);
		yield put(getStatsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(statsInitState());
			responseErrors(error);
		}
		yield put(statsInitState());
	}
}
// =============================================================:
export function* StatsSaga() {
	yield takeEvery(getStatsRequest.type, getStatsWorker);
}
