import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux/store';
import { ToastContainer } from 'react-toastify';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.scss';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root') as HTMLElement;

const root = createRoot(rootElement);

root.render(
	<ReduxProvider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
			<ToastContainer />
		</PersistGate>
	</ReduxProvider>,
);

reportWebVitals();
