import React from 'react';

import AdministrativeRoles from 'components/AdministrativeRoles';
import { Page } from 'layouts/page';

const AdministrativeRolesPage = () => {
	return (
		<Page>
			<AdministrativeRoles />
		</Page>
	);
};

export default AdministrativeRolesPage;
