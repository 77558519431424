import { FC, useState, ChangeEvent } from 'react';
import { Formik, Form, Field } from 'formik';
import yup from 'services/utils/capsLockValidate';
import Input from 'ui/Formik/Input';
import { Link } from 'react-router-dom';
import logoIcon from 'assets/images/login-icon.svg';
import { ILoginForm, ILoginSubmitValue } from './types';

export const LoginForm: FC<ILoginForm> = ({ loginSubmit }) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const [code2FA, setCode2FA] = useState('');

	const initialValues = {
		email: '',
		password: '',
		captcha: '00000000',
		totp: '',
	};
	/* eslint-disable no-debugger */
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required('required email')
			.email('Invalid email format')
			.max(60, 'email max characters 60'),
		password: yup
			.string()
			.required('password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.max(25, 'Password max length 25'),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleChange2FA = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 6) return;
		setCode2FA(e.target.value);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: ILoginSubmitValue, { resetForm, setSubmitting }) => {
				loginSubmit({ ...values, totp: String(code2FA) });
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty }) => (
				<Form>
					<div className="authorization-form__logo">
						<img src={logoIcon} alt="" />
					</div>
					<p className="form-title">Log in GATOCHAIN.</p>
					<div className="authorization-form__body">
						<Field
							type="email"
							placeholder="Email"
							name="email"
							title="Email"
							required
							component={Input}
						/>
						<Field
							type={isShowPass ? 'text' : 'password'}
							placeholder="Password"
							name="password"
							required
							title="Password"
							component={Input}
							ariaLabel="show-password"
							showPass={isShowPass}
							setIsShowPass={handlePassDisplay}
						/>
						<div className="input">
							<p className="input__name">Authenticator Code</p>
							<div className="input-wrapper">
								<input
									value={code2FA}
									onChange={handleChange2FA}
									className="input-item "
									type="number"
									placeholder=""
								/>
							</div>
							<p className="input-notification">Enter the 6-digit code from Google Authenticator</p>
						</div>
						<div className="cred-remember-block">
							<Link to="/" className="link">
								Reset password
							</Link>
						</div>
						<div className="form-submit">
							<button
								disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								aria-label="form-submit"
								className="button button--size2 button--full-width"
							>
								Log in
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
