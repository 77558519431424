import { FC, MouseEvent } from 'react';
import {
	ArrowSortDown,
	ArrowSortUp,
	ArrowDot,
	ArrowSelect,
	ArrowDown,
	ArrowLeft,
	ArrowD,
	ArrowU,
	LineDefault,
} from 'assets/custom-icons';
import classNames from 'classnames';
import { IArrowSort } from './types';

export const SortArrowButton: FC<IArrowSort> = ({
	name: reName,
	sortDirection,
	setSortDirection,
	setNameField,
}) => {
	const clickArrow = (e: MouseEvent<HTMLButtonElement>) => {
		const { name: value } = e.currentTarget;
		setSortDirection({ [reName]: String(value) });
		setNameField(value ? reName : '');
	};
	return (
		<div className="td-sorting-arrow-button td-sorting-arrow-button--position">
			<div className="td-sorting-arrow td-sorting-arrow--flex">
				<button
					onClick={clickArrow}
					type="button"
					name="desc"
					className={classNames('td-sorting-arrow__item', {
						active: sortDirection[reName] === 'desc',
					})}
				>
					<ArrowU />
				</button>
				<button
					onClick={clickArrow}
					type="button"
					name=""
					style={{ padding: '0 3px' }}
					className={classNames('td-sorting-arrow__item td-sorting-arrow--center', {
						active: sortDirection[reName] === '',
					})}
				>
					<ArrowDot />
				</button>
				<button
					onClick={clickArrow}
					type="button"
					name="asc"
					className={classNames('td-sorting-arrow__item ', {
						active: sortDirection[reName] === 'asc',
					})}
				>
					<ArrowD />
				</button>
			</div>
		</div>
	);
};
