import { FC } from 'react';
import { Edit, Check, CloseCrest } from 'assets/custom-icons';
import { IAction } from './type';

export const Actions: FC<IAction> = ({ edit, setAction, handleSubmit }) => {
	return (
		<div className="table-buttons">
			{edit ? (
				<>
					<button onClick={handleSubmit} type="button">
						<Check />
					</button>
					<button type="button" onClick={() => setAction('')}>
						<CloseCrest />
					</button>
				</>
			) : (
				<button type="button" onClick={() => setAction('edit')}>
					<Edit />
				</button>
			)}
		</div>
	);
};
