import { TStoreState } from 'redux/types';
import { IUsersStore } from './types';

const usersState = (state: TStoreState): IUsersStore => state.users;

export const getUsers = (state: TStoreState) => usersState(state).users;
export const getUser = (state: TStoreState) => usersState(state).user;
export const usersLoading = (state: TStoreState) => usersState(state).userLoading;
export const getProfits = (state: TStoreState) => usersState(state).userProfit;
export const getSecretToken = (state: TStoreState) => usersState(state).token;
