import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPoolsRequest } from 'redux/reducers/pools/reducer';
import { getPools, getPoolsLoader } from 'redux/reducers/pools/selectors';
import Loader from 'ui/Loader';
import usePagination from 'hooks/usePagination';
import { Item } from './item';
import { TLiquidity } from './types';

export const Liquidity: FC<TLiquidity> = ({ search, setTotalPages }) => {
	const dispatch = useDispatch();
	const poolsList = useSelector(getPools);
	const poolsLoader = useSelector(getPoolsLoader);
	const { perPage, currentPage } = usePagination();
	const totalPages = poolsList?.last_page || 1;
	useEffect(() => {
		setTotalPages(totalPages);
		// eslint-disable-next-line
	}, [totalPages]);

	useEffect(() => {
		const body = {
			params: search
				? {
						per_page: perPage,
						current_page: currentPage,
						search,
				  }
				: {
						per_page: perPage,
						current_page: currentPage,
				  },
		};
		dispatch(getPoolsRequest(body));
	}, [dispatch, currentPage, perPage, search]);
	return (
		<div className="table table--pool-liquidity">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Num.</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Tokens/pairs</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Users in pool</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Balance token 1</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Balance token 2</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Amount of swap</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Total comission</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Comission distributed</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Gatochain profit</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Status</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Lock/Unlock</p>
						</div>
					</div>
					<div className="td ">
						<div className="td-name">
							<p>Hide in exchange</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body table--position">
				{poolsLoader && (
					<div className="table-loader-wrapper">
						<Loader />
					</div>
				)}
				{!poolsLoader && poolsList?.data?.length
					? poolsList?.data?.map((pool) => <Item key={pool.id} pool={pool} />)
					: null}
			</div>
		</div>
	);
};
