import { FC, MouseEvent } from 'react';
import useSelect from 'hooks/useSelect';
import { TAssetStatus } from 'redux/reducers/tokens/types';
import { useNavigate } from 'react-router';
import { IStatusFilter } from './types';

const OPTIONS: { value: TAssetStatus; label: string }[] = [
	{
		value: 'pending',
		label: 'Pending',
	},
	{
		value: 'blockchain_pending',
		label: 'Blockchain pending',
	},
	{
		value: 'approved',
		label: 'Approved',
	},
];

const StatusFilter: FC<IStatusFilter> = ({ onChange, value }) => {
	const { open, setOpen, toggleOpen, currentRef, dropRef } = useSelect();
	const navigate = useNavigate();

	const handleSelect = (e: MouseEvent<HTMLButtonElement>) => {
		const { value: v } = e.target as HTMLButtonElement;
		onChange && onChange(v as TAssetStatus);
		setOpen(false);
		navigate({ search: '' });
	};

	const selectedOption = value && OPTIONS.find((opt) => opt.value === value);

	return (
		<div className={`select select--type2 ${open ? ' active' : ''}`}>
			<button type="button" className="select__current" onClick={toggleOpen} ref={currentRef}>
				{selectedOption && selectedOption.label}
				<span className="select__current-arrow">
					<svg
						width="14"
						height="7"
						viewBox="0 0 14 7"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12.28 1.4668L7.93333 5.81346C7.42 6.3268 6.58 6.3268 6.06667 5.81346L1.72 1.4668"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<ul>
							{OPTIONS.map((item) => (
								<li key={item.value}>
									<button type="button" value={item.value} onClick={handleSelect}>
										{item.label}
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StatusFilter;
