import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Auth } from 'layouts/Auth';
import { Link } from 'react-router-dom';
import { ForgotPassForm } from 'components/Forms/ForgotPassForm';
import { IForgotPassSubmitValue } from 'components/Forms/ForgotPassForm/types';
import { forgotPasswordRequest } from 'redux/reducers/auth/reducer';
import { getForgotPassIsLoad } from 'redux/reducers/auth/selectors';
import Loader from 'ui/Loader';
/* eslint-disable no-debugger */
export const ResetPassword: FC = () => {
	const forgotPassIsLoad = useSelector(getForgotPassIsLoad);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const validateEmail = (email: string) => {
		// const yes = email.match(
		// 	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(\/".+\/"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		// );
		const search = '[a-z0-9]+@[a-z]+.[a-z]{2,3}';
		const regex = new RegExp(`${search}`, 'i');
		if (regex.test(email)) {
			return true;
		}
		return false;
	};

	const handleSubmitEmail = (values: any) => {
		dispatch(forgotPasswordRequest({ email: String(values.text).toLowerCase(), navigate }));
	};
	return (
		<Auth title="ResetPassword">
			<section className="authorization-section">
				<div className="container">
					<div className="authorization-box">
						<div className="authorization-form">
							<ForgotPassForm emailSubmit={handleSubmitEmail} />
						</div>
						<div className="cancel-button">
							<Link
								to="/login"
								className="button button--big-height button--full-width button--transparent"
							>
								Cancel
							</Link>
						</div>
					</div>
					{forgotPassIsLoad && (
						<div className="auth-loader-wrapper">
							<Loader />
						</div>
					)}
				</div>
			</section>
		</Auth>
	);
};
