import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExchangeRequest } from 'redux/reducers/exchange/reducer';
import { getPairs, getPoolsLoader } from 'redux/reducers/exchange/selectors';
import Loader from 'ui/Loader';
import usePagination from 'hooks/usePagination';
import { Item } from './item';
import { TPair } from './types';

export const Pair: FC<TPair> = ({ search, setTotalPages }) => {
	const dispatch = useDispatch();
	const pairList = useSelector(getPairs);
	const pairLoader = useSelector(getPoolsLoader);
	const { perPage, currentPage } = usePagination();
	const totalPages = pairList?.last_page || 1;
	useEffect(() => {
		setTotalPages(totalPages);
		// eslint-disable-next-line
	}, [totalPages]);

	useEffect(() => {
		const body = {
			params: search
				? {
						per_page: perPage,
						current_page: currentPage,
						search,
				  }
				: {
						per_page: perPage,
						current_page: currentPage,
				  },
		};
		dispatch(getExchangeRequest(body));
	}, [dispatch, currentPage, perPage, search]);
	return (
		<div className="table table--exchange-swap-pair">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Num.</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Pairs</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Volume</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Total number of transactions</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Total comission amount</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Total comission distrbuted</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Gatochain profit</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body table--position">
				{pairLoader && (
					<div className="table-loader-wrapper">
						<Loader />
					</div>
				)}
				{!pairLoader && pairList?.data?.length
					? pairList?.data?.map((pool) => <Item key={pool.id} pool={pool} />)
					: null}
			</div>
		</div>
	);
};
