/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { TStakingRequest, TStakingTransactionsResponse } from './types';
import { getStakingRequest, getStakingSuccess, StakingInitState } from './reducer';

// =============================================================:
function* getStakingWorker(action: PayloadAction<TStakingRequest>) {
	const { payload } = action;

	try {
		const response: TStakingTransactionsResponse = yield call(api.staking.getTransactions, payload);
		yield put(getStakingSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(StakingInitState());
			responseErrors(error);
		}
		yield put(StakingInitState());
	}
}

// =============================================================:
export function* stakingSaga() {
	yield takeEvery(getStakingRequest.type, getStakingWorker);
}
