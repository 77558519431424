/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
// import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	IAssetsResponse,
	IGetAssetsParams,
	IEditFeePayload,
	ITokenUpdateRequest,
	ICalculateFeePayload,
	IAsset,
	IGetAssetTokenDetailsRequest,
	IGetAssetTokenDetailsResponse,
	IGetTransactionTokensRequest,
	IGetTransactionTokensResponse,
	IGetAssetTokenTotalDetailsRequest,
} from './types';
import {
	editFeeCoinRequest,
	editFeeRequest,
	editFeeSuccess,
	editTokenRequest,
	getAssetRequest,
	getAssetsCustomRequest,
	getAssetsCustomSuccess,
	getAssetsRequest,
	getAssetsSuccess,
	getAssetSuccess,
	getFeeInTokenRequest,
	getFeeInTokenSuccess,
	toggleActivityRequest,
	toggleActivitySuccess,
	getAssetTokenDetailsRequest,
	getAssetTokenDetailsSuccess,
	getTokenTransactionsRequest,
	getTokenTransactionsSuccess,
	getTokenTransactionsClaimSuccess,
	getTokenTransactionsClaimRequest,
	getTokenTotalDetailsRequest,
	getTokenTotalDetailsSuccess,
} from './reducer';
/* eslint-disable no-debugger */

// =============================================================:
function* getAssetsRequestWorker(action: PayloadAction<IGetAssetsParams>) {
	const { payload } = action;

	try {
		const response: IAssetsResponse = yield call(api.tokens.getAssets, payload);

		yield put(getAssetsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getAssetsCustomRequestWorker(action: PayloadAction<IGetAssetsParams>) {
	const { payload } = action;

	try {
		const response: IAssetsResponse = yield call(api.tokens.getAssetsCustom, payload);
		yield put(getAssetsCustomSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getAssetWorker({ payload }: PayloadAction<number>) {
	try {
		const response: IAsset = yield call(api.tokens.getAssetItem, payload);
		yield put(getAssetSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* editFeeWorker({ payload }: PayloadAction<IEditFeePayload>) {
	try {
		yield call(api.tokens.editFee, payload);
		yield put(editFeeSuccess(payload));
		notificationContainer(`${String(payload?.name) || ''} fee changed successfully`, 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'token_is_not_created_by_admin') {
				notificationContainer('Token is not created by admin', 'error');
			}
		}
	}
}

function* editFeeCoinWorker({ payload }: PayloadAction<IEditFeePayload>) {
	try {
		yield call(api.tokens.editFeeCoin, payload);
		/// yield delay(2000);
		yield put(getAssetsRequest({ per_page: 1000 }));
		notificationContainer('GATO Coin fee changed successfully', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* toggleActivityWorker({ payload }: PayloadAction<number>) {
	try {
		const response: IAsset = yield call(api.tokens.toggleActive, payload);
		yield put(toggleActivitySuccess(response));
		notificationContainer(`Status ${response.symbol} was changed`, 'success');
	} catch (error) {
		notificationContainer('Status chainging failed', 'error');
	}
}

function* getFeeInTokenWorker({ payload }: PayloadAction<ICalculateFeePayload>) {
	try {
		const response: number = yield call(api.tokens.calculateFee, payload.requestParams);
		yield put(getFeeInTokenSuccess({ fee: response, fee_name: payload.fee_name }));
	} catch (error) {
		yield put(getFeeInTokenSuccess({ fee: 0, fee_name: payload.fee_name }));
	}
}

function* updateTokenWorker({ payload }: PayloadAction<ITokenUpdateRequest>) {
	try {
		const response: IAsset = yield call(api.tokens.editToken, payload);
		notificationContainer(`${response.symbol} updated`, 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
	}
}
function* getAssetTokenDetailsWorker({ payload }: PayloadAction<IGetAssetTokenDetailsRequest>) {
	try {
		const response: IGetAssetTokenDetailsResponse = yield call(
			api.tokens.getDetailsAssetItem,
			payload,
		);
		yield put(getAssetTokenDetailsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
	}
}

function* getTokenTransactionsWorker({ payload }: PayloadAction<IGetTransactionTokensRequest>) {
	try {
		const { params } = payload;
		const response: IGetTransactionTokensResponse = yield call(
			api.tokens.getDetailsTokenTransactions,
			{ ...params },
		);
		yield put(getTokenTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
	}
}
function* getTokenTransactionsClaimWorker({
	payload,
}: PayloadAction<IGetTransactionTokensRequest>) {
	try {
		const { params } = payload;
		const response: IGetTransactionTokensResponse = yield call(
			api.tokens.getDetailsTokenTransactions,
			{ ...params },
		);
		yield put(getTokenTransactionsClaimSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
	}
}

function* getTokenDetailsWorker({ payload }: PayloadAction<IGetAssetTokenTotalDetailsRequest>) {
	try {
		const response: IGetAssetTokenDetailsResponse = yield call(
			api.tokens.getDetailsTotalAsset,
			payload,
		);
		yield put(getTokenTotalDetailsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
	}
}

// =============================================================:
export function* tokensSaga() {
	yield takeLatest(getAssetsRequest.type, getAssetsRequestWorker);
	yield takeEvery(getAssetsCustomRequest.type, getAssetsCustomRequestWorker);
	yield takeEvery(getAssetRequest.type, getAssetWorker);
	yield takeEvery(editFeeRequest, editFeeWorker);
	yield takeEvery(editFeeCoinRequest, editFeeCoinWorker);
	yield takeEvery(toggleActivityRequest, toggleActivityWorker);
	yield takeEvery(getFeeInTokenRequest, getFeeInTokenWorker);
	yield takeEvery(editTokenRequest, updateTokenWorker);
	yield takeEvery(getAssetTokenDetailsRequest, getAssetTokenDetailsWorker);
	yield takeEvery(getTokenTransactionsRequest, getTokenTransactionsWorker);
	yield takeEvery(getTokenTransactionsClaimRequest, getTokenTransactionsClaimWorker);
	yield takeEvery(getTokenTotalDetailsRequest, getTokenDetailsWorker);
}
