import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { responseErrors } from 'services/http/responseErrors';
import { WEB_API_URL } from 'services/constants/env';
import { downloadCVSFunc } from 'services/utils/downloadCVSFunc';
import { endpoint } from 'services/endpoint';
import {
	resetPassword,
	userProfitRequest,
	userProfitSuccess,
	userRequest,
	usersRequest,
	usersSuccess,
	userSuccess,
	generateSecretKeyRequest,
	getDownloadsListRequest,
	generateSecretKeySuccess,
	deleteUserRequest,
	deleteUserSuccess,
} from './reducer';
import {
	IUser,
	IUsersRequest,
	IUsersResponse,
	IProfitResponse,
	IDownloadList,
	IDeleteRequest,
} from './types';

function* getUsersWorker({ payload }: PayloadAction<IUsersRequest>) {
	try {
		const response: IUsersResponse = yield call(api.users.getUsers, payload);
		yield put(usersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getUserWorker({ payload }: PayloadAction<string>) {
	try {
		const response: IUser = yield call(api.users.getUser, payload);
		yield put(userSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* resetPasswordWorker({ payload }: PayloadAction<string>) {
	try {
		yield call(api.users.getResetPassword, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getUserProfitWorker({ payload }: PayloadAction<string>) {
	try {
		const response: IProfitResponse = yield call(api.users.getProfit, payload);
		yield put(userProfitSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getSecretKeyWorker({ payload }: PayloadAction<IDownloadList>) {
	const { onOpenPdf, fileType } = payload;
	try {
		const { code }: { code: string } = yield call(api.users.getGenerateKey);
		// const link = `${String(WEB_API_URL).replace('/api', '')}${endpoint.users.OPEN_DOWNLOAD}${String(
		// 	key,
		// )}`;
		// window.open(link);
		// onSuccess?.(key);
		const { origin } = window.location;
		if (code) {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			onOpenPdf(code);
			// `${String(
			// 	window.location.replace(
			// 		`${String(WEB_API_URL)}admin/download/users/${String(fileType)}/${String(code)}`,
			// 	),
			// )}`;
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			// const attachment = `${String(WEB_API_URL)}admin/download/users/${String(code)}`;
			// const attachment = `${String(
			// 	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			// 	window.location.replace(`${String(WEB_API_URL)}admin/download/users/${code}`),
			// )}`;
			// console.log(attachment);

			// const link = document.createElement('a');
			// link.href = attachment;
			// link.target = '_blank';
			// link.download = attachment;
			// document.body.appendChild(link);
			// link.click();
			// 		// link.remove();
			// window.location.replace(
			// 	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			// 	// `${origin}/api/admin/trade_referrals/report/trades/${payload.fileType}/${token.secret}/${payload.adminId}`,
			// 	`${String(WEB_API_URL)}admin/download/users/${code}`,
			// );
		}
		yield put(generateSecretKeySuccess(code));
		// yield put(getDownloadsListRequest(code));
	} catch (error) {
		// yield put(getInvoiceLinkError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getDownloadsListWorker({ payload }: PayloadAction<string>) {
	try {
		const response: string = yield call(api.users.getDownloadsList, payload);
		downloadCVSFunc('Export List', response);
	} catch (error) {
		// yield put(getInvoiceLinkError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* deleteUserWorker({ payload }: PayloadAction<IDeleteRequest>) {
	const { id, onSuccess } = payload;
	try {
		const response: string = yield call(api.users.deleteUser, id);
		yield put(deleteUserSuccess(id));
		onSuccess?.();
	} catch (error) {
		// yield put(getInvoiceLinkError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* usersSaga() {
	yield takeEvery(usersRequest, getUsersWorker);
	yield takeEvery(userRequest, getUserWorker);
	yield takeEvery(resetPassword, resetPasswordWorker);
	yield takeEvery(userProfitRequest, getUserProfitWorker);
	yield takeEvery(generateSecretKeyRequest, getSecretKeyWorker);
	yield takeEvery(getDownloadsListRequest, getDownloadsListWorker);
	yield takeEvery(deleteUserRequest, deleteUserWorker);
}
