/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWalletsStore, TWalletsRequest, TWalletsDataResponse } from './types';

// ==========================================:
export const initialState: IWalletsStore = {
	wallets: null,
	walletsLoading: false,
};

// ==========================================:
const wallets = createSlice({
	name: '@@wallets',
	initialState,
	reducers: {
		getWalletsRequest: (state, action: PayloadAction<TWalletsRequest>) => {
			const walletsRequestState = state;

			walletsRequestState.walletsLoading = true;
		},
		getWalletsSuccess: (state, action: PayloadAction<TWalletsDataResponse>) => {
			const { payload } = action;
			const walletsState = state;

			walletsState.wallets = payload;
			walletsState.walletsLoading = false;
		},

		walletsInitState: () => initialState,
	},
});

export default wallets.reducer;
export const { getWalletsRequest, getWalletsSuccess, walletsInitState } = wallets.actions;
