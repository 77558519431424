import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import PhoneInput from 'react-phone-input-2';
import { CopySvg } from 'assets/custom-icons';
import { IUser } from 'redux/reducers/users/types';
import { notificationContainer } from 'services/utils/notificationContainer';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { useDispatch } from 'react-redux';
import { resetPassword, deleteUserRequest } from 'redux/reducers/users/reducer';
import ResetPasswordPopUp from 'ui/Popups/PopUp/ResetPasswordPopUp';
import { format } from 'date-fns';
import { ConfirmPopUp } from '../ConfirmPopUp/ConfirmPopUp';

const UserManagementRow: FC<IUser> = ({
	id,
	email,
	data,
	phone,
	wallets,
	password_reset_recommendation,
	lastLogin,
}) => {
	const dispatch = useDispatch();
	const moneyFormat = useMoneyFormat();
	const handleCopy = (text: string) => {
		notificationContainer(`${text} copied successfully!`, 'info');
	};

	const [openConfirm, openSetConfirm] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => {
		setOpenModal(false);
	};
	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleResetPassword = () => {
		dispatch(resetPassword(id));
		setOpenModal(false);
	};

	const totalAmount = useMemo(() => {
		if (wallets) {
			return wallets.reduce(
				(acum, current) =>
					current.balances.reduce(
						(acBal, curBal) => Number(acBal) + Number(curBal.price_in_usd),
						0,
					) + acum,
				0,
			);
		}
		return 0;
	}, [wallets]);

	const submitDelete = () => {
		const payload = {
			onSuccess: () => {
				notificationContainer(`Delete successfully!`, 'info');
				openSetConfirm(false);
			},
			id,
		};
		dispatch(deleteUserRequest(payload));
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>
					<Link to={`/user-details/${id}`} className="link link--type2 ">
						{id}
					</Link>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Last login</p>
				<p>{lastLogin ? format(Date.parse(lastLogin.logged_at), 'MM/dd/yyy hh:mm') : '--/--'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Full name</p>
				<p>{data ? `${data.first_name} ${data.last_name}` : '--/--'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Last wallet connected balance</p>
				<p>{totalAmount ? `${moneyFormat(totalAmount, 2, 2)} GUSD` : '--//--'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<div className="td-two-items">
					<CopyToClipboard text={String(email)} onCopy={() => handleCopy('Email')}>
						<p>
							<span className="link link--type2 ">{email}</span>
						</p>
					</CopyToClipboard>
					<CopyToClipboard text={String(email)} onCopy={() => handleCopy('Email')}>
						<button className="copy-btn" type="button">
							<CopySvg />
						</button>
					</CopyToClipboard>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Country</p>
				<p>{lastLogin ? lastLogin.country : '--/--'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Phone number</p>
				<div className="td-two-items">
					{phone ? (
						<>
							<div className="phone-line">
								<PhoneInput value={phone} disableDropdown disabled />
							</div>
							<CopyToClipboard text={String(phone)} onCopy={() => handleCopy('Phone')}>
								<button className="copy-btn" type="button">
									<CopySvg />
								</button>
							</CopyToClipboard>
						</>
					) : (
						'--/--'
					)}
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Password reset</p>
				<div className="button-block">
					<button
						onClick={handleOpenModal}
						className="button button--min-width-75 button--small-height-2"
						type="button"
						disabled={password_reset_recommendation}
					>
						Reset
					</button>
					<button
						onClick={() => openSetConfirm(true)}
						className="button button--min-width-75 button--small-height-2 button--red"
						type="button"
						disabled={password_reset_recommendation}
					>
						Delete
					</button>
				</div>
			</div>
			<ResetPasswordPopUp
				open={openModal}
				closeModal={closeModal}
				handleResetPassword={handleResetPassword}
			/>
			<ConfirmPopUp
				openModal={openConfirm}
				openSetConfirm={openSetConfirm}
				handleSubmit={submitDelete}
			/>
		</div>
	);
};

export default UserManagementRow;
