import { FC } from 'react';
import { Header } from 'layouts-elements/Header';
import Sidebar from 'layouts-elements/Sidebar';
import { TPageProps } from './types';

// ==========================================:
export const Page: FC<TPageProps> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';

	return (
		<>
			<div className="wrapper">
				<Header />
				<main className="main">
					<div className="main-section">
						<Sidebar />
						{children}
					</div>
				</main>
			</div>
		</>
	);
};
