import { eventChannel, EventChannel } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, take, put, fork, race } from 'redux-saga/effects';
import { notificationContainer } from 'services/utils/notificationContainer';
import { WEB_SOCKETS_URL } from '../../../services/constants/env';
import {
	setSocketsConnect,
	setSocketsDisconnect,
	socketClosedConnection,
	socketOpenConnection,
	socketSendMessage,
} from './reducer';

import { SocketsResponseData } from './types';
import { editPriceInStableCoin } from '../tokens/reducer';

export const socketConnection = (socketToken: string | null) => {
	return new Promise((resolve, reject) => {
		let socket: WebSocket;

		if (socketToken) {
			socket = new WebSocket(`${String(WEB_SOCKETS_URL)}?${socketToken}`, ['wamp']);
		} else {
			socket = new WebSocket(String(WEB_SOCKETS_URL), ['wamp']);
		}

		socket.onopen = () => {
			resolve(socket);
			// console.log('Connection open...');
		};

		socket.onerror = (event) => {
			reject(event);
		};

		socket.onclose = (event) => {
			if (event.wasClean) {
				// console.log('Connection closed...');
			} else {
				// console.log('Lost connection...');
			}
		};
	});
};

function* socketSend(socket: WebSocket) {
	const isOpenSocket = socket.readyState === socket.OPEN;
	if (isOpenSocket) {
		while (true) {
			const { payload }: { payload: PayloadAction } = yield take(socketSendMessage.type);
			socket.send(JSON.stringify(payload));
		}
	}
}

function* socketClose(socket: WebSocket) {
	while (true) {
		yield take(socketClosedConnection.type);
		yield put(setSocketsDisconnect());

		socket.close();
	}
}

export const socketChannel = (socketValue: WebSocket) => {
	const socket = socketValue;
	return eventChannel((emiter) => {
		socket.onmessage = ({ data }) => {
			emiter(JSON.parse(data));
		};
		return () => {
			socket.close();
		};
	});
};

function* socketOnmessage(channel: EventChannel<SocketsResponseData>) {
	while (true) {
		const data: SocketsResponseData = yield take(channel);
		if (+data[0] === 8) {
			switch (data[1]) {
				case 'fee_data':
					yield put(editPriceInStableCoin(data[2].data.data));
					break;
				default:
					break;
			}
		}
	}
}

export function* socketsSaga() {
	try {
		while (true) {
			const { payload }: PayloadAction<string> = yield take(socketOpenConnection.type);
			const socketToken: string = payload;

			const socket: WebSocket = yield call(socketConnection, socketToken);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const channel: EventChannel<any> = yield call(socketChannel, socket);

			if (socket.onopen) {
				yield put(setSocketsConnect());
			}

			yield fork(socketSend, socket);
			yield fork(socketClose, socket);

			const { cancel } = yield race({
				task: call(socketOnmessage, channel),
				cancel: take(socketClosedConnection.type),
			});

			if (cancel) {
				channel.close();
			}
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		// console.error('websockets_error', error);
		// eslint-disable-next-line no-console
		// console.dir('websockets_error', error);
		notificationContainer('Errors.websockets_error', 'error');
	}
}
