import FeeManagement from 'components/FeeManagement';
import { Page } from 'layouts/page';
import React from 'react';

const FeeManagementPage = () => {
	return (
		<Page>
			<FeeManagement />
		</Page>
	);
};

export default FeeManagementPage;
