import { TotalStats } from 'components/TotalStats';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const TotalStatsPage: FC = () => {
	return (
		<Page>
			<TotalStats />
		</Page>
	);
};
