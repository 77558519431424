import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ActiveLink } from 'ui/ActiveLink';
import { ReferralManagIcon, LinkStaking } from 'assets/custom-icons';

const Sidebar = () => {
	const [barHide, setBarHide] = useState(false);
	const handleClick = () => {
		setBarHide(!barHide);
	};

	return (
		<div className={`sidebar ${barHide ? 'sidebar--hidden' : ''}`}>
			<div className="sidebar-top">
				<button onClick={handleClick} type="button" className="sidebar__action">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M4 14L10 14L10 20"
							stroke="#070708"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M10 14L3 21" stroke="#070708" strokeLinecap="round" strokeLinejoin="round" />
						<path
							d="M20 10L14 10L14 4"
							stroke="#070708"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M21 3L14 10" stroke="#070708" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
				</button>
				<nav className="sidebar-nav">
					<ul className="sidebar-nav__list">
						<li>
							<ActiveLink to="/">
								<span className="sidebar-nav-icon">
									<svg
										width="13"
										height="14"
										viewBox="0 0 13 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M6.5 7C8.22589 7 9.625 5.60089 9.625 3.875C9.625 2.14911 8.22589 0.75 6.5 0.75C4.77411 0.75 3.375 2.14911 3.375 3.875C3.375 5.60089 4.77411 7 6.5 7Z"
											stroke="white"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.8688 13.25C11.8688 10.8313 9.4625 8.875 6.5 8.875C3.5375 8.875 1.13126 10.8313 1.13126 13.25"
											stroke="white"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">User Management</span>
							</ActiveLink>
						</li>
						<li>
							<ActiveLink to="/wallets">
								<span className="sidebar-nav-icon">
									<svg
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M11.275 7.96874C11.0125 8.22499 10.8625 8.59375 10.9 8.9875C10.9563 9.6625 11.575 10.1562 12.25 10.1562H13.4375V10.9C13.4375 12.1937 12.3812 13.25 11.0875 13.25H3.9125C2.61875 13.25 1.5625 12.1937 1.5625 10.9V6.69376C1.5625 5.40001 2.61875 4.34375 3.9125 4.34375H11.0875C12.3812 4.34375 13.4375 5.40001 13.4375 6.69376V7.59376H12.175C11.825 7.59376 11.5063 7.73124 11.275 7.96874Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M1.5625 7.25607V4.39985C1.5625 3.6561 2.01875 2.99357 2.7125 2.73107L7.675 0.856074C8.45 0.562324 9.28125 1.13734 9.28125 1.96859V4.34358"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M14.0993 8.23122V9.51876C14.0993 9.86251 13.8243 10.1437 13.4743 10.1562H12.2493C11.5743 10.1562 10.9555 9.66249 10.8993 8.98749C10.8618 8.59374 11.0118 8.22498 11.2743 7.96873C11.5055 7.73123 11.8243 7.59375 12.1743 7.59375H13.4743C13.8243 7.60625 14.0993 7.88747 14.0993 8.23122Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M4.375 7H8.75"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Wallets</span>
							</ActiveLink>
						</li>
						<li>
							<ActiveLink to="/staking">
								<span className="sidebar-nav-icon">
									<LinkStaking />
								</span>
								<span className="sidebar-nav-text">Staking</span>
							</ActiveLink>
						</li>
						<li>
							<ActiveLink to="/dividends">
								<span className="sidebar-nav-icon">
									<LinkStaking />
								</span>
								<span className="sidebar-nav-text">Dividends</span>
							</ActiveLink>
						</li>
						<li>
							<ActiveLink to="/exchange">
								<span className="sidebar-nav-icon">
									<svg
										width="13"
										height="12"
										viewBox="0 0 13 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M11.8125 7.86865L8.68124 11.0062"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M1.1875 7.86865H11.8125"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M1.1875 4.13115L4.31875 0.993652"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.8125 4.13135H1.1875"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Exchange/Swap</span>
							</ActiveLink>
						</li>
						<li>
							<ActiveLink to="/lp-tokens">
								<span className="sidebar-nav-icon">
									<svg
										width="11"
										height="14"
										viewBox="0 0 11 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9.5625 7.40625V9.71875C9.5625 11.6687 7.74375 13.25 5.5 13.25C3.25625 13.25 1.4375 11.6687 1.4375 9.71875V7.40625C1.4375 9.35625 3.25625 10.75 5.5 10.75C7.74375 10.75 9.5625 9.35625 9.5625 7.40625Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M9.5625 4.28125C9.5625 4.85 9.40625 5.375 9.13125 5.825C8.4625 6.925 7.0875 7.625 5.5 7.625C3.9125 7.625 2.5375 6.925 1.86875 5.825C1.59375 5.375 1.4375 4.85 1.4375 4.28125C1.4375 3.30625 1.89375 2.425 2.625 1.7875C3.3625 1.14375 4.375 0.75 5.5 0.75C6.625 0.75 7.6375 1.14375 8.375 1.78125C9.10625 2.425 9.5625 3.30625 9.5625 4.28125Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M9.5625 4.28125V7.40625C9.5625 9.35625 7.74375 10.75 5.5 10.75C3.25625 10.75 1.4375 9.35625 1.4375 7.40625V4.28125C1.4375 2.33125 3.25625 0.75 5.5 0.75C6.625 0.75 7.6375 1.14375 8.375 1.78125C9.10625 2.425 9.5625 3.30625 9.5625 4.28125Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">LP Token</span>
							</ActiveLink>
						</li>
						<li>
							<ActiveLink to="/tokens">
								<span className="sidebar-nav-icon">
									<svg
										width="16"
										height="15"
										viewBox="0 0 16 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M7.4375 12.4999C9.68125 12.4999 11.8125 11.1548 11.8125 9.36735V7.24756C11.8125 9.03506 9.99375 10.3127 7.75 10.3127"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.8125 4.38249C11.8125 4.90384 11.6562 5.38509 11.3812 5.79759C10.7125 6.80592 9.3375 7.44759 7.75 7.44759C6.1625 7.44759 4.7875 6.80592 4.11875 5.79759C3.84375 5.38509 3.6875 4.90384 3.6875 4.38249C3.6875 3.48874 4.14375 2.68092 4.875 2.09655C5.6125 1.50645 6.625 1.14551 7.75 1.14551C8.875 1.14551 9.8875 1.50645 10.625 2.09082C11.3563 2.68093 11.8125 3.48874 11.8125 4.38249Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M7.75 10.3122C9.99375 10.3122 11.8125 9.03457 11.8125 7.24707V4.38249C11.8125 3.48874 11.3563 2.68093 10.625 2.09082C9.8875 1.50645 8.875 1.14551 7.75 1.14551C5.50625 1.14551 3.6875 2.59499 3.6875 4.38249V7.49988"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M6.5 11.25C6.5 11.7188 6.36876 12.1625 6.13751 12.5375C5.70626 13.2625 4.9125 13.75 4 13.75C3.0875 13.75 2.29374 13.2625 1.86249 12.5375C1.63124 12.1625 1.5 11.7188 1.5 11.25C1.5 9.86875 2.61875 8.75 4 8.75C5.38125 8.75 6.5 9.86875 6.5 11.25Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Tokens made on Gatochain</span>
							</ActiveLink>
						</li>
						<li>
							<ActiveLink to="/pools">
								<span className="sidebar-nav-icon">
									<svg
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M5.625 13.25H9.375C12.5 13.25 13.75 12 13.75 8.875V5.125C13.75 2 12.5 0.75 9.375 0.75H5.625C2.5 0.75 1.25 2 1.25 5.125V8.875C1.25 12 2.5 13.25 5.625 13.25Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M4.58125 8.55635L6.06875 6.6251C6.28125 6.3501 6.675 6.3001 6.95 6.5126L8.09375 7.4126C8.36875 7.6251 8.7625 7.5751 8.975 7.30635L10.4188 5.44385"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Pool Liquidity</span>
							</ActiveLink>
						</li>
						<li>
							<a href="#!">
								<span className="sidebar-nav-icon">
									<svg
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M7.5 13.25C10.9518 13.25 13.75 10.4518 13.75 7C13.75 3.54822 10.9518 0.75 7.5 0.75C4.04822 0.75 1.25 3.54822 1.25 7C1.25 10.4518 4.04822 13.25 7.5 13.25Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M10.625 9.8125H8.68752L9.70001 8.69373C10.2 8.13748 10.475 7.43127 10.475 6.70627C10.475 5.95627 10.1625 5.23751 9.60625 4.70626C9.05 4.17501 8.29375 3.875 7.5 3.875C6.7125 3.875 5.95625 4.17501 5.39375 4.70626C4.8375 5.23751 4.52499 5.95627 4.52499 6.70627C4.52499 7.43752 4.79999 8.14373 5.29999 8.69373L6.31248 9.8125H4.375"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Bridges Management</span>
							</a>
						</li>
						<li>
							<ActiveLink to="/fee-management">
								<span className="sidebar-nav-icon">
									<svg
										width="13"
										height="14"
										viewBox="0 0 13 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0.875 13.25H12.125"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M2.49998 4.7373H1.5C1.15625 4.7373 0.875 5.01855 0.875 5.3623V10.7498C0.875 11.0936 1.15625 11.3748 1.5 11.3748H2.49998C2.84373 11.3748 3.12498 11.0936 3.12498 10.7498V5.3623C3.12498 5.01855 2.84373 4.7373 2.49998 4.7373Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M6.99998 2.74365H6C5.65625 2.74365 5.375 3.0249 5.375 3.36865V10.7499C5.375 11.0937 5.65625 11.3749 6 11.3749H6.99998C7.34373 11.3749 7.62498 11.0937 7.62498 10.7499V3.36865C7.62498 3.0249 7.34373 2.74365 6.99998 2.74365Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.5 0.75H10.5C10.1563 0.75 9.87502 1.03125 9.87502 1.375V10.75C9.87502 11.0938 10.1563 11.375 10.5 11.375H11.5C11.8438 11.375 12.125 11.0938 12.125 10.75V1.375C12.125 1.03125 11.8438 0.75 11.5 0.75Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Fees in Native Coin</span>
							</ActiveLink>
						</li>
						<li>
							<NavLink to="/administrative-roles">
								<span className="sidebar-nav-icon">
									<svg
										width="13"
										height="14"
										viewBox="0 0 13 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10.875 13.25V6.375"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M10.875 3.875V0.75"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M6.5 13.25V10.125"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M6.5 7.625V0.75"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M2.125 13.25V6.375"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M2.125 3.875V0.75"
											stroke="#1A203F"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M0.875 6.375H3.375"
											stroke="#1A203F"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M9.625 6.375H12.125"
											stroke="#1A203F"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M5.25 7.625H7.75"
											stroke="#1A203F"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Administrative roles</span>
							</NavLink>
						</li>
						<li>
							<ActiveLink to="/referral-management">
								<span className="sidebar-nav-icon">
									<ReferralManagIcon />
								</span>
								<span className="sidebar-nav-text">Referral Management</span>
							</ActiveLink>
						</li>
						<li>
							<a href="#!">
								<span className="sidebar-nav-icon">
									<svg
										width="15"
										height="15"
										viewBox="0 0 15 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M2.49306 9.16253L1.54307 8.21252C1.15557 7.82502 1.15557 7.18751 1.54307 6.80001L2.49306 5.85C2.65556 5.6875 2.78681 5.36874 2.78681 5.14374V3.79998C2.78681 3.24998 3.23681 2.79999 3.78681 2.79999H5.13056C5.35556 2.79999 5.67431 2.66876 5.83681 2.50626L6.78681 1.55625C7.17431 1.16875 7.81181 1.16875 8.19931 1.55625L9.14932 2.50626C9.31182 2.66876 9.63056 2.79999 9.85556 2.79999H11.1993C11.7493 2.79999 12.1993 3.24998 12.1993 3.79998V5.14374C12.1993 5.36874 12.3306 5.6875 12.4931 5.85L13.4431 6.80001C13.8306 7.18751 13.8306 7.82502 13.4431 8.21252L12.4931 9.16253C12.3306 9.32503 12.1993 9.64378 12.1993 9.86878V11.2125C12.1993 11.7625 11.7493 12.2125 11.1993 12.2125H9.85556C9.63056 12.2125 9.31182 12.3438 9.14932 12.5063L8.19931 13.4563C7.81181 13.8438 7.17431 13.8438 6.78681 13.4563L5.83681 12.5063C5.67431 12.3438 5.35556 12.2125 5.13056 12.2125H3.78681C3.23681 12.2125 2.78681 11.7625 2.78681 11.2125V9.86878C2.78681 9.63753 2.65556 9.31878 2.49306 9.16253Z"
											stroke="#161522"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M5.625 9.375L9.375 5.625"
											stroke="#161522"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M9.05907 9.0625H9.06468"
											stroke="#161522"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M5.93407 5.9375H5.93968"
											stroke="#161522"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Infinity referral system</span>
							</a>
						</li>
						<li>
							<ActiveLink to="/total-stats">
								<span className="sidebar-nav-icon">
									<svg
										className="fill"
										width="15"
										height="16"
										viewBox="0 0 15 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10.9314 2.7762L11.2608 2.27472L11.2608 2.27472L10.9314 2.7762ZM12.0561 12.2784L11.6453 12.7158C11.8869 12.9426 12.2666 12.9307 12.4934 12.6891L12.0561 12.2784ZM7.5 8H6.9C6.9 8.16567 6.9685 8.32397 7.08927 8.43738L7.5 8ZM13.15 8C13.15 11.1204 10.6204 13.65 7.5 13.65V14.85C11.2832 14.85 14.35 11.7832 14.35 8H13.15ZM7.5 13.65C4.37959 13.65 1.85 11.1204 1.85 8H0.65C0.65 11.7832 3.71685 14.85 7.5 14.85V13.65ZM1.85 8C1.85 4.87959 4.37959 2.35 7.5 2.35V1.15C3.71685 1.15 0.65 4.21685 0.65 8H1.85ZM7.5 2.35C10.6204 2.35 13.15 4.87959 13.15 8H14.35C14.35 4.21685 11.2832 1.15 7.5 1.15V2.35ZM7.5 2.35C8.60234 2.35 9.68062 2.67247 10.602 3.27769L11.2608 2.27472C10.1438 1.54096 8.83647 1.15 7.5 1.15V2.35ZM10.602 3.27769C11.5233 3.88291 12.2475 4.74442 12.6853 5.7561L13.7866 5.27953C13.2558 4.05296 12.3778 3.00848 11.2608 2.27472L10.602 3.27769ZM12.6853 5.7561C13.1231 6.76779 13.2554 7.88548 13.0659 8.9714L14.248 9.17772C14.4778 7.86115 14.3174 6.50608 13.7866 5.27953L12.6853 5.7561ZM13.0659 8.9714C12.8763 10.0573 12.3733 11.0641 11.6187 11.8677L12.4934 12.6891C13.4083 11.7149 14.0182 10.4943 14.248 9.17772L13.0659 8.9714ZM12.4668 11.841L7.91073 7.56262L7.08927 8.43738L11.6453 12.7158L12.4668 11.841ZM8.1 8V1.75H6.9V8H8.1Z"
											fill="white"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Total stats</span>
							</ActiveLink>
						</li>
						<li>
							<a href="#!">
								<span className="sidebar-nav-icon">
									<svg
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10.4375 11.3626H4.5625C4.3 11.3626 4.00625 11.1563 3.91875 10.9063L1.33125 3.66885C0.962502 2.63135 1.39375 2.3126 2.28125 2.9501L4.71875 4.69385C5.125 4.9751 5.5875 4.83135 5.7625 4.3751L6.8625 1.44385C7.2125 0.506348 7.79375 0.506348 8.14375 1.44385L9.24375 4.3751C9.41875 4.83135 9.88125 4.9751 10.2813 4.69385L12.5688 3.0626C13.5438 2.3626 14.0125 2.71885 13.6125 3.8501L11.0875 10.9188C10.9938 11.1563 10.7 11.3626 10.4375 11.3626Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M4.0625 13.25H10.9375"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M5.9375 8.25H9.0625"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Rewards</span>
							</a>
						</li>
						<li>
							<ActiveLink to="/token-create">
								<span className="sidebar-nav-icon">
									<svg
										width="16"
										height="15"
										viewBox="0 0 16 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M7.4375 12.4999C9.68125 12.4999 11.8125 11.1548 11.8125 9.36735V7.24756C11.8125 9.03506 9.99375 10.3127 7.75 10.3127"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.8125 4.38249C11.8125 4.90384 11.6562 5.38509 11.3812 5.79759C10.7125 6.80592 9.3375 7.44759 7.75 7.44759C6.1625 7.44759 4.7875 6.80592 4.11875 5.79759C3.84375 5.38509 3.6875 4.90384 3.6875 4.38249C3.6875 3.48874 4.14375 2.68092 4.875 2.09655C5.6125 1.50645 6.625 1.14551 7.75 1.14551C8.875 1.14551 9.8875 1.50645 10.625 2.09082C11.3563 2.68093 11.8125 3.48874 11.8125 4.38249Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M7.75 10.3122C9.99375 10.3122 11.8125 9.03457 11.8125 7.24707V4.38249C11.8125 3.48874 11.3563 2.68093 10.625 2.09082C9.8875 1.50645 8.875 1.14551 7.75 1.14551C5.50625 1.14551 3.6875 2.59499 3.6875 4.38249V7.49988"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M6.5 11.25C6.5 11.7188 6.36876 12.1625 6.13751 12.5375C5.70626 13.2625 4.9125 13.75 4 13.75C3.0875 13.75 2.29374 13.2625 1.86249 12.5375C1.63124 12.1625 1.5 11.7188 1.5 11.25C1.5 9.86875 2.61875 8.75 4 8.75C5.38125 8.75 6.5 9.86875 6.5 11.25Z"
											stroke="#1A203F"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Create token</span>
							</ActiveLink>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default Sidebar;
