import { CopySvg } from 'assets/custom-icons';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ITokenLp } from 'redux/reducers/tokensLp/types';
import { notificationContainer } from 'services/utils/notificationContainer';

interface ILpTokensTableProps {
	list: ITokenLp[] | undefined;
}

const LpTokensTable: FC<ILpTokensTableProps> = ({ list }) => {
	const moneyFormat = useMoneyFormat();

	const handleCopy = (text: string) => {
		notificationContainer(`${text} copied successfully!`, 'info');
	};
	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--lp-token">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>Pairs</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Address</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Issued</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Burned</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>In users wallet</p>
								</div>
							</div>
						</div>
					</div>
					<div className="table-body">
						{list &&
							list.map((el) => (
								<div key={el.id} className="tr">
									<div className="td">
										<p className="td-hidden-name">Pairs</p>
										<div className="token-line">
											<img className="token-line__icon" src={el.asset_base.logo} alt="bnb" />
											<span className="token-line__text">{el.asset_base.symbol}</span>
											<span className="token-line__text">/</span>
											<img className="token-line__icon" src={el.asset_quote.logo} alt="eth" />
											<span className="token-line__text">{el.asset_quote.symbol}</span>
										</div>
									</div>
									<div className="td">
										<p className="td-hidden-name">Address</p>
										<div className="td-two-items">
											<div className="td-wallet-address">
												<p>{el.pair_id}</p>
											</div>
											<CopyToClipboard
												text={String(el.pair_id)}
												onCopy={() => handleCopy('Address')}
											>
												<button className="copy-btn" type="button">
													<CopySvg />
												</button>
											</CopyToClipboard>
										</div>
									</div>
									<div className="td">
										<p className="td-hidden-name">Issued</p>
										<p>{moneyFormat(el.issued, 2, 9)}</p>
									</div>
									<div className="td">
										<p className="td-hidden-name">Burned</p>
										<p>{moneyFormat(el.burned, 2, 9)}</p>
									</div>
									<div className="td">
										<p className="td-hidden-name">In users wallet</p>
										<p>{moneyFormat(el.lp_tokens_in_wallets, 2, 9)}</p>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default LpTokensTable;
