import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TLink, TActive } from './type';

// ==========================================:
export const ActiveLink: FC<TLink> = ({ children, to, classLink = '' }) => {
	const { pathname } = useLocation();
	const setActive = ({ isActive }: TActive): string => {
		return isActive && new RegExp(`^${to}((\\/|\\?).*)?$`).test(pathname)
			? `${String(classLink)} active`
			: `${String(classLink)}`;
	};
	return (
		<NavLink to={to} className={setActive}>
			{children}
		</NavLink>
	);
};
