import { FC } from 'react';
import ReactPaginate from 'react-paginate';
import usePagination from 'hooks/usePagination';
import { IHandlePage, IPaginationProps } from './types';

const Pagination: FC<IPaginationProps> = ({ pageCount, refElement }) => {
	const { currentPage, onPageChange } = usePagination();
	const handlePage = (props: IHandlePage) => {
		const { selected } = props;
		if (refElement?.current) refElement.current.scrollIntoView();
		return onPageChange(Number(selected) + 1);
	};

	return (
		<div className="pagination-block">
			<ReactPaginate
				pageCount={pageCount}
				pageRangeDisplayed={2}
				marginPagesDisplayed={1}
				containerClassName="pagination"
				nextLabel={
					<span className="arrow ">
						<svg
							width="8"
							height="12"
							viewBox="0 0 8 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M1 11.2L6.6 6.00005L1 0.800049" stroke="#777E90" strokeLinecap="square" />
						</svg>
					</span>
				}
				previousLabel={
					<span className="arrow disabled">
						<svg
							width="8"
							height="12"
							viewBox="0 0 8 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.00002 11.2L1.40002 6.00005L7.00002 0.800049"
								stroke="#777E90"
								strokeLinecap="square"
							/>
						</svg>
					</span>
				}
				activeClassName="active"
				forcePage={currentPage - 1}
				onPageChange={handlePage}
			/>
		</div>
	);
};

export default Pagination;

// <li>
// 	<NavLink to="#!" className="arrow disabled">
// 		<svg
// 			width="8"
// 			height="12"
// 			viewBox="0 0 8 12"
// 			fill="none"
// 			xmlns="http://www.w3.org/2000/svg"
// 		>
// 			<path
// 				d="M7.00002 11.2L1.40002 6.00005L7.00002 0.800049"
// 				stroke="#777E90"
// 				strokeLinecap="square"
// 			/>
// 		</svg>
// 	</NavLink>
// </li>
// <li>
// 	<NavLink to="#!" className="active">
// 		{' '}
// 		1{' '}
// 	</NavLink>
// </li>
// <li>
// 	<NavLink to="#!"> 2 </NavLink>
// </li>
// <li>
// 	<NavLink to="#!"> 3 </NavLink>
// </li>
// <li>
// 	<NavLink to="#!"> 4 </NavLink>
// </li>
// <li>
// 	<NavLink to="#!"> 5 </NavLink>
// </li>
// <li>
// 	<NavLink to="#!" className="arrow ">
// 		<svg
// 			width="8"
// 			height="12"
// 			viewBox="0 0 8 12"
// 			fill="none"
// 			xmlns="http://www.w3.org/2000/svg"
// 		>
// 			<path d="M1 11.2L6.6 6.00005L1 0.800049" stroke="#777E90" strokeLinecap="square" />
// 		</svg>
// 	</NavLink>
// </li>
