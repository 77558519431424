import { IValues } from 'components/Forms/EditFeeForm/types';
import { useFormikContext } from 'formik';
import { FC, useEffect, useState, ChangeEvent } from 'react';
import { IReferralUser } from 'redux/reducers/referral/types';
import { textSearchValid } from 'services/utils/textSearch';
import { ISearchInputProps } from './types';

export const CSearchInput: FC<ISearchInputProps> = ({ listData, onSelect, dataItem }) => {
	const [dropdown, setDropdown] = useState(false);
	const [search, setSearch] = useState('');
	// const { values, setValues } = useFormikContext<IValues>();
	const changeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length < 15) {
			setSearch(e.target.value);
		}
	};
	useEffect(() => {
		const coincidences = listData?.find((item: any) => item?.name === dataItem);
		if (!dropdown && dataItem && coincidences) {
			// const { name } = coincidences;
			// setSearch(name);
			// setErrors(false);
		}
	}, [dataItem, dropdown, listData]);

	const handleFocus = () => {
		setDropdown(true);
		setSearch('');
		onSelect(null);
	};

	const handleBlur = () => {
		setTimeout(() => {
			setDropdown(false);
			setSearch('');
		}, 300);
	};

	const handleOnSelect = (item: IReferralUser) => {
		// setValues({
		// 	...values,
		// 	asset_id: item?.id,
		// });
		onSelect(item);
		setDropdown(false);
	};
	/* eslint-disable */
	const filterData = (searchCoin: string, data: IReferralUser[] | undefined) => {
		
		return data?.filter((item: IReferralUser) => textSearchValid(String(item?.id), searchCoin));
		//return data?.filter((item: IReferralUser) => item);
	};

	const data = filterData(search, listData);

	return (
		<div className={`select ${dropdown ? 'active' : ''}`}>
			<div className="select__current current--position">
				{dataItem && (
					<div className="token-line">
						<span className="token-line__text">{dataItem.id} </span>
						<span className="token-line__text">{dataItem.email}</span>
					</div>
				)}
				<input
					className="input-item"
					placeholder={dataItem ? '' : 'Select User'}
					value={search}
					onChange={changeSearch}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
				<span className="select__current-arrow arrow--position">
					<svg
						width="15"
						height="10"
						viewBox="0 0 15 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M13.75 1.5L7.25 8.5L0.75 1.5" stroke="black" strokeLinecap="square" />
					</svg>
				</span>
			</div>

			{dropdown && (
				<div className="select__drop">
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{data?.length ? (
									data?.map((item: IReferralUser) => (
										<li key={item.id}>
											<button
												type="button"
												data-code={item?.email}
												onClick={() => handleOnSelect(item)}
											>
												<div className="token-line">
												<span className="token-line__text">{item.id} </span>
													<span className="token-line__text">{item.email}</span>
												</div>
											</button>
										</li>
									))
								) : (
									<li>
										<div className="token-line__text text--center">No Result</div>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
