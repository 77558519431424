import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IExchangeApi } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const exchange: IExchangeApi = {
	getPair: (params: any) => {
		return http.get<any>(endpoint.exchange.GET_PAIRS, params).then(({ data }) => data);
	},
	getTokens: (params: any) => {
		return http.get<any>(endpoint.exchange.GET_TOKENS, params).then(({ data }) => data);
	},
	putToken: (payload) => {
		return http.put<any>(endpoint.exchange.PUT_TOKEN, payload).then(({ data }) => data);
	},
	getHistoryTransaction: (params) => {
		return http.get(endpoint.exchange.GET_TRANSACTION_HISTORY, params).then(({ data }) => data);
	},
	getVolue24: () => {
		return http.get<any>(endpoint.exchange.GET_VOLUME_24H).then(({ data }) => data);
	},
};
