import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IAsset } from 'redux/reducers/tokens/types';

const TokensTableCell: FC<IAsset> = ({ id, logo, symbol, name, token_fee }) => {
	const getFeeType = (obj: any) => {
		if (!obj) return '';
		const percent = Object.values(obj).includes('percent');
		const flat = Object.values(obj).includes('flat');
		if (percent && flat) {
			return 'Percentage / Flat';
		}
		return percent ? 'Percentage' : 'Flat';
	};

	return (
		<>
			<div className="td-group">
				<div className="td">
					<p className="td-hidden-name">Token</p>
					<Link to={`/tokens/${id}`} className="token-line">
						<img className="token-line__icon" src={logo} alt="bnb" />
						<span className="token-line__text">{symbol}</span>
						<span className="token-line__transcription">{name}</span>
					</Link>
				</div>
				<div className="td ">
					<p className="td-hidden-name">Percentage / Flat</p>
					<Link to={`/tokens/fee/${id}`} className="capitalize token-line">
						{getFeeType(token_fee)}
					</Link>
				</div>
			</div>
		</>
	);
};

export default TokensTableCell;
