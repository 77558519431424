import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ITokensApi } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const tokens: ITokensApi = {
	createToken: (payload) =>
		http.post<any>(endpoint.tokens.CREATE_TOKEN, payload).then((response) => response.data),
	getAssets: (params) =>
		http.get<any>(endpoint.tokens.GET_ASSETS, { params }).then((response) => response.data),
	getAssetsCustom: (params) =>
		http.get<any>(endpoint.tokens.GET_ASSETS_CUSTOM, { params }).then((response) => response.data),
	toggleActive: (id: number) =>
		http.put(endpoint.tokens.TOGGLE_ACTIVE(id)).then((response) => response.data),
	getAssetItem: (id: number) =>
		http.get<any>(endpoint.tokens.GET_ASSET_ITEM(id)).then((response) => response.data),
	getBlockchainAssetItem: (address: string) =>
		http
			.get<any>(endpoint.tokens.GET_BLOCKCHAIN_ASSET_ITEM(address))
			.then((response) => response.data),
	mintToken: (payload) =>
		http.put<any>(endpoint.tokens.MINT_TOKEN, payload).then((response) => response.data),
	editFee: (payload) =>
		http.put<any>(endpoint.tokens.EDIT_FEE, payload).then((response) => response.data),
	editFeeCoin: (payload) =>
		http.put<any>(endpoint.tokens.EDIT_FEE_COIN, payload).then((response) => response.data),
	approve: (id: number) =>
		http.post<any>(endpoint.tokens.APPROVE(id)).then((response) => response.data),
	reject: (id: number) =>
		http.post<any>(endpoint.tokens.REJECT(id)).then((response) => response.data),
	editImage: (payload) =>
		http.post<any>(endpoint.tokens.EDIT_IMAGE, payload).then((response) => response.data),
	calculateFee: (params) =>
		http.post(endpoint.tokens.CALCULATE_FEE, params).then(({ data }) => data),
	editToken: (params) => http.post(endpoint.tokens.UPDATE_TOKEN, params).then(({ data }) => data),
	getDetailsAssetItem: ({ params }) =>
		http
			.get<any>(endpoint.tokens.GET_DETAILS_ASSET(params.asset), {
				params: { date_from: params.date_from, date_to: params.date_to },
			})
			.then((response) => response.data),
	getDetailsTotalAsset: ({ asset }) =>
		http.get<any>(endpoint.tokens.GET_TOTAL_DETAILS_ASSET(asset)).then((response) => response.data),
	getDetailsTokenTransactions: (params) =>
		http
			.get<any>(endpoint.tokens.GET_DETAILS_TRANSACTIONS, { params })
			.then((response) => response.data),
};
