import { FC } from 'react';
import { Link } from 'react-router-dom';
import { notificationContainer } from 'services/utils/notificationContainer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TStakingTramsactionItemUser } from 'redux/reducers/staking/types';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { format } from 'date-fns';
import { WEB_URL } from 'services/constants/env';
import { TParams } from './type';

export const Item: FC<TParams> = ({ el }) => {
	const { wallet, balance, reward, created_at, averagePoolAmount, averagePercent, transactions } =
		el;
	const moneyFormat = useMoneyFormat();

	const operationType = (value: string) => {
		if (value === 'add_staking') {
			return 'Staking';
		}
		if (value === 'claim_staking') {
			return 'Claim Staking';
		}
		return 'Add liquidity';
	};

	const handleStatus = (value: string) => {
		if (value === 'completed') {
			return <p className="status-tag status-tag--success">Successed</p>;
		}
		if (value === 'pending') {
			return <p className="status-tag status-tag--pending">Pending</p>;
		}
		return <p className="status-tag status-tag--error">Faild</p>;
	};

	const handleCopy = () => {
		notificationContainer('Copied successfully!', 'info');
	};
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">User ID.</p>
				<p>
					{wallet.user.id && (
						<Link to={`/user-details/${String(wallet.user.id)}`} className="link link--type2 ">
							{wallet.user.id}
						</Link>
					)}
				</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Operation type</p>
				{/* <p>{operationType(type)}</p> */}
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p>{created_at ? format(Date.parse(created_at), 'MM/dd/yyy hh:mm') : '--/--'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Operation amount</p>
				<p>
					{moneyFormat(balance, 2, 6)}
					{/* {gas_asset?.symbol} */}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Reward from Staking</p>
				<p>
					{moneyFormat(reward, 2, 6)}
					{/* {gas_asset?.symbol} */}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total comision</p>
				<p>
					{moneyFormat(averagePoolAmount, 2, 18)}
					{/* {gas_asset?.symbol} */}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total commission distributed</p>
				<p>{averagePercent}</p>
			</div>
			{/* <div className="td">
				<p className="td-hidden-name">Profit Gato Chain</p>
				<p>
					{moneyFormat(profit_gato_chain, 2, 18)} {gas_asset?.symbol}
					</p>
			</div> */}
			<div className="td">
				<p className="td-hidden-name">Transaction hash</p>
				{transactions && transactions?.length ? (
					<div className="address-line address-line--td-default">
						{/* <a
						href={`${WEB_URL || ''}/transactions/${String(transactions[0].tx)}`}
						target="_blank"
						className="address-line__value"
						rel="noreferrer"
					> */}
						{transactions[0].tx.slice(0, 12)}...
						{/* </a> */}
						<CopyToClipboard text={String(transactions[0].tx)} onCopy={handleCopy}>
							<button className="copy-btn" type="button">
								<svg
									width="17"
									height="16"
									viewBox="0 0 17 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.9168 8.6V11.4C10.9168 13.7333 9.9835 14.6667 7.65016 14.6667H4.85016C2.51683 14.6667 1.5835 13.7333 1.5835 11.4V8.6C1.5835 6.26667 2.51683 5.33333 4.85016 5.33333H7.65016C9.9835 5.33333 10.9168 6.26667 10.9168 8.6Z"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M14.9168 4.6V7.4C14.9168 9.73333 13.9835 10.6667 11.6502 10.6667H10.9168V8.6C10.9168 6.26667 9.9835 5.33333 7.65016 5.33333H5.5835V4.6C5.5835 2.26667 6.51683 1.33333 8.85016 1.33333H11.6502C13.9835 1.33333 14.9168 2.26667 14.9168 4.6Z"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</button>
						</CopyToClipboard>
					</div>
				) : (
					'---'
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Transaction staus</p>
				{(transactions && transactions?.length && handleStatus(transactions[0].status)) || 0}
			</div>
		</div>
	);
};
