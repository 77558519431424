import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api, responseErrors } from 'services';
import { authInitState, google2faEnabled } from 'redux/reducers/auth/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	changeUserPassRequest,
	changeUserPassSuccess,
	disable2faRequest,
	disable2faSuccess,
	enable2faRequest,
	enable2faSuccess,
	generate2faKeyRequest,
	generate2faKeySuccess,
	settingsInitState,
} from './reducer';
import {
	IChangePassPayload,
	IDisable2faPayload,
	IEnable2faPayload,
	IGenerate2faKeyResponse,
} from './types';

// =============================================================:
function* changeUserPassWorker(action: PayloadAction<IChangePassPayload>) {
	const { payload } = action;

	try {
		yield call(api.settings.changePassword, payload);
		yield put(changeUserPassSuccess());
		yield put(authInitState());

		notificationContainer('Your password has been successfully changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(settingsInitState());
		}
	}
}

// =============================================================:
function* generate2faKeyWorker() {
	try {
		const response: IGenerate2faKeyResponse = yield call(api.settings.generate2faKey);
		yield put(generate2faKeySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(settingsInitState());
		}
	}
}

// =============================================================:
function* disable2faWorker(action: PayloadAction<IDisable2faPayload>) {
	const { payload } = action;

	try {
		yield call(api.settings.disable2fa, payload);
		yield put(disable2faSuccess());
		yield put(google2faEnabled(false));
		notificationContainer('Google 2fa successfully turned off!', 'success');
	} catch (error) {
		// if (axios.isAxiosError(error)) {
		// 	responseErrors(error);
		// }
	}
}

// =============================================================:
function* enable2faWorker(action: PayloadAction<IEnable2faPayload>) {
	const { payload } = action;

	try {
		yield call(api.settings.enable2fa, payload);
		yield put(enable2faSuccess());
		yield put(google2faEnabled(true));
		notificationContainer('Google 2fa successfully activated!', 'success');
	} catch (error) {
		// if (axios.isAxiosError(error)) {
		// 	responseErrors(error);
		// }
	}
}

// =============================================================:
export function* settingsSaga() {
	yield takeEvery(changeUserPassRequest.type, changeUserPassWorker);
	yield takeEvery(generate2faKeyRequest.type, generate2faKeyWorker);
	yield takeEvery(enable2faRequest.type, enable2faWorker);
	yield takeEvery(disable2faRequest.type, disable2faWorker);
}
