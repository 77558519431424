import { FC, useEffect, useState, MouseEvent } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import Pagination from 'ui/Pagination';
import { SelectDropdownPeriod } from 'ui/SelectDropdownPeriod';
import { WEB_URL } from 'services/constants/env';
import { Liquidity } from './Liquidity';
import { TransactionHistory } from './TransactionHistory';

export const Pools: FC = () => {
	// const totalPages = walletsList?.last_page || 1;
	const [searchValue, setSearchValue] = useState('');
	const [currentTab, setCurrentTab] = useState<string>('liquidity');
	const [searchField, setSearchField] = useState<any>('7');
	const [totalPages, setTotalPages] = useState(1);
	const debouncedSearch = useDebounce(searchValue, 500);

	const FilterField = ['7', '3', '1'];

	useEffect(() => {
		setSearchValue('');
	}, [currentTab]);

	const tabs = {
		liquidity: <Liquidity search={debouncedSearch} setTotalPages={setTotalPages} />,
		transaction: (
			<TransactionHistory
				search={debouncedSearch}
				period={searchField}
				setTotalPages={setTotalPages}
			/>
		),
	};
	const handleTab = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setCurrentTab(name);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	return (
		<div className="main-content">
			<div className="panel-block">
				<div className="title-block">
					<p className="title">Pool Liquidity</p>
				</div>
				<div className="filter-group">
					{currentTab === 'transaction' && (
						<div className="country-filter">
							<SelectDropdownPeriod
								options={FilterField}
								onChange={setSearchField}
								value={searchField}
							/>
						</div>
					)}
					<div className="panel-search">
						<div className="input-wrapper">
							<input
								className="input-item input-item--left-icon input-item--type2"
								type="text"
								placeholder="Search"
								value={searchValue}
								onChange={handleChange}
							/>
							<span className="input-icon input-icon--left input-icon--color2">
								<svg
									width="17"
									height="17"
									viewBox="0 0 17 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z"
										stroke="#8E8EA9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M16.5 16.5L15 15"
										stroke="#8E8EA9"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
						</div>
					</div>
					<a
						href={`${WEB_URL || ''}/liquidity`}
						className="button button--fw-500 button--width-auto2"
					>
						Add liquidity
						<span className="button__icon">
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M4.5 9H13.5"
									stroke="white"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M9 13.5V4.5"
									stroke="white"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</a>
				</div>
			</div>
			<div className="tabs">
				<button
					type="button"
					onClick={handleTab}
					name="liquidity"
					className={`tabs__item ${currentTab === 'liquidity' ? 'active' : ''}`}
				>
					General
				</button>
				<button
					type="button"
					onClick={handleTab}
					name="transaction"
					className={`tabs__item ${currentTab === 'transaction' ? 'active' : ''}`}
				>
					Transaction history
				</button>
			</div>

			<div className="table-block table-block--border-tabs">
				<div className="table-wrapper">{tabs[currentTab as keyof typeof tabs]}</div>
			</div>
			{totalPages > 1 && <Pagination pageCount={totalPages} />}
		</div>
	);
};
