import { FC } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.svg';

// ==========================================:
export const AppLogo: FC = () => {
	return (
		<div className="header-logo">
			<div className="header-logo__icon">
				<img src={logo} alt="" />
			</div>
			<p className="header-logo__text">Gato Chain</p>
		</div>
	);
};
