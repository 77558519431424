import React, { FC, useRef, useState } from 'react';
import { ArrowSelect } from 'assets/custom-icons';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';

export interface ISelectProps {
	values: any;
	onChange: (selectedValue: any) => void;
	value: any;
}

const SelectDropdown: FC<ISelectProps> = ({ values, onChange, value }) => {
	const [drop, setDrop] = useState(false);
	const options: string[] = Object.keys(values);
	const labels: string[] = Object.values(values);
	const handleClickDrop = () => {
		setDrop(!drop);
	};

	const handleChange = (el: string) => {
		onChange(el);
		setDrop(false);
	};

	const ref = useRef<HTMLDivElement | null>(null);
	useOutsideClick(ref, () => setDrop(false));

	return (
		<div className={classNames('select select--type2', { active: drop })} ref={ref}>
			<button type="button" onClick={handleClickDrop} className="select__current">
				{values[value]}
				<span className="select__current-arrow">
					<ArrowSelect />
				</span>
			</button>
			<div className="select__drop">
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<ul>
							{options.map((opt, idx) => (
								<li key={opt}>
									<button type="button" onClick={() => handleChange(opt)}>
										<span className="select__drop-link">{labels[idx]}</span>
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SelectDropdown;
