import { Wallets } from 'components/Wallets';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const WalletPage: FC = () => {
	return (
		<Page>
			<Wallets />
		</Page>
	);
};
