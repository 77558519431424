/* eslint-disable react-hooks/exhaustive-deps */
import { SearchIcon } from 'assets/custom-icons';
import { SortArrowButton } from 'ui/SortArrowTable';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { WEB_API_URL } from 'services/constants/env';
import { useDispatch, useSelector } from 'react-redux';
import { usersRequest, generateSecretKeyRequest } from 'redux/reducers/users/reducer';
import { getUsers, getSecretToken } from 'redux/reducers/users/selectors';
import { IUser } from 'redux/reducers/users/types';
import { tryOnEmpty } from 'services/utils/custom';
import Pagination from 'ui/Pagination/Pagination';
import SelectDropdown from 'ui/SelectDropdown';
import { useLocation } from 'react-router';
import UserManagementRow from './UserManagementRow';

export enum EFilterField {
	email = 'Email',
	country = 'Country',
	phone = 'Phone',
	id = 'ID',
}

const UserManagement = () => {
	const dispatch = useDispatch();
	const usersList = useSelector(getUsers);
	const secretToken = useSelector(getSecretToken);
	const location = useLocation();
	const perPage = 50;
	// const [searchField, setSearchField] = useState<any>('');
	const [searchField, setSearchField] = useState<any>(Object.keys(EFilterField)[0]);
	const [searchValue, setSearchValue] = useState('');
	const [nameField, setNameField] = useState('');
	const [sortDirection, setSortDirection] = useState<{ [key: string]: string }>({});
	const debouncedSearch = useDebounce(searchValue, 500);

	const handlePageClick = ({ selected }: { selected: number }) => {
		dispatch(usersRequest({ per_page: perPage, current_page: selected + 1 }));
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	useEffect(() => {
		const payload = {
			per_page: perPage,
			search_field: searchField,
			search_value: debouncedSearch,
			sort_by: nameField || '',
			sort_direction: sortDirection[nameField],
		};

		dispatch(usersRequest(tryOnEmpty(payload)));
	}, [debouncedSearch, dispatch, searchField, sortDirection, nameField]);

	// useEffect(() => {
	// 	if (secretToken && WEB_API_URL) {
	// 		const attachment = `${window.location.replace(
	// 			`${WEB_API_URL}admin/download/users/${secretToken}`,
	// 		)}`;
	// 		// const attachment = `${String(WEB_API_URL?.replace('admin.', '') || '')}admin/download/users/${secretToken}`;
	// 		const link = document.createElement('a');
	// 		link.href = attachment;
	// 		// link.target = '_blank';
	// 		link.download = attachment;
	// 		// document.body.appendChild(link);
	// 		link.click();
	// 		// link.remove();
	// 		// 		const hiddenElement = document.createElement('a');
	// 		// hiddenElement.href = `data:text/xml;charset=utf-8,${encodeURI(xml)}`;
	// 		// hiddenElement.target = '_blank';
	// 		// hiddenElement.download = `${nameFile}.xlsx`;
	// 		// hiddenElement.click();
	// 	}
	// }, [secretToken, WEB_API_URL]);

/* eslint-disable */
	const downloadFile = (type:string) => {
		dispatch(generateSecretKeyRequest({ onOpenPdf: (key: string) => {
			if (key && WEB_API_URL) {
			 window.location.replace(`${WEB_API_URL}/admin/download/users/${type}/${key}`);
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions

			// 	const url = `${WEB_API_URL}/admin/download/users/${type}/${key}`;
            //   window.open(url, '_blank');
			}
		}}));
		
		//  if (secretToken && WEB_API_URL) {

		// 	const attachment = `${window.location.replace(`${WEB_API_URL}admin/download/users/${secretToken}`)}`;
		// 	//const attachment = `${String(WEB_API_URL?.replace('admin.', '') || '')}admin/download/users/${secretToken}`;
		// 	const link = document.createElement('a');
		// 	link.href = attachment;
		// 	// link.target = '_blank';
		// 	link.download = attachment;
		// 	// document.body.appendChild(link);
		// 	link.click();
			// link.remove();
	// 		const hiddenElement = document.createElement('a');
	// hiddenElement.href = `data:text/xml;charset=utf-8,${encodeURI(xml)}`;
	// hiddenElement.target = '_blank';
	// hiddenElement.download = `${nameFile}.xlsx`;
	// hiddenElement.click();
		//}
		// dispatch(generateSecretKeyRequest({ onOpenPdf: (key: string) => {} }));
	};

	return (
		<div className="main-content">
			<div className="panel-block">
				<div className="title-block">
					<p className="title"> User Management </p>
				</div>
				<div className="filter-group">
				<button
						onClick={()=>downloadFile('pdf')}
						type="button"
						className="button button--fw-500 button--width-auto2"
					>
						Download PDF
					</button>
					<button
						onClick={()=>downloadFile('xlsx')}
						type="button"
						className="button button--fw-500 button--width-auto2"
					>
						Download Exel
					</button>
				</div>
			</div>
			<div className="panel-block">
				<div className="title-block">
					<p className="title"> List of user’s</p>
				</div>
				<div className="filter-group">
					<div className="panel-search">
						<div className="input-wrapper">
							<input
								className="input-item input-item--left-icon input-item--type2"
								type="text"
								placeholder="Search by user id and email"
								value={searchValue}
								onChange={handleChange}
							/>
							<span className="input-icon input-icon--left input-icon--color2">
								<SearchIcon />
							</span>
						</div>
					</div>
					<div className="country-filter">
						<SelectDropdown values={EFilterField} onChange={setSearchField} value={searchField} />
					</div>
				</div>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--user-management">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name td-name__flex td-name--position">
										<p>ID</p>
										<SortArrowButton
											name="id"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Last login</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Full name</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name td-name__flex td-name--position">
										<p>Last wallet connected balance</p>
										<SortArrowButton
											name="sum"
											sortDirection={sortDirection}
											setNameField={setNameField}
											setSortDirection={setSortDirection}
										/>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Email</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Country</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Phone number</p>
									</div>
								</div>
								<div className="td ">
									<div className="td-name">
										<p>Password reset</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body table--position">
							{usersList &&
								usersList.users.map((el: IUser) => <UserManagementRow key={el.id} {...el} />)}
						</div>
					</div>
				</div>
			</div>
			<div className="pagination-block">
				{usersList && usersList.last_page > 1 && (
					<Pagination pageCount={Number(usersList?.last_page)} onPageChange={handlePageClick} />
				)}
			</div>
		</div>
	);
};

export default UserManagement;
