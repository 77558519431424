import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';

import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import { WEB_URL } from 'services/constants/env';
import { shortNotation } from 'services/utils/textSlice';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { TWallet } from './type';

export const Item: FC<TWallet> = ({ wallet, id }) => {
	const {
		address,
		user,
		user_id,
		wallet_balance_in_usd,
		wallet_balance_gato,
		wallet_balance_gusd,
		updated_at,
	} = wallet;

	const moneyFormat = useMoneyFormat();

	const handleCopy = () => {
		notificationContainer('Copied successfully!', 'info');
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Num.</p>
				<p>{id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>
					<NavLink to={`/user-details/${user_id}`} className="link link--type2 ">
						{user_id}
					</NavLink>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Full name</p>
				<p>
					{user?.data?.first_name || '-'} {user?.data?.last_name || ''}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Wallet balance</p>
				<p>{moneyFormat(wallet_balance_in_usd, 0, 6)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">GATO balance</p>
				<p>{moneyFormat(wallet_balance_gato, 0, 6)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">GUSD balance</p>
				<p>{moneyFormat(wallet_balance_gusd, 0, 6)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<div className="td-two-items">
					<div className="td-email-address">
						<p>
							<NavLink to={`/user-details/${user_id}`} className="link link--type2 ">
								{user?.email}
							</NavLink>
						</p>
					</div>
					<CopyToClipboard text={String(user?.email)} onCopy={handleCopy}>
						<button className="copy-btn" type="button">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.6819 8.5625V11.1875C10.6819 13.375 9.80689 14.25 7.61939 14.25H4.99438C2.80688 14.25 1.93188 13.375 1.93188 11.1875V8.5625C1.93188 6.375 2.80688 5.5 4.99438 5.5H7.61939C9.80689 5.5 10.6819 6.375 10.6819 8.5625Z"
									stroke="#C0C0CF"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14.4319 4.8125V7.4375C14.4319 9.625 13.5569 10.5 11.3694 10.5H10.6819V8.5625C10.6819 6.375 9.80689 5.5 7.61939 5.5H5.68188V4.8125C5.68188 2.625 6.55688 1.75 8.74438 1.75H11.3694C13.5569 1.75 14.4319 2.625 14.4319 4.8125Z"
									stroke="#C0C0CF"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Country</p>
				<p>{user?.lastLogin?.country || '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">User wallet address</p>
				<div className="td-two-items">
					<div className="td-wallet-address">
						<a href={`${WEB_URL || ''}/address/${address}`} target="_blank" rel="noreferrer">
							{address ? shortNotation(address, 4, 4) : '--'}
						</a>
					</div>
					<CopyToClipboard text={String(address)} onCopy={handleCopy}>
						<button className="copy-btn" type="button">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.6819 8.5625V11.1875C10.6819 13.375 9.80689 14.25 7.61939 14.25H4.99438C2.80688 14.25 1.93188 13.375 1.93188 11.1875V8.5625C1.93188 6.375 2.80688 5.5 4.99438 5.5H7.61939C9.80689 5.5 10.6819 6.375 10.6819 8.5625Z"
									stroke="#C0C0CF"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14.4319 4.8125V7.4375C14.4319 9.625 13.5569 10.5 11.3694 10.5H10.6819V8.5625C10.6819 6.375 9.80689 5.5 7.61939 5.5H5.68188V4.8125C5.68188 2.625 6.55688 1.75 8.74438 1.75H11.3694C13.5569 1.75 14.4319 2.625 14.4319 4.8125Z"
									stroke="#C0C0CF"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</CopyToClipboard>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p>{format(new Date(updated_at), 'd MMM yyyy hh:mm')}</p>
			</div>
		</div>
	);
};
