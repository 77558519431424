import { endOfMonth, format, getMonth, isThisMonth, startOfMonth } from 'date-fns';
import React, { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import { months } from 'services/utils/ditePicker';
import { IModalCalendarProps } from './type';

const ModalCalendar: FC<IModalCalendarProps> = ({
	setOpenModal,
	setStart,
	setEnd,
	start: defaultStart,
	end: defaultEnd,
}) => {
	const [startDateV, setStartDateV] = useState<any>(defaultStart || new Date());
	const [endDateV, setEndDateV] = useState<any>(defaultEnd || new Date());

	const handleChangeDate = (dates: [Date, Date]) => {
		const [start, end] = dates;
		setStartDateV(start);
		setEndDateV(end);
	};

	const handleSelectAllMonth = (date: number | Date) => {
		setStartDateV(startOfMonth(date));
		if (isThisMonth(date)) {
			setEndDateV(new Date());
		} else {
			setEndDateV(endOfMonth(date));
		}
	};

	const handleReset = () => {
		setStartDateV('');
		setEndDateV('');
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleDone = () => {
		if (!startDateV) return;
		setStart(startDateV);
		setEnd(endDateV || new Date());
		handleCloseModal();
	};

	return (
		<div className="popup popup--calendar popup--smaller">
			<div className="calendar">
				<div className="calendar-top">
					<input
						className="calendar-input"
						type="text"
						readOnly
						value={startDateV ? format(startDateV, 'yyyy.MM.dd') : ''}
					/>
					<span>-</span>
					<input
						className="calendar-input"
						type="text"
						readOnly
						value={endDateV ? format(endDateV, 'yyyy.MM.dd') : ''}
					/>
				</div>
				<DatePicker
					renderCustomHeader={({
						date,
						decreaseMonth,
						increaseMonth,
						prevMonthButtonDisabled,
						nextMonthButtonDisabled,
					}) => {
						return (
							<div className="calendar-header">
								<div className="btn-wrapper">
									<button
										className="arrow left-arrow"
										type="button"
										onClick={decreaseMonth}
										disabled={prevMonthButtonDisabled}
									>
										<span />
									</button>
									<span className="h5">{months[getMonth(date)]} </span>
									<button
										className="arrow right-arrow"
										type="button"
										onClick={increaseMonth}
										disabled={nextMonthButtonDisabled}
									>
										<span />
									</button>
								</div>

								<div className="select-wrapper">
									<button
										type="button"
										onClick={() => handleSelectAllMonth(date)}
										className="select-month"
									>
										Select all month
									</button>
								</div>
							</div>
						);
					}}
					selectsRange
					startDate={startDateV}
					endDate={endDateV}
					dateFormat="yyyy.MM.dd"
					onChange={handleChangeDate}
					maxDate={new Date()}
					inline
					renderDayContents={(date: number) => <span>{date}</span>}
				/>
			</div>
			<div className="popup-footer popup-footer--more-mt">
				<div className="popup-btns">
					<button
						type="button"
						onClick={handleReset}
						className="button button--type3 button--full-width"
					>
						Cancel
					</button>
					<button type="button" onClick={handleDone} className="button button--full-width">
						Done
					</button>
				</div>
			</div>
		</div>
	);
};

export default ModalCalendar;
