import {
	ILoginPayload,
	ILoginResponse,
	ILogin2FAResponse,
	IForgotPasswordResponse,
} from 'redux/reducers/auth/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAuthApi } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const auth: IAuthApi = {
	login: (payload) => {
		return http
			.post<ILoginPayload, { data: ILoginResponse | ILogin2FAResponse }>(
				endpoint.auth.LOGIN,
				payload,
			)
			.then(({ data }) => data);
	},
	logout: () => http.post(endpoint.auth.LOGOUT),
	forgotPassword: async (payload) => {
		return http
			.post<any, { data: IForgotPasswordResponse }>(endpoint.auth.RESET_PASSWORD, payload)
			.then((response) => response.data);
	},
};
