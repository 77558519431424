import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IWalletsApi } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const wallet: IWalletsApi = {
	getWallets: (params) => {
		return http.get<any>(endpoint.wallets.GET_WALLETS, params).then(({ data }) => data);
	},
};
