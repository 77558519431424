import { FC, useRef, useState } from 'react';

import { ArrowSelect } from 'assets/custom-icons';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import { EFilterField } from 'components/UserManagement';
/* eslint-disable */

export interface ISelectProps {
	options: any | [];
	onChange: (selectedValue: any) => void;
	value: keyof typeof EFilterField;
	name?: string | undefined;
}

export const SelectDropdownPeriod: FC<ISelectProps> = ({ options, onChange, value, name = '' }) => {
	const [drop, setDrop] = useState(false);

	const handleClickDrop = () => {
		setDrop(!drop);
	};

	const handleChange = (el: string) => {
		onChange(el);
		setDrop(false);
	};

	const ref = useRef<HTMLDivElement | null>(null);
	useOutsideClick(ref, () => setDrop(false));

	return (
		<div className={classNames('select select--type2', { active: drop })} ref={ref}>
			<button type="button" onClick={handleClickDrop} className="select__current">
				<span className="select-icon">
					<svg
						width="15"
						height="15"
						viewBox="0 0 15 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5 1.25V3.125"
							stroke="#4062E1"
							strokeWidth="1.2"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M10 1.25V3.125"
							stroke="#4062E1"
							strokeWidth="1.2"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M2.1875 5.68115H12.8125"
							stroke="#4062E1"
							strokeWidth="1.2"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M13.125 5.3125V10.625C13.125 12.5 12.1875 13.75 10 13.75H5C2.8125 13.75 1.875 12.5 1.875 10.625V5.3125C1.875 3.4375 2.8125 2.1875 5 2.1875H10C12.1875 2.1875 13.125 3.4375 13.125 5.3125Z"
							stroke="#4062E1"
							strokeWidth="1.2"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7.49718 8.5625H7.50279"
							stroke="#4062E1"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M5.18394 8.5625H5.18956"
							stroke="#4062E1"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M5.18394 10.4375H5.18956"
							stroke="#4062E1"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				{value} {name === 'month' ? '' : 'days'}
				<span className="select__current-arrow">
					<ArrowSelect />
				</span>
			</button>
			<div className="select__drop">
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<ul>
							{options.map((el: any) => (
								<li key={el}>
									<button type="button" onClick={() => handleChange(el)}>
										<span className="select__drop-link">
											{el} {name === 'month' ? '' : 'days'}
										</span>
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
