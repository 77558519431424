/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
// import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
// import { notificationContainer } from 'services/utils/notificationContainer';
import {
	TSwapRequest,
	TSwapDataResponse,
	TSwapTokenDataResponse,
	TSwapTokenPayload,
	ISwapTransactionsResponse,
} from './types';
import {
	getExchangeRequest,
	getExchangeSuccess,
	exchangeInitState,
	getHistoryTokensSuccess,
	getTokensRequest,
	getTokensSuccess,
	putActiveTokens,
	getHIstoryTokensRequest,
	getVolue24Request,
	getVolue24Success,
} from './reducer';

/* eslint-disable no-debugger */

// =============================================================:
function* getExchangeWorker(action: PayloadAction<TSwapRequest>) {
	const { payload } = action;

	try {
		const response: TSwapDataResponse = yield call(api.exchange.getPair, payload);
		yield put(getExchangeSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(exchangeInitState());
			responseErrors(error);
		}
		yield put(exchangeInitState());
	}
}

// =============================================================:
function* getTokensWorker(action: PayloadAction<TSwapRequest>) {
	const { payload } = action;

	try {
		const response: TSwapTokenDataResponse = yield call(api.exchange.getTokens, payload);
		yield put(getTokensSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(exchangeInitState());
			responseErrors(error);
		}
		yield put(exchangeInitState());
	}
}

// =============================================================:
function* putActiveTokensWorker(action: PayloadAction<TSwapTokenPayload>) {
	const { payload } = action;

	try {
		yield call(api.exchange.putToken, payload);
		// yield put(getPoolsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(exchangeInitState());
			responseErrors(error);
		}
		yield put(exchangeInitState());
	}
}

// =============================================================:
function* getHistoryWorker(action: PayloadAction<TSwapRequest>) {
	const { payload } = action;

	try {
		const response: ISwapTransactionsResponse = yield call(
			api.exchange.getHistoryTransaction,
			payload,
		);
		yield put(getHistoryTokensSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(exchangeInitState());
			responseErrors(error);
		}
		yield put(exchangeInitState());
	}
}

// =============================================================:
function* getVolue24Worker(action: PayloadAction<TSwapRequest>) {
	const { payload } = action;

	try {
		const response: string = yield call(api.exchange.getVolue24);
		yield put(getVolue24Success(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(exchangeInitState());
			responseErrors(error);
		}
		yield put(exchangeInitState());
	}
}
// =============================================================:
export function* exchangeSaga() {
	yield takeEvery(getExchangeRequest.type, getExchangeWorker);
	yield takeEvery(getTokensRequest.type, getTokensWorker);
	yield takeEvery(putActiveTokens.type, putActiveTokensWorker);
	yield takeEvery(getHIstoryTokensRequest.type, getHistoryWorker);
	yield takeEvery(getVolue24Request.type, getVolue24Worker);
}
