import { UserReferral } from 'components/ReferralManagement/UserReferral/UserReferral';
import { Page } from 'layouts/page';

export const ReferralUserPage = () => {
	return (
		<Page>
			<UserReferral />
		</Page>
	);
};
