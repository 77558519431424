import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { INavTabs } from './types';

export const NavTabs: FC<INavTabs> = ({ routes }) => {
	return (
		<div className="tabs">
			{routes.map(({ path, label }) => (
				<NavLink key={`${path}${label}`} to={path} className="tabs__item">
					{label}
				</NavLink>
			))}
		</div>
	);
};
