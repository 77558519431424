import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { IStakingStore, TStakingTransactionsResponse } from './types';

// ==========================================:
const getStakingState = (state: TStoreState): IStakingStore => state.staking;

// ====================================================:
export const getStaking = createSelector(
	[getStakingState],
	(staking: IStakingStore): TStakingTransactionsResponse | null => staking.transactions,
);

export const getStakingLoader = createSelector(
	[getStakingState],
	(staking: IStakingStore): boolean => staking.transactionsLoading,
);
