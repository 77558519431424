import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAssetsRequest } from 'redux/reducers/tokens/reducer';
import { TokenLayout as Token } from 'layouts/Token';
import usePagination from 'hooks/usePagination';
import TokensTable from 'components/Tokens/EditFee/TokensTable';

export const TransactionFeePage: FC = () => {
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();

	useEffect(() => {
		dispatch(
			getAssetsRequest({
				per_page: 24,
				current_page: currentPage,
				pulled: 1,
			}),
		);
	}, [currentPage, dispatch, perPage]);

	return (
		<Token title="All Tokens in Chain">
			<TokensTable />
		</Token>
	);
};
