import { FC, useEffect } from 'react';
import usePagination from 'hooks/usePagination';
import Loader from 'ui/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionsDetails, getTransactionsLoading } from 'redux/reducers/tokens/selectors';
import { getTokenTransactionsRequest } from 'redux/reducers/tokens/reducer';
import { NoDataTable } from 'layouts-elements/NoDataTable';
import { Item } from './Item';
import { TParams } from './types';

export const TokenTransactions: FC<TParams> = ({ address }) => {
	const transactionsLoading = useSelector(getTransactionsLoading);
	const transactionsDetails = useSelector(getTransactionsDetails);
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	useEffect(() => {
		if (address) dispatch(getTokenTransactionsRequest({ params: { asset_address: address } }));
	}, [dispatch, address]);
	// const totalPages = stakingList?.transactions.last_page || 1;

	const handaleUpdate = () => {
		if (address)
			dispatch(
				getTokenTransactionsRequest({
					params: {
						asset_address: address,
						per_page: String(Number(transactionsDetails?.per_page) + 15),
					},
				}),
			);
	};

	return (
		<div className="content-tables__transaction">
			<p className="block-subtitle block-subtitle--mt-16">Token Transactions</p>
			<div className="table table--token-transactions">
				<div className="table-header">
					<div className="tr">
						<div className="td td--left">
							<div className="td-name">
								<p>User ID</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Operation type</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Date</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Operation amount</p>
							</div>
						</div>
					</div>
				</div>
				<div className="table-body table--position">
					{transactionsLoading && (
						<div className="table-loader-wrapper">
							<Loader />
						</div>
					)}
					{!transactionsLoading && transactionsDetails?.data.length ? (
						transactionsDetails?.data?.map((el) => <Item key={el.id} el={el} />)
					) : (
						<NoDataTable />
					)}
				</div>
			</div>
			{transactionsDetails?.per_page === 15 && transactionsDetails.data.length < 15 ? null : (
				<div className="button__box">
					<button
						onClick={() => handaleUpdate()}
						type="button"
						className="button button--type3 btn-update"
					>
						More ...
					</button>
				</div>
			)}
		</div>
	);
};
