/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPoolTransactionsResponse } from '../pools/types';
import {
	IExchangeStore,
	TSwapRequest,
	TSwapDataResponse,
	TSwapTokenDataResponse,
	TSwapTokenPayload,
	ISwapTransactionsResponse,
} from './types';

// ==========================================:
export const initialState: IExchangeStore = {
	pairs: null,
	tokens: null,
	transactions: null,
	transactionsLoader: false,
	pairsLoading: false,
	tokensLoader: false,
	volue24: null,
};

// ==========================================:
const exchange = createSlice({
	name: '@@exchange',
	initialState,
	reducers: {
		getExchangeRequest: (state, action: PayloadAction<TSwapRequest>) => {
			const requestState = state;

			requestState.pairsLoading = true;
		},
		getExchangeSuccess: (state, action: PayloadAction<TSwapDataResponse>) => {
			const { payload } = action;
			const exchangeState = state;

			exchangeState.pairs = payload;
			exchangeState.pairsLoading = false;
		},
		getTokensRequest: (state, action: PayloadAction<TSwapRequest>) => {
			const requestState = state;

			requestState.pairsLoading = true;
		},
		getTokensSuccess: (state, action: PayloadAction<TSwapTokenDataResponse>) => {
			const { payload } = action;
			const exchangeState = state;

			exchangeState.tokens = payload;
			exchangeState.tokensLoader = false;
		},
		putActiveTokens: (state, action: PayloadAction<TSwapTokenPayload>) => {},
		getHIstoryTokensRequest: (state, action: PayloadAction<TSwapRequest>) => {
			const walletsRequestState = state;

			walletsRequestState.transactionsLoader = true;
		},
		getHistoryTokensSuccess: (state, action: PayloadAction<ISwapTransactionsResponse>) => {
			const { payload } = action;
			const walletsState = state;

			walletsState.transactions = payload;
			walletsState.transactionsLoader = false;
		},
		getVolue24Request: (state) => {
			const walletsRequestState = state;
		},
		getVolue24Success: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const walletsState = state;

			walletsState.volue24 = payload;
		},

		exchangeInitState: () => initialState,
	},
});

export default exchange.reducer;
export const {
	getExchangeRequest,
	getExchangeSuccess,
	exchangeInitState,
	getTokensRequest,
	getTokensSuccess,
	putActiveTokens,
	getHIstoryTokensRequest,
	getHistoryTokensSuccess,
	getVolue24Request,
	getVolue24Success,
} = exchange.actions;
