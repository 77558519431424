import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IReferralsApi } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const referrals: IReferralsApi = {
	getReferrals: async (params) => {
		return http.get<any>(endpoint.referral.GET_REFERRALS_USER, { params }).then(({ data }) => data);
	},
	getUserReferrals: async ({ id, params }) => {
		return http
			.get<any>(endpoint.referral.GET_REFERRAL_USER(id), { params })
			.then(({ data }) => data);
	},
	getReferralsList: async ({ params }) => {
		return http
			.get<any>(endpoint.referral.GET_REFERRALS_LIST_USER, { params })
			.then(({ data }) => data);
	},
	postReferralsChange: async ({ referralId, id }) => {
		return http
			.post<any>(endpoint.referral.POST_REFERRALS_CHANGE_USER(String(referralId), String(id)))
			.then(({ data }) => data);
	},
};
