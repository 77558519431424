export const removeEmptyStrings = <T extends Record<string, any>>(values: T): Partial<T> => {
	return Object.keys(values).reduce((acc, curr) => {
		if (values[curr] !== '') {
			return {
				...acc,
				[curr]: values[curr],
			};
		}
		return acc;
	}, {}) as T;
};
