/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IDividentsStore,
	TDividentsResponse,
	TDividentsRequest,
	IExpensesRequest,
	IExpensesResponse,
	IExpensesSaveRequest,
} from './types';

// ==========================================:
export const initialState: IDividentsStore = {
	dividents: null,
	dividentsLoading: false,
	expenses: null,
	expensesLoading: false,
};

// ==========================================:
const dividents = createSlice({
	name: '@@dividents',
	initialState,
	reducers: {
		getDividentsRequest: (state, action: PayloadAction<TDividentsRequest>) => {
			const statsState = state;

			statsState.dividentsLoading = true;
		},
		getDividentsSuccess: (state, action: PayloadAction<TDividentsResponse>) => {
			const { payload } = action;
			const statsState = state;

			statsState.dividents = payload;
			statsState.dividentsLoading = false;
		},
		getExpensesRequest: (state, action: PayloadAction<IExpensesRequest>) => {
			const statsState = state;

			statsState.expensesLoading = true;
		},
		getExpensesSuccess: (state, action: PayloadAction<IExpensesResponse>) => {
			const { payload } = action;
			const statsState = state;

			statsState.expenses = payload;
			statsState.expensesLoading = false;
		},
		saveExpensesRequest: (state, action: PayloadAction<IExpensesSaveRequest>) => {
			const statsState = state;

			statsState.dividentsLoading = true;
		},
		saveExpensesSuccess: (state, action: PayloadAction<TDividentsResponse>) => {
			const { payload } = action;
			const statsState = state;

			statsState.dividents = payload;
			statsState.dividentsLoading = false;
		},
		updateExpensesRequest: (state, action: PayloadAction<IExpensesSaveRequest>) => {
			const statsState = state;

			statsState.dividentsLoading = true;
		},
		updateExpensesSuccess: (state, action: PayloadAction<TDividentsResponse>) => {
			const { payload } = action;
			const statsState = state;

			statsState.dividents = payload;
			statsState.dividentsLoading = false;
		},

		dividentsInitState: () => initialState,
	},
});

export default dividents.reducer;
export const {
	getDividentsRequest,
	getDividentsSuccess,
	dividentsInitState,
	getExpensesRequest,
	getExpensesSuccess,
	saveExpensesRequest,
	updateExpensesRequest,
} = dividents.actions;
