import { FC } from 'react';
import { TPropsEdit } from './type';

export const EditField: FC<TPropsEdit> = ({ edit, field, title, value, onChange, playholder }) => {
	return (
		<div className="td">
			<p className="td-hidden-name">{title}</p>
			{edit ? (
				<div className="input-wrapper">
					<input
						className="input-item item--width"
						type="text"
						placeholder={playholder}
						value={value}
						onChange={(e) => onChange(e.currentTarget.value)}
					/>
				</div>
			) : (
				<p>{field}</p>
			)}
		</div>
	);
};
