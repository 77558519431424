import { FC, useState, ChangeEvent } from 'react';
import { TableSwitch } from 'ui/TableSwitch';
import { useDispatch } from 'react-redux';
import { putActiveTokens } from 'redux/reducers/exchange/reducer';
import { TPool } from './type';

export const Item: FC<TPool> = ({ pool, idNum }) => {
	const { id, symbol, logo, name, pairs } = pool;
	const dispatch = useDispatch();
	const [data, setData] = useState(pool);
	const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.currentTarget;

		setData({ ...data, is_active_global: checked });

		const payload = {
			asset_id: data?.id,
			active: Number(checked),
		};
		dispatch(putActiveTokens(payload));
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Num.</p>
				<p>{idNum}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Token</p>

				<div className="token-line">
					<img className="token-line__icon" src={logo} alt={symbol} />
					<span className="token-line__text">{symbol}</span>
					<span className="token-line__transcription">{name}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Available Pairs</p>
				<div className="token-line-list">
					{pairs.map((item) => (
						<div key={item.id} className="token-line token-line--border">
							<img
								className="token-line__icon"
								src={item.asset_base.logo}
								alt={item.asset_base.name}
							/>
							<span className="token-line__text">{item.asset_base.symbol}</span>
							<span className="token-line__text">/</span>
							<img
								className="token-line__icon"
								src={item.asset_quote.logo}
								alt={item.asset_quote.name}
							/>
							<span className="token-line__text">{item.asset_quote.symbol}</span>
						</div>
					))}
				</div>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Activate / Deactivate</p>
				<TableSwitch edit={id} checked={data.is_active_global}>
					<input
						type="checkbox"
						className="hidden"
						checked={data.is_active_global}
						onChange={handleCheckbox}
					/>
				</TableSwitch>
			</div>
		</div>
	);
};
