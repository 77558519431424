import { Home } from 'components/Home';
import { Page } from 'layouts/page';

import { FC } from 'react';

export const HomePage: FC = () => {
	return (
		<Page title="Home">
			<Home />
		</Page>
	);
};
