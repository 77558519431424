import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IDividentsApi } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const dividents: IDividentsApi = {
	getDiviedents: async (params) => {
		return http.get<any>(endpoint.dividents.GET_DIVIDENTS, { params }).then(({ data }) => data);
	},
	getExpenses: async () => {
		return http.get<any>(endpoint.dividents.GET_EXPENSES).then(({ data }) => data);
	},
	postExpenses: async ({ type, description, amount, id }) => {
		return type
			? http
					.put<any>(endpoint.dividents.POST_EXPENSES, { description, amount: Number(amount), id })
					.then(({ data }) => data)
			: http
					.post<any>(endpoint.dividents.POST_EXPENSES, { description, amount: Number(amount) })
					.then(({ data }) => data);
	},
};
