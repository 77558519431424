import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import popUp from 'redux/reducers/popUp/reducer';
import users from './reducers/users/reducer';
import auth from './reducers/auth/reducer';
import wallets from './reducers/wallets/reducer';
import tokens from './reducers/tokens/reducer';
import settings from './reducers/settings/reducer';
import tokensLp from './reducers/tokensLp/reducer';
import pools from './reducers/pools/reducer';
import exchange from './reducers/exchange/reducer';
import sockets from './reducers/sockets/reducer';
import stats from './reducers/stats/reducer';
import staking from './reducers/staking/reducer';
import referral from './reducers/referral/reducer';
import dividents from './reducers/dividents/reducer';

const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		auth,
		wallets,
		tokens,
		users,
		settings,
		tokensLp,
		popUp,
		pools,
		exchange,
		sockets,
		stats,
		staking,
		referral,
		dividents,
	});

export default createRootReducer;
