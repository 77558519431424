/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IReferralStore,
	TReferralRequest,
	TReferralsDataResponse,
	IReferralUser,
	TUserReferralistParams,
	TListOfRefarralResponse,
	TUserReferraChangePayload,
} from './types';

// ==========================================:
export const initialState: IReferralStore = {
	referrals: null,
	referralsLoading: false,
	userReferralsLoading: false,
	userReferral: null,
	listOfReferral: null,
};

// ==========================================:
const referral = createSlice({
	name: '@@referral',
	initialState,
	reducers: {
		getReferralUsersRequest: (state, action: PayloadAction<TReferralRequest>) => {
			const walletsRequestState = state;

			walletsRequestState.referralsLoading = true;
		},
		getReferralUsersSuccess: (state, action: PayloadAction<TReferralsDataResponse>) => {
			const { payload } = action;
			const walletsState = state;

			walletsState.referrals = payload;
			walletsState.referralsLoading = false;
		},
		getUserRefarralRequest: (state, action: PayloadAction<TReferralRequest>) => {
			const walletsRequestState = state;

			walletsRequestState.userReferralsLoading = true;
		},
		getReferralUserSuccess: (state, action: PayloadAction<IReferralUser>) => {
			const { payload } = action;
			const walletsState = state;

			walletsState.userReferral = payload;
			walletsState.userReferralsLoading = false;
		},

		getReferralUsersListRequest: (state, action: PayloadAction<TUserReferralistParams>) => {
			const walletsRequestState = state;

			// walletsRequestState.referralsLoading = true;
		},
		getReferralUsersListSuccess: (state, action: PayloadAction<TListOfRefarralResponse>) => {
			const { payload } = action;
			const walletsState = state;

			walletsState.listOfReferral = payload;
			// walletsState.referralsLoading = false;
		},
		postReferralUsersChangeRequest: (state, action: PayloadAction<TUserReferraChangePayload>) => {
			const walletsRequestState = state;
		},

		referralssInitState: () => initialState,
	},
});

export default referral.reducer;
export const {
	getReferralUsersRequest,
	getReferralUsersSuccess,
	referralssInitState,
	getUserRefarralRequest,
	getReferralUserSuccess,
	getReferralUsersListRequest,
	getReferralUsersListSuccess,
	postReferralUsersChangeRequest,
} = referral.actions;
