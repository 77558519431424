import { store } from 'redux/store';
import { authInitState } from 'redux/reducers/auth/reducer';
import { errors } from 'redux/reducers/errors/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { IAxiosError, IAxiosRequestConfig } from './types';

// ==========================================:
const onError = (error: IAxiosError) => {
	const status = error.response ? error.response.status : null;
	const errorName = error.response ? error.response.data : null;
	const errorResponse: IAxiosRequestConfig = error.config;
	const token = store.getState().auth.accessToken;
	store.dispatch(errors(status));
	if (status === 401 && !errorResponse._retry && token) {
		store.dispatch(authInitState());
		notificationContainer('session has ended', 'info');
	}

	if (status === 403 && !errorResponse._retry && errorName.errors.includes('user_blocked')) {
		notificationContainer(`user blocked`, 'error');
	}

	if (status === 403 && errorName.errors.includes('email_not_confirmed')) {
		notificationContainer('Email not confirmed', 'error');
	}

	if (status === 422 && errorName.errors.totp.includes('the_totp_field_is_required')) {
		notificationContainer('The 2FA code is required', 'error');
	}

	if (status === 400 && errorName.errors.includes('invalid_totp_code')) {
		notificationContainer('Invalid 2FA code', 'error');
	}

	return Promise.reject(error);
};

export default onError;

// // // ==========================================:
