import { FC } from 'react';
import { Header } from 'layouts-elements/Header';
import { Content } from 'layouts-elements/Content';
import { IAuth } from './types';

// ==========================================:
export const Auth: FC<IAuth> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';

	return (
		<>
			<div className="wrapper">
				<Header />
				<Content>{children}</Content>
			</div>
		</>
	);
};
