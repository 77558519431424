import LpTokens from 'components/LpTokens';
import { Page } from 'layouts/page';
import React from 'react';

const LpTokensPage = () => {
	return (
		<Page>
			<LpTokens />
		</Page>
	);
};

export default LpTokensPage;
