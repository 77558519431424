import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IStakingApi } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const staking: IStakingApi = {
	getTransactions: async ({ params }) => {
		return http.get<any>(endpoint.staking.GET_TRANSACTIONS, { params }).then(({ data }) => data);
	},
};
