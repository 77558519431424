import { FC, useEffect, useState, MouseEvent, useRef } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import Pagination from 'ui/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getVolue24Request } from 'redux/reducers/exchange/reducer';
import { getValue24 } from 'redux/reducers/exchange/selectors';
import { Link, useNavigate } from 'react-router-dom';
import { WEB_URL } from 'services/constants/env';
import { Pair } from './Pair';
import { TransactionHistory } from './TransactionHistory';
import { Tokens } from './Tokens';

export const Exchange: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const value24 = useSelector(getValue24);
	const [searchValue, setSearchValue] = useState('');
	const [currentTab, setCurrentTab] = useState<string>('pairs');
	const [totalPages, setTotalPages] = useState(1);
	const debouncedSearch = useDebounce(searchValue, 500);
	const scrollRef = useRef(null);

	useEffect(() => {
		setSearchValue('');
	}, [currentTab]);

	const handleText = (text: string) => {
		if (text === 'pairs') return 'Search by tokens';
		return 'Search by user ID and hash';
	};

	const tabs = {
		pairs: <Pair search={debouncedSearch} setTotalPages={setTotalPages} />,
		tokens: <Tokens search={debouncedSearch} setTotalPages={setTotalPages} />,
		transactions: <TransactionHistory search={debouncedSearch} setTotalPages={setTotalPages} />,
	};
	const handleTab = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setCurrentTab(name);
		navigate({ search: '' });
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	useEffect(() => {
		dispatch(getVolue24Request());
	}, [dispatch]);

	return (
		<div className="main-content">
			<div className="panel-block">
				<div className="title-block">
					<p className="title">Exchange/Swap</p>
				</div>
			</div>
			<div className="swap-excgange-block">
				<div className="swap-excgange-info">
					<p className="swap-excgange-info__name">Volume 24h</p>
					<p className="swap-excgange-info__value">
						<span className="swap-excgange-info__value-big">{value24} GUSD</span>
					</p>
				</div>
				<div className="swap-excgange-controls">
					<div className="panel-search">
						<div className="input-wrapper">
							<input
								className="input-item input-item--left-icon input-item--type2"
								type="text"
								placeholder={handleText(currentTab)}
								value={searchValue}
								onChange={handleChange}
							/>
							<span className="input-icon input-icon--left input-icon--color2">
								<svg
									width="17"
									height="17"
									viewBox="0 0 17 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z"
										stroke="#8E8EA9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M16.5 16.5L15 15"
										stroke="#8E8EA9"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
						</div>
					</div>
					<a
						href={`${WEB_URL || ''}/liquidity`}
						className="button button--fw-500 button--width-auto2"
					>
						Add new pair
						<span className="button__icon">
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M4.5 9H13.5"
									stroke="white"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M9 13.5V4.5"
									stroke="white"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</a>
					<Link to="/token-create" className="button button--fw-500 button--width-auto2">
						Add new token
						<span className="button__icon">
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M4.5 9H13.5"
									stroke="white"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M9 13.5V4.5"
									stroke="white"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</Link>
				</div>
			</div>
			<div className="tabs" ref={scrollRef}>
				<button
					type="button"
					onClick={handleTab}
					name="pairs"
					className={`tabs__item ${currentTab === 'pairs' ? 'active' : ''}`}
				>
					Pairs
				</button>
				<button
					type="button"
					onClick={handleTab}
					name="tokens"
					className={`tabs__item ${currentTab === 'tokens' ? 'active' : ''}`}
				>
					Tokens
				</button>
				<button
					type="button"
					onClick={handleTab}
					name="transactions"
					className={`tabs__item ${currentTab === 'transactions' ? 'active' : ''}`}
				>
					Transactions
				</button>
			</div>
			<div className="table-block table-block--border-tabs">
				<div className="table-wrapper">{tabs[currentTab as keyof typeof tabs]}</div>
			</div>
			{totalPages > 1 && <Pagination pageCount={totalPages} refElement={scrollRef} />}
		</div>
	);
};
