import { api, responseErrors } from 'services';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { ITokensLpRequest, ITokensLpResponse } from './types';
import { tokensRequest, tokensSuccess } from './reducer';

function* getTokensLpWorker({ payload }: PayloadAction<ITokensLpRequest>) {
	try {
		const response: ITokensLpResponse = yield call(api.tokensLp.getTokens, payload);
		yield put(tokensSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* tokensLpSaga() {
	yield takeEvery(tokensRequest, getTokensLpWorker);
}
