/* eslint-disable no-debugger */
import { FC, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAssets, getAssetsLoader } from 'redux/reducers/tokens/selectors';
import { toggleActivityRequest } from 'redux/reducers/tokens/reducer';
import usePagination from 'hooks/usePagination';
import Pagination from 'ui/Pagination';

import { IAsset } from 'redux/reducers/tokens/types';
import Loader from 'ui/Loader';

const TokensTable: FC = () => {
	const dispatch = useDispatch();

	const assetsResponse = useSelector(getAssets);
	const loading = useSelector(getAssetsLoader);
	const { assets, total } = assetsResponse || {};

	const { perPage, currentPage } = usePagination();
	const totalPages = total && Math.ceil(total / perPage);

	const assetsList: IAsset[][] = [];
	const size = 3;
	if (assets) {
		for (let i = 0; i < Math.ceil(assets.length / size); i += 1) {
			assetsList[i] = assets.slice(i * size, i * size + size);
		}
	}

	const onActiveCheck = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(toggleActivityRequest(Number(e.target.value)));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--token-made-list">
						<div className="table-header">
							<div className="tr">
								<div className="td-group">
									<div className="td">
										<div className="td-name">
											<p>Token</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Activate / Deactivate</p>
										</div>
									</div>
								</div>
								<div className="td-group">
									<div className="td">
										<div className="td-name">
											<p>Token</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Activate / Deactivate</p>
										</div>
									</div>
								</div>
								<div className="td-group">
									<div className="td">
										<div className="td-name">
											<p>Token</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Activate / Deactivate</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body table--position">
							{loading && (
								<div className="loader-wrapper loader-wrapper__cover">
									<Loader />
								</div>
							)}

							{assetsList?.map((row) => (
								<div key={Math.random()} className="tr">
									{row.map((asset) => (
										<div key={asset.id} className="td-group">
											<div className="td">
												<p className="td-hidden-name">Pairs</p>
												<Link to={`/tokens/${asset?.id}`} className="token-line">
													<img className="token-line__icon" src={asset.logo} alt="bnb" />
													<span className="token-line__text">{asset.symbol}</span>
													<span className="token-line__transcription">{asset.name}</span>
												</Link>
											</div>
											<div className="td ">
												<p className="td-hidden-name">Activate / Deactivate</p>
												<div className="switch switch--center">
													<label className="switch__label">
														<input
															type="checkbox"
															checked={asset.is_active_global}
															value={asset.id}
															onChange={onActiveCheck}
															className="hidden"
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									))}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			{!!assetsList.length && !!totalPages && totalPages > 1 && (
				<Pagination pageCount={totalPages} />
			)}
		</>
	);
};

export default TokensTable;
