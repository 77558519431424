/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	authInitState,
	loginRequest,
	loginSuccess,
	loginGoTo2FASuccess,
	logoutRequest,
	forgotPasswordRequest,
	forgotPasswordSuccess,
} from './reducer';
import {
	ILoginResponse,
	ILoginPayload,
	ILogoutPayload,
	ILogin2FAResponse,
	IForgotPasswordPayload,
} from './types';
import { get2FATypeByResponse } from './utils';
/* eslint-disable no-debugger */

// =============================================================:
function* loginRequestWorker({ payload }: PayloadAction<ILoginPayload>) {
	const { data: apiParams } = payload;

	try {
		const data: ILoginResponse | ILogin2FAResponse = yield call(api.auth.login, apiParams);

		// const type2FA = get2FATypeByResponse(data);

		// if (type2FA) {
		// 	yield put(loginGoTo2FASuccess());
		// 	on2FA?.({ apiParams, type2FA });
		// } else {
		yield put(loginSuccess(data as ILoginResponse));
		// }
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 502 || error.response?.status === 500) {
				notificationContainer('Server error', 'error');
				yield put(authInitState());
				return;
			}
			// if (
			// 	error?.response?.data?.errors &&
			// 	error?.response?.data?.errors[0] === 'invalid_totp_code'
			// ) {
			// 	notificationContainer(`Invalid 2FA code`, 'error');
			// 	yield put(authInitState());
			// 	return;
			// }
			if (error?.response?.data?.errors[0] === 'invalid_credentials') {
				notificationContainer('Invalid credentials', 'error');
				yield put(authInitState());
				return;
			}
			if (error?.response?.data?.errors[0] === 'you_need_confirmed_account!_check_your_email') {
				notificationContainer('You need confirmed account! check your email', 'info');
				yield put(authInitState());
				return;
			}
			if (error?.response?.data?.errors[0] === 'you_need_confirmed_account_check_your_sms_code') {
				notificationContainer('You need confirmed account check your sms code', 'info');
				yield put(authInitState());
				return;
			}
			// if (error.response?.data?.errors?.[0] === 'already_sent_code') {
			// 	// Backend bug? Need to send a code with each login?
			// 	yield put(loginGoTo2FASuccess());
			// 	onEmailAlreadySent?.({ apiParams });
			// 	yield put(authInitState());
			// }

			// if (error?.response?.data?.errors) {
			// 	yield put(authInitState());
			// 	callErrorFunc(error?.response?.data?.errors);
			// 	return;
			// }
			yield put(authInitState());
			// responseErrors(error);
		}
		yield put(authInitState());
	}
}

// =============================================================:
function* logoutRequestWorker(action: PayloadAction<ILogoutPayload>) {
	const { payload } = action;

	try {
		yield call(api.auth.logout);
		yield put(authInitState());
		payload.navigate({ search: '' });
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	}
}

// =============================================================:

function* forgotPasswordRequestWorker(action: PayloadAction<IForgotPasswordPayload>) {
	const { payload } = action;
	const { email, phone, navigate } = payload;

	try {
		yield call(api.auth.forgotPassword, { email });
		yield put(forgotPasswordSuccess());

		if (email && navigate) {
			notificationContainer('Check the email', 'info');
			navigate('/check-email', {
				state: {
					email: payload.email,
				},
			});
			/* eslint-disable no-useless-return */
			return;
			// =============================================================:
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.status === 404 &&
				error?.response?.data?.errors[0] === 'model_not_found' &&
				phone
			) {
				notificationContainer('Incorrect phone number', 'info');
				yield put(authInitState());
				return;
			}
			if (
				error?.response?.status === 404 &&
				error?.response?.data?.errors[0] === 'model_not_found' &&
				email
			) {
				notificationContainer('Incorrect email address', 'info');
				yield put(authInitState());
				return;
			}
			yield put(authInitState());
			responseErrors(error);
		}
		yield put(authInitState());
	}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(forgotPasswordRequest.type, forgotPasswordRequestWorker);
}
