import { FC, useEffect } from 'react';
import usePagination from 'hooks/usePagination';
import Loader from 'ui/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getDividents, getLoaderDividents } from 'redux/reducers/dividents/selectors';

import { Item } from './Item';

export const StakingTransactions: FC = () => {
	const dividentsList = useSelector(getDividents);
	const dividentsLoader = useSelector(getLoaderDividents);
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	// const totalPages = stakingList?.transactions.last_page || 1;

	return (
		<div className="table table--staking-transactions">
			<div className="table-header">
				<div className="tr">
					<div className="td td--left">
						<div className="td-name">
							<p>User ID</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Operation type</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Date</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Operation amount</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Reward from Staking</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Pool amount</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Percent</p>
						</div>
					</div>
					{/* <div className="td">
						<div className="td-name">
							<p>
								Profit Gato <br /> Chain
							</p>
						</div>
					</div> */}
					<div className="td">
						<div className="td-name">
							<p>Transaction hash</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Transaction staus</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body table--position">
				{dividentsLoader && (
					<div className="table-loader-wrapper">
						<Loader />
					</div>
				)}
				{!dividentsLoader && dividentsList?.transactions.response.length
					? dividentsList?.transactions?.response?.map((el) => <Item key={el.id} el={el} />)
					: null}
			</div>
		</div>
	);
};
