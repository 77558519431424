import { FC } from 'react';
import { Link } from 'react-router-dom';
import { notificationContainer } from 'services/utils/notificationContainer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TStakingTramsactionItemUser } from 'redux/reducers/staking/types';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { format } from 'date-fns';
import { WEB_URL } from 'services/constants/env';
import { TParams } from './type';

export const Item: FC<TParams> = ({ el }) => {
	const { id, updated_at, type, base_amount, quote_amount } = el;
	const moneyFormat = useMoneyFormat();

	const operationType = (value: string) => {
		if (value === 'add_staking') {
			return 'Staking';
		}
		if (value === 'claim_staking') {
			return 'Claim Staking';
		}
		return 'Add liquidity';
	};

	const handleStatus = (value: string) => {
		if (value === 'completed') {
			return <p className="status-tag status-tag--success">Successed</p>;
		}
		if (value === 'pending') {
			return <p className="status-tag status-tag--pending">Pending</p>;
		}
		return <p className="status-tag status-tag--error">Faild</p>;
	};

	const handleCopy = () => {
		notificationContainer('Copied successfully!', 'info');
	};
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">User ID.</p>
				<p>{id}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Operation type</p>
				<p>{type}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p>{updated_at ? format(Date.parse(updated_at), 'MM/dd/yyy hh:mm') : '--/--'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Operation amount</p>
				<p>
					<p>{Number(base_amount)?.toFixed(8) || Number(quote_amount)?.toFixed(8)}</p>
				</p>
			</div>
		</div>
	);
};
