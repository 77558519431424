export const endpoint = {
	auth: {
		LOGIN: '/admin/login',
		LOGOUT: '/admin/logout',
		RESET_PASSWORD: '/admin/reset/password/email',
	},
	users: {
		GET_USERS: '/admin/users/',
		GET_USER: (userId: string) => `/admin/user/${userId}`,
		RESET_PASSWORD: (userId: string) => `/admin/user/${userId}/reset_password`,
		GET_PROFIT: (userId: string) => `/admin/user/${userId}/profit`,
		GET_GENERATE_KEY: '/admin/download/code',
		OPEN_DOWNLOAD: (key: string) => `admin/download/users/${key}`,
		DELETE_USER: (id: string) => `admin/user/${id}`,
	},
	wallets: {
		GET_WALLETS: '/admin/wallets',
	},
	tokens: {
		CREATE_TOKEN: '/admin/asset/token/create',
		GET_ASSETS: '/admin/assets',
		GET_ASSETS_CUSTOM: '/admin/assets/custom',
		TOGGLE_ACTIVE: (id: number) => `/admin/asset/token/toggle_active/${id}`,
		GET_ASSET_ITEM: (assetId: number) => `/admin/asset/${assetId}`,
		GET_BLOCKCHAIN_ASSET_ITEM: (address: string) => `/admin/asset/blockchain/${address}`,
		MINT_TOKEN: '/admin/asset/token/mint_token',
		EDIT_FEE: '/admin/asset/token/edit_fee',
		EDIT_FEE_COIN: '/admin/asset/edit_fee',
		APPROVE: (id: number) => `/admin/asset/token/approve/${id}`,
		REJECT: (id: number) => `/admin/asset/token/reject/${id}`,
		EDIT_IMAGE: '/admin/asset/token/edit_image',
		CALCULATE_FEE: '/admin/asset/calculate_fee',
		UPDATE_TOKEN: '/admin/asset/token/update',
		GET_DETAILS_ASSET: (asset: string) => `/admin/detailed_asset/${asset}`,
		GET_DETAILS_TRANSACTIONS: `/admin/swap/transactions`,
		GET_TOTAL_DETAILS_ASSET: (asset: string) => `/admin/total_detailed_asset/${asset}`,
	},
	settings: {
		CHANGE_PASSWORD: '/admin/password/change',
		ENABLE_2FA: '/admin/2fa/enable',
		DISABLE_2FA: '/admin/2fa/disable',
		GENERATE_2FA_KEY: '/admin/2fa/generate_secret_key',
	},
	tokensLp: {
		GET_TOKENS_LP: '/admin/tokens/lp',
	},
	pools: {
		GET_POOLS: '/admin/pool',
		PUT_POOL: '/admin/pool/lock_liquidity',
		PUT_SWAP: '/admin/pool/hide_swap',
		GET_TRANSACTION_HISTORY: '/admin/pool/transactions',
	},
	exchange: {
		GET_PAIRS: '/admin/swap/pairs',
		GET_TOKENS: '/admin/swap/tokens',
		PUT_TOKEN: '/admin/swap/token',
		GET_TRANSACTION_HISTORY: '/admin/swap/transactions',
		GET_VOLUME_24H: '/admin/swap/volume_24h',
	},
	referral: {
		GET_REFERRALS_USER: '/admin/referrals',
		GET_REFERRAL_USER: (id: string) => {
			/* eslint-disable */
			return `/admin/referrals/${id}`;
		},
		GET_REFERRALS_LIST_USER: `/admin/referrals/list_of_users`,
		POST_REFERRALS_CHANGE_USER: (refId: string, newId: string) =>
			`/admin/referrals/change_parent/${refId}/${newId} `,
	},
	stats: {
		GET_TOTAL_STATS: '/admin/assets/profit',
	},
	staking: {
		GET_TRANSACTIONS: '/admin/staking',
	},
	dividents: {
		GET_DIVIDENTS: '/admin/dividend',
		GET_EXPENSES: '/admin/dividend/expenses',
		POST_EXPENSES: '/admin/dividend/expenses',
	},
};
