/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
// import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
// import { notificationContainer } from 'services/utils/notificationContainer';
import {
	TReferralRequest,
	TReferralsDataResponse,
	TUserReferralRequest,
	TUserReferralistParams,
	TListOfRefarralResponse,
	TUserReferraChangePayload,
	IReferralUser,
} from './types';
import {
	referralssInitState,
	getReferralUsersRequest,
	getReferralUsersSuccess,
	getUserRefarralRequest,
	getReferralUserSuccess,
	getReferralUsersListRequest,
	getReferralUsersListSuccess,
	postReferralUsersChangeRequest,
} from './reducer';
/* eslint-disable no-debugger */

// =============================================================:
function* getReferralUsersWorker(action: PayloadAction<TReferralRequest>) {
	const { payload } = action;

	try {
		const response: TReferralsDataResponse = yield call(api.referrals.getReferrals, payload);
		yield put(getReferralUsersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(referralssInitState());
			responseErrors(error);
		}
		yield put(referralssInitState());
	}
}

function* getReferralUserWorker(action: PayloadAction<TUserReferralRequest>) {
	const { payload } = action;

	try {
		const response: IReferralUser = yield call(api.referrals.getUserReferrals, {
			...payload,
		});
		yield put(getReferralUserSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(referralssInitState());
			responseErrors(error);
		}
		yield put(referralssInitState());
	}
}

function* getReferralUsersListWorker(action: PayloadAction<TUserReferralistParams>) {
	const { payload } = action;

	try {
		const response: TListOfRefarralResponse = yield call(api.referrals.getReferralsList, payload);
		yield put(getReferralUsersListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(referralssInitState());
			responseErrors(error);
		}
		yield put(referralssInitState());
	}
}

function* postReferralUsersChangeWorker(action: PayloadAction<TUserReferraChangePayload>) {
	const { payload } = action;

	try {
		// const response: TListOfRefarralResponse =
		yield call(api.referrals.postReferralsChange, payload);
		// yield put(getReferralUsersListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// yield put(referralssInitState());
			responseErrors(error);
		}
		// yield put(referralssInitState());
	}
}
// =============================================================:
export function* referralSaga() {
	yield takeEvery(getReferralUsersRequest.type, getReferralUsersWorker);
	yield takeEvery(getUserRefarralRequest.type, getReferralUserWorker);
	yield takeEvery(getReferralUsersListRequest.type, getReferralUsersListWorker);
	yield takeEvery(postReferralUsersChangeRequest.type, postReferralUsersChangeWorker);
}
