import { FC, useEffect } from 'react';
import usePagination from 'hooks/usePagination';
import Loader from 'ui/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
	getTransactionsClaimDetails,
	getTransactionsClaimLoading,
} from 'redux/reducers/tokens/selectors';
import { getTokenTransactionsClaimRequest } from 'redux/reducers/tokens/reducer';
import { NoDataTable } from 'layouts-elements/NoDataTable';
import { TParams } from './types';
import { Item } from './Item';

export const Claiming: FC<TParams> = ({ address }) => {
	const transactionsClaimDetails = useSelector(getTransactionsClaimDetails);
	const transactionsClaimLoading = useSelector(getTransactionsClaimLoading);
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	// const totalPages = stakingList?.transactions.last_page || 1;

	useEffect(() => {
		if (address)
			dispatch(
				getTokenTransactionsClaimRequest({
					params: {
						type: 'claim_staking,Claim,claim_infinity_referral,claim_b2b_infinity_referral',
						asset_address: address,
					},
				}),
			);
	}, [dispatch, address]);

	const handaleUpdate = () => {
		if (address)
			dispatch(
				getTokenTransactionsClaimRequest({
					params: {
						asset_address: address,
						type: 'claim_staking,Claim,claim_infinity_referral,claim_b2b_infinity_referral',
						per_page: String(Number(transactionsClaimDetails?.per_page) + 15),
					},
				}),
			);
	};

	return (
		<div className="content-tables__claiming">
			<p className="block-subtitle block-subtitle--mt-16">Claiming record</p>
			<div className="table table--claiming">
				<div className="table-header">
					<div className="tr">
						<div className="td td--left">
							<div className="td-name">
								<p>User ID</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Operation type</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Date</p>
							</div>
						</div>
						<div className="td">
							<div className="td-name">
								<p>Operation amount</p>
							</div>
						</div>
					</div>
				</div>
				<div className="table-body table--position">
					{transactionsClaimLoading && (
						<div className="table-loader-wrapper">
							<Loader />
						</div>
					)}
					{!transactionsClaimLoading && transactionsClaimDetails?.data.length ? (
						transactionsClaimDetails?.data?.map((el) => <Item key={el.id} el={el} />)
					) : (
						<NoDataTable />
					)}
				</div>
			</div>
			{transactionsClaimDetails?.per_page === 15 &&
			transactionsClaimDetails.data.length < 15 ? null : (
				<div className="button__box">
					<button
						onClick={() => handaleUpdate()}
						type="button"
						className="button button--type3 btn-update"
					>
						More ...
					</button>
				</div>
			)}
		</div>
	);
};
