import { FC } from 'react';
import { SearchIcon } from 'assets/custom-icons';
import { IFilter } from './types';

export const Filter: FC<IFilter> = ({ searchValue, setSearchValue }) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};
	return (
		<div className="panel-search">
			<div className="input-wrapper">
				<input
					className="input-item input-item--left-icon input-item--type2"
					type="text"
					placeholder="Search by user id and wallet address"
					value={searchValue}
					onChange={handleChange}
				/>
				<span className="input-icon input-icon--left input-icon--color2">
					<SearchIcon />
				</span>
			</div>
		</div>
	);
};
