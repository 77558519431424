import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	editFeeCoinRequest,
	editFeeRequest,
	getFeeInTokenRequest,
} from 'redux/reducers/tokens/reducer';

import SelectDropdown from 'ui/SelectDropdown';
import { PercentSvg } from 'assets/custom-icons';
import { getFeeInToken } from 'redux/reducers/tokens/selectors';
import { EFeeCategory, ETypeField } from '..';
import { IFeeItemProps } from './types';

const FeeItem: FC<IFeeItemProps> = ({ feeName, asset, nativeCoin }) => {
	const dispatch = useDispatch();
	const feeInToken = useSelector(getFeeInToken);

	const [value, setValue] = useState(asset.token_fee[`${feeName}_commission_value`]);
	const [maxValue, setMaxValue] = useState(asset.token_fee[`${feeName}_max_amount`]);
	const [feeType, setFeeType] = useState(
		asset.token_fee[`${feeName}_commission_type`] || 'percent',
	);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const result = e.target.value.replace(/[^\d.]/g, '');
		setValue(result);
	};

	const handleChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
		const result = e.target.value.replace(/[^\d.]/g, '');
		setMaxValue(result);
	};

	useEffect(() => {
		if (asset && feeType !== 'percent' && Number(value) > 0) {
			dispatch(
				getFeeInTokenRequest({
					requestParams: {
						asset_id:
							asset.symbol === 'GATO' && asset.type === 'coin' ? Number(nativeCoin) : asset.id,
						amount: Number(value),
						type: 0,
					},
					fee_name: feeName,
				}),
			);
		}
	}, [asset, dispatch, feeName, feeType, value, nativeCoin]);

	const handleSave = () => {
		const payload = {
			asset_id: Number(asset.id),
			commission_type: feeType,
			commission: Number(value),
			commission_category: feeName,
			max_amount: feeType === 'percent' ? Number(maxValue) : undefined,
			name: asset.name,
		};

		asset.type === 'coin'
			? dispatch(editFeeCoinRequest(payload))
			: dispatch(editFeeRequest(payload));
	};

	return (
		<div className="fee-item">
			<p className="fee-name">{EFeeCategory[feeName as keyof typeof EFeeCategory]} Fee, %</p>
			<div className="input">
				<p className="input__name">Transfer Fee Type</p>
				<SelectDropdown values={ETypeField} onChange={setFeeType} value={feeType} />
				<p className="input-transcription">
					Choose whether the transfer fee will be calculated as percentage or fixed amount.
				</p>
			</div>
			{feeType === 'percent' ? (
				<>
					<div className="input">
						<p className="input__name">Transfer Fee Percentage</p>
						<div className="input-wrapper">
							<input
								className="input-item input-item--pr50"
								type="text"
								placeholder="Enter Transfer Fee"
								value={value}
								onChange={handleChange}
							/>
							<span className="input-icon input-icon--color3">
								<PercentSvg />
							</span>
						</div>
						<p className="input-transcription">Set transfer fee percent.</p>
					</div>
					<div className="input">
						<p className="input__name">Max Transfer Fee</p>
						<div className="input-wrapper">
							<input
								className="input-item input-item--pr50"
								type="text"
								placeholder="Enter Max Transfer Fee"
								value={maxValue}
								onChange={handleChangeMax}
							/>
							<span className="input-text">USD</span>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="input">
						<p className="input__name">Transfer Fee Amount</p>
						<div className="input-wrapper">
							<input
								className="input-item"
								type="text"
								placeholder="Enter Transfer Fee"
								value={value}
								onChange={handleChange}
							/>
						</div>
						<p className="input-transcription">Set transfer fee amount in USD.</p>
					</div>
					<div className="input">
						<p className="input__name">Fee in tokens</p>
						<div className="input-wrapper">
							<input
								className="input-item input-readonly"
								type="text"
								readOnly
								value={value > 0 ? feeInToken[feeName] : 0}
							/>
						</div>
					</div>
				</>
			)}

			<button onClick={handleSave} type="button" className="button button--full-width">
				Save
			</button>
		</div>
	);
};

export default FeeItem;
