import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { IWalletsStore, TWalletsDataResponse } from 'redux/reducers/wallets/types';

// ==========================================:
const getWalletsState = (state: TStoreState): IWalletsStore => state.wallets;

// ====================================================:
export const getWallets = createSelector(
	[getWalletsState],
	(wallets: IWalletsStore): TWalletsDataResponse | null => wallets.wallets,
);

export const getWalletsLoader = createSelector(
	[getWalletsState],
	(wallets: IWalletsStore): boolean => wallets.walletsLoading,
);
