/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITokensLpStore, ITokensLpRequest, ITokensLpResponse } from './types';

export const initialState: ITokensLpStore = {
	tokens: null,
	tokensLoading: false,
};

const tokensLp = createSlice({
	name: '@@tokensLp',
	initialState,
	reducers: {
		tokensRequest: (state, { payload }: PayloadAction<ITokensLpRequest>) => {
			state.tokensLoading = true;
		},
		tokensSuccess: (state, { payload }: PayloadAction<ITokensLpResponse>) => {
			state.tokensLoading = false;
			state.tokens = payload;
		},
	},
});

export default tokensLp.reducer;
export const { tokensRequest, tokensSuccess } = tokensLp.actions;
