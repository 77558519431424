import { Staking } from 'components/Staking';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const StakingPage: FC = () => {
	return (
		<Page>
			<Staking />
		</Page>
	);
};
