import React from 'react';

import UserDetails from 'components/UserDetails';
import { Page } from 'layouts/page';

const UserDetailsPage = () => {
	return (
		<Page>
			<UserDetails />
		</Page>
	);
};

export default UserDetailsPage;
