import { SearchIcon } from 'assets/custom-icons';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tokensRequest } from 'redux/reducers/tokensLp/reducer';
import { getTokensLp } from 'redux/reducers/tokensLp/selectors';
import Pagination from 'ui/Pagination/Pagination';
import LpTokensTable from './LpTokensTable';

const LpTokens = () => {
	const dispatch = useDispatch();
	const tokens = useSelector(getTokensLp);

	const perPage = 8;
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearch = useDebounce(searchValue, 500);

	const handlePageClick = ({ selected }: { selected: number }) => {
		dispatch(tokensRequest({ per_page: perPage, current_page: selected + 1 }));
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	useEffect(() => {
		if (debouncedSearch) {
			dispatch(
				tokensRequest({
					per_page: perPage,
					search: debouncedSearch,
				}),
			);
		} else {
			dispatch(tokensRequest({ per_page: perPage }));
		}
	}, [debouncedSearch, dispatch]);

	return (
		<div className="main-content">
			<div className="panel-block panel-block--column">
				<div className="title-block">
					<p className="title">LP Token</p>
				</div>
				<div className="filter-group">
					<div className="panel-search">
						<div className="input-wrapper">
							<input
								className="input-item input-item--left-icon input-item--type2"
								type="text"
								placeholder="Search by token symbol or name"
								value={searchValue}
								onChange={handleChange}
							/>
							<span className="input-icon input-icon--left input-icon--color2">
								<SearchIcon />
							</span>
						</div>
					</div>
				</div>
			</div>
			<LpTokensTable list={tokens?.data} />
			<div className="pagination-block">
				{tokens && tokens?.last_page > 1 && (
					<Pagination pageCount={Number(tokens?.last_page)} onPageChange={handlePageClick} />
				)}
			</div>
		</div>
	);
};

export default LpTokens;
