export const tryOnEmpty = (data: any) => {
	const newArray: any = Object.entries(data).filter(
		(item: any) => item[1] !== '' && item[1] !== undefined,
	);
	const newPayload = newArray.reduce((acc: any, obj: any) => {
		const [one, two] = obj;
		acc[one] = two;
		return acc;
	}, {});

	return newPayload;
};
export const checkTransaction = (hash2: any, web3: any) =>
	new Promise((resolve) => {
		const funcChecker = async (hash: string) => {
			const result = await web3.eth.getTransactionReceipt(hash);
			setTimeout(() => {
				if (!result) {
					// eslint-disable-next-line @typescript-eslint/no-floating-promises
					funcChecker(hash);
				} else {
					resolve(result);
				}
			}, 1000);
		};
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		funcChecker(hash2);
	});
