import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { assetInitState, getAssetRequest } from 'redux/reducers/tokens/reducer';
import { getAsset, getAssetsLoader } from 'redux/reducers/tokens/selectors';
import Loader from 'ui/Loader';
import { useParams } from 'react-router';
import { EFeeCategory } from 'components/FeeManagement';
import FeeItem from 'components/FeeManagement/FeeItem';
import { Link } from 'react-router-dom';

const TokenFee = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getAssetsLoader);
	const { assetId } = useParams();
	const asset = useSelector(getAsset);

	const tokenFee = asset?.token_fee;

	useEffect(() => {
		if (assetId) dispatch(getAssetRequest(assetId));
		return () => {
			dispatch(assetInitState());
		};
	}, [assetId, dispatch]);

	return (
		<div className="content-block content-block--mt0">
			<div className="back back--type2">
				<Link to="/tokens/fee" className="back__button">
					<svg
						width="25"
						height="26"
						viewBox="0 0 25 26"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.37504 23.4166H15.625C20.8334 23.4166 22.9167 21.3333 22.9167 16.1249V9.87492C22.9167 4.66659 20.8334 2.58325 15.625 2.58325H9.37504C4.16671 2.58325 2.08337 4.66659 2.08337 9.87492V16.1249C2.08337 21.3333 4.16671 23.4166 9.37504 23.4166Z"
							stroke="#C0C0CF"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>

						<path
							d="M13.8125 16.6772L10.1459 13.0001L13.8125 9.323"
							stroke="#C0C0CF"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Link>
				<p className="title">{asset?.symbol}</p>
			</div>
			{tokenFee && (
				<div className="fee-container table--position">
					{loading && (
						<div className="loader-wrapper loader-wrapper__cover">
							<Loader />
						</div>
					)}

					{Object.keys(EFeeCategory).map((item) => (
						<FeeItem key={item} feeName={item} asset={asset} />
					))}
				</div>
			)}
		</div>
	);
};

export default TokenFee;
