/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ITokensStore,
	IGetAssetsParams,
	IAssetsResponse,
	IAsset,
	IEditFeePayload,
	IEditPriceInStableCoinPayload,
	ITokenUpdateRequest,
	ICalculateFeePayload,
	ICalculateFeeResponse,
	IGetAssetTokenDetailsRequest,
	IGetAssetTokenDetailsResponse,
	IGetTransactionTokensRequest,
	IGetTransactionTokensResponse,
	IGetAssetTokenTotalDetailsRequest,
} from './types';

// ==========================================:
export const initialState: ITokensStore = {
	assets: null,
	assetsCustom: null,
	assetsLoading: false,
	assetItemLoading: false,
	assetItem: null,
	blockchainAssetItemLoading: false,
	blockchainAssetItem: null,
	fee_in_tokens: {},
	token_details: null,
	tokenTotalDetails: null,
	tokenTransaction: null,
	tokenTransactionLoading: false,
	tokenTransactionClaimLoading: false,
	tokenTransactionClaim: null,
};

// ==========================================:
const wallets = createSlice({
	name: '@@tokens',
	initialState,
	reducers: {
		getAssetsRequest: (state, { payload }: PayloadAction<IGetAssetsParams>) => {
			const tokensState = state;
			tokensState.assetsLoading = true;
			if (tokensState.assets && payload.current_page === 1) {
				tokensState.assets.last_page = 1;
			}
		},
		getAssetsSuccess: (state, action: PayloadAction<IAssetsResponse>) => {
			const { payload } = action;
			const tokensState = state;
			tokensState.assets = payload;
			if (tokensState.assetsCustom) {
				tokensState.assetsCustom.data = payload.assets;
				tokensState.assetsCustom.total = payload.total;
			}
			tokensState.assetsLoading = false;
		},
		getAssetsCustomRequest: (state, action: PayloadAction<IGetAssetsParams>) => {
			const tokensState = state;
			tokensState.assetsLoading = true;
		},
		getAssetsCustomSuccess: (state, action: PayloadAction<IAssetsResponse>) => {
			const { payload } = action;
			const tokensState = state;
			tokensState.assetsCustom = payload;
			tokensState.assetsLoading = false;
		},
		getAssetRequest: (state, action: PayloadAction<string>) => {
			state.assetItemLoading = true;
		},
		getAssetSuccess: (state, { payload }: PayloadAction<IAsset>) => {
			state.assetItemLoading = false;
			state.assetItem = payload;
		},
		editFeeRequest: (state, action: PayloadAction<IEditFeePayload>) => {},
		editFeeSuccess: (state, { payload }: PayloadAction<IEditFeePayload>) => {
			const asset = state.assets?.assets.find((el) => el.id === payload.asset_id);
			if (asset) {
				asset.token_fee = {
					...asset.token_fee,
					transfer_commission_value: Number(payload.commission),
					transfer_commission_type: payload.commission_type,
					transfer_max_amount: Number(payload.max_amount),
				};
			}
		},
		editFeeCoinRequest: (state, action: PayloadAction<IEditFeePayload>) => {},

		editPriceInStableCoin: (state, { payload }: PayloadAction<IEditPriceInStableCoinPayload>) => {
			const asset = state.assets?.assets.find((el) => el.id === payload.asset_id);
			if (asset) {
				asset.price_in_stable_coin = payload.price_in_stable_coin;
			}
		},
		toggleActivityRequest: (state, action: PayloadAction<number>) => {},
		toggleActivitySuccess: (state, { payload }: PayloadAction<IAsset>) => {
			const asset = state.assets?.assets.find((el) => el.id === payload.id);
			if (asset) {
				asset.is_active_global = payload.is_active_global;
			}
		},
		getFeeInTokenRequest: (state, action: PayloadAction<ICalculateFeePayload>) => {},
		getFeeInTokenSuccess: (state, { payload }: PayloadAction<ICalculateFeeResponse>) => {
			if (state.fee_in_tokens) {
				state.fee_in_tokens[payload.fee_name] = payload.fee;
			}
		},
		editTokenRequest: (state, action: PayloadAction<ITokenUpdateRequest>) => {},
		assetInitState: (state) => {
			state.assetItem = null;
		},
		getAssetTokenDetailsRequest: (state, action: PayloadAction<IGetAssetTokenDetailsRequest>) => {},
		getAssetTokenDetailsSuccess: (
			state,
			{ payload }: PayloadAction<IGetAssetTokenDetailsResponse>,
		) => {
			const tokensState = state;
			tokensState.token_details = payload;
		},
		getTokenTransactionsRequest: (state, action: PayloadAction<IGetTransactionTokensRequest>) => {
			state.tokenTransactionLoading = true;
		},
		getTokenTransactionsSuccess: (
			state,
			{ payload }: PayloadAction<IGetTransactionTokensResponse>,
		) => {
			const tokensState = state;
			tokensState.tokenTransaction = payload;
			tokensState.tokenTransactionLoading = false;
		},
		getTokenTransactionsClaimRequest: (
			state,
			action: PayloadAction<IGetTransactionTokensRequest>,
		) => {
			state.tokenTransactionClaimLoading = true;
		},
		getTokenTransactionsClaimSuccess: (
			state,
			{ payload }: PayloadAction<IGetTransactionTokensResponse>,
		) => {
			const tokensState = state;
			tokensState.tokenTransactionClaim = payload;
			tokensState.tokenTransactionClaimLoading = false;
		},
		getTokenTotalDetailsRequest: (
			state,
			action: PayloadAction<IGetAssetTokenTotalDetailsRequest>,
		) => {
			state.tokenTransactionClaimLoading = true;
		},
		getTokenTotalDetailsSuccess: (
			state,
			{ payload }: PayloadAction<IGetAssetTokenDetailsResponse>,
		) => {
			const tokensState = state;
			tokensState.tokenTotalDetails = payload;
		},
	},
});

export default wallets.reducer;
export const {
	getAssetsRequest,
	getAssetsSuccess,
	getAssetsCustomRequest,
	getAssetsCustomSuccess,
	getAssetRequest,
	getAssetSuccess,
	editFeeRequest,
	editFeeSuccess,
	editFeeCoinRequest,
	editPriceInStableCoin,
	toggleActivityRequest,
	toggleActivitySuccess,
	getFeeInTokenRequest,
	getFeeInTokenSuccess,
	assetInitState,
	editTokenRequest,
	getAssetTokenDetailsRequest,
	getAssetTokenDetailsSuccess,
	getTokenTransactionsRequest,
	getTokenTransactionsSuccess,
	getTokenTransactionsClaimRequest,
	getTokenTransactionsClaimSuccess,
	getTokenTotalDetailsRequest,
	getTokenTotalDetailsSuccess,
} = wallets.actions;
