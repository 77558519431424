import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	IDividentsStore,
	TDividentsResponse,
	IExpensesResponse,
} from 'redux/reducers/dividents/types';

// ==========================================:
const getStatsState = (state: TStoreState): IDividentsStore => state.dividents;

// ====================================================:
export const getDividents = createSelector(
	[getStatsState],
	(divident: IDividentsStore): TDividentsResponse | null => divident.dividents,
);

export const getLoaderDividents = createSelector(
	[getStatsState],
	(divident: IDividentsStore): boolean => divident.dividentsLoading,
);
// ====================================================:
export const getExpencies = createSelector(
	[getStatsState],
	(divident: IDividentsStore): IExpensesResponse | null => divident.expenses,
);

export const getLoaderExpencies = createSelector(
	[getStatsState],
	(divident: IDividentsStore): boolean => divident.expensesLoading,
);
