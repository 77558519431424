import { FC } from 'react';
import { Page } from 'layouts/page';
import { NavTabs } from 'layouts-elements/NavTabs';
import { ITokenLayout } from './types';
import { navTabsRoutes } from './constants';

export const TokenLayout: FC<ITokenLayout> = ({ title, children }) => {
	return (
		<Page>
			<div className="main-content">
				<div className="title-block title-block--mb24">
					<p className="title">{title}</p>
				</div>
				<NavTabs routes={navTabsRoutes} />
				{children}
			</div>
		</Page>
	);
};
