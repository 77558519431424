import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { api } from 'services';
import { removeEmptyStrings } from 'services/utils/form';
import { notificationContainer } from 'services/utils/notificationContainer';
import Loader from 'ui/Loader';
import ButtonsSelect from 'ui/ButtonsSelect';
import FilePicker from 'ui/FilePicker';
import TokenCreationConfirm from 'components/Tokens/Create/ConfirmModal';
import { getAssetsRequest } from 'redux/reducers/tokens/reducer';
import { IValues } from './types';

export const CreateTokenForm: FC = () => {
	const dispatch = useDispatch();
	const [confirmOpened, setConfirmOpened] = useState<any>(null);
	const [logoSrc, setLogoSrc] = useState<string>();
	const initialValues: IValues = {
		name: '',
		decimals: 2,
		target_wallet_address: '',
		description: '',
		symbol: '',
		mint: false,
		total_supply: '',
		website_url: '',
		burn: false,
	};

	const validationSchema = yup.object().shape({
		name: yup.string().required('Name is required!'),
		decimals: yup
			.number()
			.min(0, 'Decimal can not be lower then 0')
			.max(18, 'Decimal can not be greater then 18')
			.typeError('Decimal can be only number')
			.required('Decimal is required'),
		target_wallet_address: yup.string().required('Wallet address is required'),
		symbol: yup.string().required('Symbol is required'),
		total_supply: yup
			.number()
			.typeError('Total supply must be an integer')
			.required('Total supply is required'),
	});

	const openConfirm = () => {
		setConfirmOpened(true);
	};

	const closeConfirm = () => {
		setConfirmOpened(false);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (_values, { setSubmitting }) => {
				closeConfirm();
				setSubmitting(true);
				try {
					const formData = new FormData();
					const nonEpltyValues = removeEmptyStrings<IValues>(_values);
					Object.keys(nonEpltyValues).forEach((key) => {
						if (!['logo', 'mint', 'burn'].includes(key)) {
							const value = nonEpltyValues[key as keyof Omit<IValues, 'logo' | 'mint' | 'burn'>];
							if (value) {
								formData.append(key, String(value));
							}
						}
					});
					formData.append('mint', _values.mint ? '1' : '0');
					formData.append('burn', _values.burn ? '1' : '0');
					if (_values.logo) {
						formData.append('logo', _values.logo);
					}
					await api.tokens.createToken(formData);
					notificationContainer('Token was created', 'success');
					dispatch(getAssetsRequest({}));
				} catch (err) {
					notificationContainer('Token creation failed', 'error');
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ errors, values, setValues, validateForm, setTouched, isSubmitting }) => {
				const onButtonsChange = (decimals?: number) => {
					setValues({ ...values, decimals });
				};

				const onLogoChange = (logo: File | null, src?: string) => {
					setValues({ ...values, logo });
					setLogoSrc(src);
				};

				const onCreateClick = async () => {
					setTouched({
						name: true,
						decimals: true,
						target_wallet_address: true,
						description: true,
						symbol: true,
						mint: true,
						total_supply: true,
						website_url: true,
						burn: true,
					});
					const validated = await validateForm();
					if (Object.keys(validated).length === 0) {
						openConfirm();
					}
				};

				return (
					<Form className="create-token-form">
						{isSubmitting ? (
							<div className="loader-wrapper">
								<Loader />
							</div>
						) : (
							<div className="create-token-container">
								<div className="create-token-box">
									<div className="input">
										<p className="input__name">Name</p>
										<div className="input-wrapper">
											<Field className="input-item" type="text" name="name" placeholder="Name" />
											<ErrorMessage className="error-message" name="name" component="span" />
										</div>
									</div>
									<div className="input">
										<p className="input__name">Decimal</p>
										<ButtonsSelect
											variants={[2, 6, 18]}
											value={values.decimals}
											onChange={onButtonsChange}
										/>
										<span className="error-message">{errors.decimals}</span>
									</div>
									<div className="input">
										<p className="input__name">Wallet address</p>
										<div className="input-wrapper">
											<Field
												className="input-item"
												type="text"
												name="target_wallet_address"
												placeholder="Enter address"
											/>
											<ErrorMessage
												className="error-message"
												name="target_wallet_address"
												component="span"
											/>
										</div>
									</div>
									<div className="input">
										<p className="input__name">Description</p>
										<div className="input-wrapper">
											<div className="textarea">
												<Field
													className="input-item"
													type="text"
													name="description"
													component="textarea"
													placeholder="Enter description"
												/>
												<ErrorMessage
													className="error-message"
													name="description"
													component="span"
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="create-token-box">
									<div className="input">
										<p className="input__name">Symbol</p>
										<div className="input-wrapper">
											<Field
												className="input-item"
												name="symbol"
												type="text"
												placeholder="Symbol"
											/>
											<ErrorMessage className="error-message" name="symbol" component="span" />
										</div>
									</div>
									<div className="input">
										<p className="input__name">Total supply value</p>
										<div className="input-wrapper">
											<Field
												className="input-item"
												name="total_supply"
												type="text"
												placeholder="Enter value"
											/>
											<ErrorMessage
												className="error-message"
												name="total_supply"
												component="span"
											/>
										</div>
									</div>
									<div className="input">
										<p className="input__name">Website URL</p>
										<div className="input-wrapper">
											<Field
												className="input-item"
												name="website_url"
												type="text"
												placeholder="Enter URL"
											/>
											<ErrorMessage className="error-message" name="website_url" component="span" />
										</div>
									</div>
									<div className="input">
										<p className="input__name">Add Logo</p>
										<FilePicker value={values.logo || null} onChange={onLogoChange} />
									</div>
								</div>
							</div>
						)}
						<div className="create-token-footer">
							<button
								disabled={isSubmitting}
								type="button"
								// eslint-disable-next-line @typescript-eslint/no-misused-promises
								onClick={onCreateClick}
								className="button button--w370 button--size2"
							>
								Create
							</button>
							<div className="switcher-block">
								<p className="switcher-block__name">Mint more tokens</p>
								<div className="switch">
									<label className="switch__label">
										<Field name="mint" component="input" type="checkbox" className="hidden" />
										<span className="switch__toggler" />
									</label>
								</div>
							</div>
							<div className="switcher-block">
								<p className="switcher-block__name">Burnable token</p>
								<div className="switch">
									<label className="switch__label">
										<Field name="burn" component="input" type="checkbox" className="hidden" />
										<span className="switch__toggler" />
									</label>
								</div>
							</div>
						</div>
						{confirmOpened && (
							<TokenCreationConfirm token={values} logoSrc={logoSrc} onCancel={closeConfirm} />
						)}
					</Form>
				);
			}}
		</Formik>
	);
};
