import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatsRequest } from 'redux/reducers/stats/reducer';
import { getStats } from 'redux/reducers/stats/selectors';
import {
	PercentSvg,
	B2cIcon,
	B2BIcon,
	Coins,
	RewardClaimIcon,
	RewardPoolIcon,
	RewardUnclaimIcon,
} from 'assets/custom-icons';

export const TotalStats: FC = () => {
	const dispatch = useDispatch();
	const stats = useSelector(getStats);

	useEffect(() => {
		dispatch(getStatsRequest());
	}, [dispatch]);

	return (
		<div className="main-content">
			<div className="title-block title-block--mb24">
				<p className="title">Total stats</p>
			</div>
			<div className="content-block content-block--mt0 ">
				<p className="block-subtitle">Infinity b2c - GATO</p>
				<div className="statistic-container">
					<div className="total-stat">
						<div className="total-stat__icon">
							<PercentSvg />
						</div>
						<div className="total-stat__text">
							<p>Total commission taken in GATO</p>
						</div>
						<p className="total-stat__value">{stats?.commission_gato}</p>
					</div>
					<div className="total-stat">
						<div className="total-stat__icon">
							<B2cIcon />
						</div>
						<div className="total-stat__text">
							<p>Total commission distributed to infinity b2c GATO</p>
						</div>
						<p className="total-stat__value">{stats?.user_profit_gato || '0'}</p>
					</div>
					<div className="total-stat">
						<div className="total-stat__icon">
							<Coins />
						</div>
						<div className="total-stat__text">
							<p>Dividends</p>
						</div>
						<p className="total-stat__value">{stats?.platform_profit_gato || '0'}</p>
					</div>
					<div className="total-stat">
						<div className="total-stat__icon">
							<RewardClaimIcon />
						</div>
						<div className="total-stat__text">
							<p>Reward claimed</p>
						</div>
						<p className="total-stat__value">{stats?.platform_profit_gato}</p>
					</div>
					<div className="total-stat">
						<div className="total-stat__icon">
							<RewardPoolIcon />
						</div>
						<div className="total-stat__text">
							<p>Funds in reward pool</p>
						</div>
						<p className="total-stat__value">{stats?.funds_in_reward_pool_gato}</p>
					</div>
					<div className="total-stat">
						<div className="total-stat__icon">
							<RewardUnclaimIcon />
						</div>
						<div className="total-stat__text">
							<p>Unclaimed reward</p>
						</div>
						<p className="total-stat__value">{stats?.unclaimed_reward_gato}</p>
					</div>
				</div>
			</div>
			<div className="content-block content-block--mt0 ">
				<p className="block-subtitle">Infinity b2c - GUSD</p>
				<div className="statistic-container">
					<div className="total-stat">
						<div className="total-stat__icon">
							<PercentSvg />
						</div>
						<div className="total-stat__text">
							<p>Total commission taken in GUSD</p>
						</div>
						<p className="total-stat__value">{stats?.commission_gusd}</p>
					</div>
					<div className="total-stat">
						<div className="total-stat__icon">
							<B2cIcon />
						</div>
						<div className="total-stat__text">
							<p>Total commission distributed to infinity b2c GUSD</p>
						</div>
						<p className="total-stat__value">{stats?.user_profit_gusd || '0'}</p>
					</div>
					<div className="total-stat">
						<div className="total-stat__icon">
							<Coins />
						</div>
						<div className="total-stat__text">
							<p>Dividends</p>
						</div>
						<p className="total-stat__value">{stats?.platform_profit_gusd || '0'}</p>
					</div>
					<div className="total-stat">
						<div className="total-stat__icon">
							<RewardClaimIcon />
						</div>
						<div className="total-stat__text">
							<p>Reward claimed</p>
						</div>
						<p className="total-stat__value">{stats?.platform_profit_gusd}</p>
					</div>
					<div className="total-stat">
						<div className="total-stat__icon">
							<RewardPoolIcon />
						</div>
						<div className="total-stat__text">
							<p>Funds in reward pool</p>
						</div>
						<p className="total-stat__value">{stats?.funds_in_reward_pool_gusd}</p>
					</div>
					<div className="total-stat">
						<div className="total-stat__icon">
							<RewardUnclaimIcon />
						</div>
						<div className="total-stat__text">
							<p>Unclaimed reward</p>
						</div>
						<p className="total-stat__value">{stats?.unclaimed_reward_gusd}</p>
					</div>
				</div>
			</div>
		</div>
	);
};
