import { FC, useEffect, useState } from 'react';
import usePagination from 'hooks/usePagination';
import Loader from 'ui/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionHistorys, getHistoryLoader } from 'redux/reducers/exchange/selectors';
import { getHIstoryTokensRequest } from 'redux/reducers/exchange/reducer';
import { Item } from './Item';
import { TLiquidity } from './types';

export const TransactionHistory: FC<TLiquidity> = ({ search, setTotalPages }) => {
	const historyList = useSelector(getTransactionHistorys);
	const historyLoader = useSelector(getHistoryLoader);
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	const totalPages = historyList?.last_page || 1;
	useEffect(() => {
		setTotalPages(totalPages);
		// eslint-disable-next-line
	}, [totalPages]);

	useEffect(() => {
		const body = {
			params: search
				? {
						per_page: perPage,
						current_page: currentPage,
						type: 'swap',
						search,
				  }
				: {
						per_page: perPage,
						current_page: currentPage,
						type: 'swap',
				  },
		};
		dispatch(getHIstoryTokensRequest(body));
	}, [dispatch, currentPage, search, perPage]);
	return (
		<div className="table table--exchange-swap-transactions">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Transaction number</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>User ID</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Time</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Pairs</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Buy Price</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Sell Price</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Transaction fee</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>TxHash</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Status</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body table--position">
				{historyLoader && (
					<div className="table-loader-wrapper">
						<Loader />
					</div>
				)}
				{!historyLoader && historyList?.data?.length
					? historyList?.data?.map((data) => <Item key={data.id} data={data} />)
					: null}
			</div>
		</div>
	);
};
