/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IPoolsStore,
	TPoolsRequest,
	TPoolsDataResponse,
	TLockLiquidityRequest,
	IPoolTransactionsResponse,
} from './types';

// ==========================================:
export const initialState: IPoolsStore = {
	pools: null,
	history: null,
	poolsLoading: false,
	historyLoader: false,
};

// ==========================================:
const pools = createSlice({
	name: '@@pools',
	initialState,
	reducers: {
		getPoolsRequest: (state, action: PayloadAction<TPoolsRequest>) => {
			const walletsRequestState = state;

			walletsRequestState.poolsLoading = true;
		},
		getPoolsSuccess: (state, action: PayloadAction<TPoolsDataResponse>) => {
			const { payload } = action;
			const walletsState = state;

			walletsState.pools = payload;
			walletsState.poolsLoading = false;
		},
		putLiquidity: (state, action: PayloadAction<TLockLiquidityRequest>) => {},
		getHIstoryRequest: (state, action: PayloadAction<TPoolsRequest>) => {
			const walletsRequestState = state;

			walletsRequestState.historyLoader = true;
		},
		getHistorySuccess: (state, action: PayloadAction<IPoolTransactionsResponse>) => {
			const { payload } = action;
			const walletsState = state;

			walletsState.history = payload;
			walletsState.historyLoader = false;
		},

		poolsInitState: () => initialState,
	},
});

export default pools.reducer;
export const {
	getPoolsRequest,
	getPoolsSuccess,
	poolsInitState,
	putLiquidity,
	getHIstoryRequest,
	getHistorySuccess,
} = pools.actions;
