import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	IExchangeStore,
	ISwapTransactionsResponse,
	TSwapDataResponse,
	TSwapTokenDataResponse,
} from 'redux/reducers/exchange/types';

// ==========================================:
const getExchangesState = (state: TStoreState): IExchangeStore => state.exchange;

// ====================================================:
export const getPairs = createSelector(
	[getExchangesState],
	(pairs: IExchangeStore): TSwapDataResponse | null => pairs.pairs,
);

export const getPoolsLoader = createSelector(
	[getExchangesState],
	(pools: IExchangeStore): boolean => pools.pairsLoading,
);

// ====================================================:
export const getTokens = createSelector(
	[getExchangesState],
	(pairs: IExchangeStore): TSwapTokenDataResponse | null => pairs.tokens,
);

export const getTokensLoader = createSelector(
	[getExchangesState],
	(pools: IExchangeStore): boolean => pools.tokensLoader,
);
export const getTransactionHistorys = createSelector(
	[getExchangesState],
	(pools: IExchangeStore): ISwapTransactionsResponse | null => pools.transactions,
);

export const getHistoryLoader = createSelector(
	[getExchangesState],
	(pools: IExchangeStore): boolean => pools.transactionsLoader,
);

export const getValue24 = createSelector(
	[getExchangesState],
	(pools: IExchangeStore): string | null => pools.volue24,
);
