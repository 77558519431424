/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStatsStore, TStatsResponse } from './types';

// ==========================================:
export const initialState: IStatsStore = {
	total_stats: null,
	statsLoading: false,
};

// ==========================================:
const stats = createSlice({
	name: '@@wstats',
	initialState,
	reducers: {
		getStatsRequest: (state) => {
			const statsState = state;

			statsState.statsLoading = true;
		},
		getStatsSuccess: (state, action: PayloadAction<TStatsResponse>) => {
			const { payload } = action;
			const statsState = state;

			statsState.total_stats = payload;
			statsState.statsLoading = false;
		},

		statsInitState: () => initialState,
	},
});

export default stats.reducer;
export const { getStatsRequest, getStatsSuccess, statsInitState } = stats.actions;
