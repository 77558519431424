import { getYear } from 'date-fns';

export const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const range = (start: number, end: number) => {
	return new Array(end - start).fill(undefined).map((d, i) => i + start);
};

export const years = range(1950, getYear(new Date()) + 1);

export const getDateFromMonthName = (monthName: string) => {
	const month = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const currentYear = new Date().getFullYear();
	const monthIndex = month.findIndex((mont: string) => {
		return String(mont).toLowerCase() === String(monthName).toLowerCase();
	});

	if (monthIndex !== -1) {
		const monthNumber = monthIndex + 1;
		const formattedMonth = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
		const formattedDate = `${currentYear}-${formattedMonth}-01`;
		return formattedDate;
	}
	return null;
};
