import { FC } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { IPropsModal } from './types';

export const ConfirmPopUp: FC<IPropsModal> = ({ openModal, openSetConfirm, handleSubmit }) => {
	return (
		<ModalWarapper openModal={openModal} setOpenModal={openSetConfirm}>
			<div className="popup popup--w360">
				<button type="button" className="popup__close" onClick={() => openSetConfirm(false)}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18 6L6 18"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M6 6L18 18"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
				<div className="popup-header">
					<p className="popup-header__title">Do you want Delete</p>
				</div>

				<div className="popup-body">
					<p className="popup-text popup-text--center">
						If you delete user all informtion is deleted
					</p>
				</div>

				<div className="popup-footer">
					<button
						onClick={() => openSetConfirm(false)}
						type="button"
						className="button button--full-width"
					>
						Cancel
					</button>
					<button onClick={handleSubmit} type="button" className="button button--full-width">
						Confirm
					</button>
				</div>
			</div>
		</ModalWarapper>
	);
};
