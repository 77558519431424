export const navTabsRoutes = [
	{
		path: '/tokens/list',
		label: 'List of tokens',
	},
	{
		path: '/tokens/fee',
		label: 'Transaction fee logic',
	},
	{
		path: '/tokens/verify',
		label: 'Verify tokens',
	},
	{
		path: '/tokens/tab',
		label: '3RD parties token manag',
	},
];
