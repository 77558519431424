import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IUsersApi } from './types';

export const users: IUsersApi = {
	getUsers: (params) => http.get(endpoint.users.GET_USERS, { params }).then(({ data }) => data),
	getUser: (payload) => http.get(endpoint.users.GET_USER(payload)).then(({ data }) => data),
	getResetPassword: (payload) => http.put(endpoint.users.RESET_PASSWORD(payload)),
	getProfit: (payload) => http.get(endpoint.users.GET_PROFIT(payload)).then(({ data }) => data),
	getGenerateKey: () => http.get(endpoint.users.GET_GENERATE_KEY).then(({ data }) => data),
	getDownloadsList: (payload) =>
		http.get(endpoint.users.OPEN_DOWNLOAD(payload)).then(({ data }) => data),
	deleteUser: (payload) =>
		http.delete(endpoint.users.DELETE_USER(payload)).then(({ data }) => data),
};
