import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { IStatsStore, TStatsResponse } from 'redux/reducers/stats/types';

// ==========================================:
const getStatsState = (state: TStoreState): IStatsStore => state.stats;

// ====================================================:
export const getStats = createSelector(
	[getStatsState],
	(stats: IStatsStore): TStatsResponse | null => stats.total_stats,
);

// ====================================================:
export const getLoaderStats = createSelector(
	[getStatsState],
	(stats: IStatsStore): boolean => stats.statsLoading,
);
