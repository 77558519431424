import { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getAssets, getAssetsLoader } from 'redux/reducers/tokens/selectors';
import Pagination from 'ui/Pagination';
import Loader from 'ui/Loader';
import { IAsset } from 'redux/reducers/tokens/types';
import TokensTableCell from './TokensTableCell';

const TokensTable: FC = () => {
	const assetsResponse = useSelector(getAssets);
	const loading = useSelector(getAssetsLoader);
	const scrollRef = useRef(null);
	const { assets = [], last_page } = assetsResponse || {};

	const assetsList: IAsset[][] = [];
	const size = 3;
	for (let i = 0; i < Math.ceil(assets.length / size); i += 1) {
		assetsList[i] = assets.slice(i * size, i * size + size);
	}

	return (
		<>
			<div className="table-block" ref={scrollRef}>
				<div className="table-wrapper">
					<div className="table table--fee-tokens-list">
						<div className="table-header">
							<div className="tr">
								<div className="td-group">
									<div className="td">
										<div className="td-name">
											<p>Token</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Percentage / Flat</p>
										</div>
									</div>
								</div>
								<div className="td-group">
									<div className="td">
										<div className="td-name">
											<p>Token</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Percentage / Flat</p>
										</div>
									</div>
								</div>
								<div className="td-group">
									<div className="td">
										<div className="td-name">
											<p>Token</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Percentage / Flat</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body table--position">
							{loading && (
								<div className="loader-wrapper loader-wrapper__cover">
									<Loader />
								</div>
							)}

							{assetsList.map((row) => (
								<div className="tr" key={Math.random()}>
									{row.map((item) => (
										<TokensTableCell key={item.id} {...item} />
									))}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			{!!assetsList.length && last_page && last_page > 1 && (
				<Pagination pageCount={last_page} refElement={scrollRef} />
			)}
		</>
	);
};

export default TokensTable;
