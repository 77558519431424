import { FC, useEffect } from 'react';
import usePagination from 'hooks/usePagination';
import Loader from 'ui/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getStaking, getStakingLoader } from 'redux/reducers/staking/selectors';
import { getStakingRequest } from 'redux/reducers/staking/reducer';
import { Item } from './Item';

export const StakingTransactions: FC = () => {
	const stakingList = useSelector(getStaking);
	const stakingLoader = useSelector(getStakingLoader);
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	// const totalPages = stakingList?.transactions.last_page || 1;

	useEffect(() => {
		const params = {
			current_page: currentPage,
			per_page: perPage,
		};
		dispatch(getStakingRequest({ params }));
		// eslint-disable-next-line
	}, [dispatch, currentPage, perPage]);
	return (
		<div className="table table--staking-transactions">
			<div className="table-header">
				<div className="tr">
					<div className="td td--left">
						<div className="td-name">
							<p>User ID</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Operation type</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Operation amount</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Reward from Staking</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Total comision</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>
								Total commission <br /> distributed
							</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>
								Profit Gato <br /> Chain
							</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Transaction hash</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Transaction staus</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body table--position">
				{stakingLoader && (
					<div className="table-loader-wrapper">
						<Loader />
					</div>
				)}
				{!stakingLoader && stakingList?.transactions?.data?.length
					? stakingList?.transactions?.data?.map((el) => <Item key={el.id} {...el} />)
					: null}
			</div>
		</div>
	);
};
