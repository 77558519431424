import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokensRequest } from 'redux/reducers/exchange/reducer';
import { getTokens, getTokensLoader } from 'redux/reducers/exchange/selectors';
import Loader from 'ui/Loader';
import usePagination from 'hooks/usePagination';
import { Item } from './item';
import { TLiquidity } from './types';

export const Tokens: FC<TLiquidity> = ({ search, setTotalPages }) => {
	const dispatch = useDispatch();
	const tokensList = useSelector(getTokens);
	const tokensLoader = useSelector(getTokensLoader);
	const { perPage, currentPage } = usePagination();
	const totalPages = tokensList?.last_page || 1;
	useEffect(() => {
		setTotalPages(totalPages);
		// eslint-disable-next-line
	}, [totalPages]);

	useEffect(() => {
		const body = {
			params: search
				? {
						per_page: perPage,
						current_page: currentPage,
						search,
				  }
				: {
						per_page: perPage,
						current_page: currentPage,
				  },
		};
		dispatch(getTokensRequest(body));
	}, [dispatch, currentPage, perPage, search]);
	return (
		<div className="table table--exchange-swap-tokens">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Num.</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Tokens</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Available Pairs</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Activate / Deactivate</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body table--position">
				{tokensLoader && (
					<div className="table-loader-wrapper">
						<Loader />
					</div>
				)}
				{!tokensLoader && tokensList?.data?.length
					? tokensList?.data?.map((pool, id) => <Item key={pool.id} pool={pool} idNum={id + 1} />)
					: null}
			</div>
		</div>
	);
};
