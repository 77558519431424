import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	ITokensStore,
	IAssetsResponse,
	IGetAssetTokenDetailsResponse,
	IGetTransactionTokensResponse,
} from './types';

// ==========================================:
const getAuthState = (state: TStoreState): ITokensStore => state.tokens;
export const getTokens = createSelector([getAuthState], (tokens: ITokensStore) => tokens);

// ====================================================:
export const getAssets = (state: TStoreState) => getAuthState(state).assets;

export const getAssetsCustom = createSelector(
	[getTokens],
	(state: ITokensStore): IAssetsResponse | null => state.assetsCustom,
);

export const getAssetsLoader = createSelector(
	[getTokens],
	(state: ITokensStore): boolean => state.assetsLoading,
);

export const getAssetsDetails = createSelector(
	[getTokens],
	(state: ITokensStore): IGetAssetTokenDetailsResponse | null => state.token_details,
);

export const getTransactionsDetails = createSelector(
	[getTokens],
	(state: ITokensStore): IGetTransactionTokensResponse | null => state.tokenTransaction,
);

export const getTransactionsLoading = createSelector(
	[getTokens],
	(state: ITokensStore): boolean => state.tokenTransactionLoading,
);

export const getTransactionsClaimDetails = createSelector(
	[getTokens],
	(state: ITokensStore): IGetTransactionTokensResponse | null => state.tokenTransactionClaim,
);

export const getTransactionsClaimLoading = createSelector(
	[getTokens],
	(state: ITokensStore): boolean => state.tokenTransactionClaimLoading,
);

export const getTokenTotalDetails = createSelector(
	[getTokens],
	(state: ITokensStore): IGetAssetTokenDetailsResponse | null => state.tokenTotalDetails,
);

export const getAsset = (state: TStoreState) => getAuthState(state).assetItem;
export const getFeeInToken = (state: TStoreState) => getAuthState(state).fee_in_tokens;
