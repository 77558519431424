import { ReferralManagement } from 'components/ReferralManagement';
import { Page } from 'layouts/page';

const ReferralManagementPage = () => {
	return (
		<Page>
			<ReferralManagement />
		</Page>
	);
};

export default ReferralManagementPage;
