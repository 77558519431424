import { FC, useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Page } from 'layouts/page';
import { navTabsRoutes } from 'layouts/Token/constants';
import { NavTabs } from 'layouts-elements/NavTabs';
import TokensTable from 'components/Tokens/Create/TokensTable';
import { useDebounce } from 'hooks/useDebounce';
import usePagination from 'hooks/usePagination';
import { getAssetsRequest } from 'redux/reducers/tokens/reducer';
import { useLocation } from 'react-router';

export const TokenListPage: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [search, setSearch] = useState('');
	const { perPage, currentPage } = usePagination();
	const debouncedSearch = useDebounce(search, 500);
	useEffect(() => {
		dispatch(
			getAssetsRequest({
				per_page: perPage,
				current_page: currentPage,
				search_value: debouncedSearch || undefined,
			}),
		);
	}, [currentPage, dispatch, perPage, debouncedSearch]);

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
		location.search = '1';
	};

	return (
		<Page>
			<div className="main-content token-list">
				<div className="panel-block">
					<div className="title-block">
						<p className="title">All Tokens in Chain</p>
					</div>
					<div className="filter-group">
						<div className="panel-search">
							<div className="input-wrapper">
								<input
									className="input-item input-item--left-icon input-item--type2"
									type="text"
									placeholder="Search tokens"
									value={search}
									onChange={handleSearch}
								/>
								<span className="input-icon input-icon--left input-icon--color2">
									<svg
										width="17"
										height="17"
										viewBox="0 0 17 17"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z"
											stroke="#8E8EA9"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M16.5 16.5L15 15"
											stroke="#8E8EA9"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
							</div>
						</div>
					</div>
				</div>
				<NavTabs routes={navTabsRoutes} />
				<TokensTable />
			</div>
		</Page>
	);
};
