import { FC, useState, MouseEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'ui/Pagination';
import { Monintor, Diamant, Miracl, ButtonIcon } from 'assets/custom-icons';
import { getStaking } from 'redux/reducers/staking/selectors';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { getDividentsRequest } from 'redux/reducers/dividents/reducer';
import { getDividents, getLoaderDividents } from 'redux/reducers/dividents/selectors';
import { getDateFromMonthName } from 'services/utils/ditePicker';
import { SelectDropdownPeriod } from 'ui/SelectDropdownPeriod';
import usePagination from 'hooks/usePagination';
import { Expencies } from './Expencies';
import { Expenses } from './PopUp/Expenses';
import { StakingTransactions } from './StakingTransactions';

export const month = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const currentDate = new Date();
const currentMonth = currentDate.getMonth();

export const Dividents: FC = () => {
	const dispatch = useDispatch();
	const [currentTab, setCurrentTab] = useState<string>('total');
	const stakingList = useSelector(getStaking);
	const dividentsList = useSelector(getDividents);
	const [searchField, setSearchField] = useState<any>(month[currentMonth]);

	const totalPages = stakingList?.transactions.last_page || 1;
	const moneyFormat = useMoneyFormat();
	const [openModal, setOpenModal] = useState(false);
	const { perPage, currentPage } = usePagination();

	const closeModal = () => {
		setOpenModal(false);
	};

	useEffect(() => {
		const params = {
			current_page: Number(currentPage),
			per_page: Number(perPage),
			date: String(getDateFromMonthName(String(searchField).toLowerCase())),
		};
		dispatch(getDividentsRequest({ params }));
		// eslint-disable-next-line
	}, [dispatch, currentPage, perPage, searchField]);

	const tabs = {
		expencies: <Expencies />,
		total: <StakingTransactions />,
	};
	const handleTab = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setCurrentTab(name);
	};

	return (
		<div className="main-content">
			<div className="panel-block panel-block--dividents">
				<div className="title-block">
					<p className="title">Dividends</p>
				</div>
				{currentTab === 'total' && (
					<div className="filter-group">
						<div className="country-filter">
							<SelectDropdownPeriod
								options={month}
								onChange={setSearchField}
								value={searchField}
								name="month"
							/>
						</div>
					</div>
				)}
			</div>
			<div className="tabs">
				<button
					type="button"
					onClick={handleTab}
					name="total"
					className={`tabs__item ${currentTab === 'total' ? 'active' : ''}`}
				>
					Total stats
				</button>
				<button
					type="button"
					onClick={handleTab}
					name="expencies"
					className={`tabs__item ${currentTab === 'expencies' ? 'active' : ''}`}
				>
					Expencies
				</button>
			</div>
			<div className="content-block content-block--mt0 ">
				{currentTab === 'total' && dividentsList && (
					<div className="statistic-container statistic-container--staking statistic-container--dividents">
						<div className="total-stat">
							<div className="total-stat__header">
								<div className="total-stat__icon">
									<Monintor />
								</div>
								<div className="total-stat__text">
									<p>Total dividends</p>
								</div>
							</div>
							<div className="total-stat__content">
								<div className="text-stat__block">
									<p className="total-stat__text">GATO</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.totalGato?.GATO || '', 2, 18)}
									</p>
								</div>
								<div className="text-stat__block">
									<p className="total-stat__text">GAMB</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.totalGato?.GAMB || '', 2, 18)}
									</p>
								</div>
								<div className="text-stat__block">
									<p className="total-stat__text">GTANK</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.totalGato?.GTANK || '', 2, 18)}
									</p>
								</div>
							</div>
						</div>
						<div className="total-stat">
							<div className="total-stat__header">
								<div className="total-stat__icon">
									<Diamant />
								</div>
								<div className="total-stat__text">
									<p>Total reward distributed from dividents</p>
								</div>
							</div>
							<div className="total-stat__content">
								<div className="text-stat__block">
									<p className="total-stat__text">GATO</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.rewardDistributed?.GATO || '', 2, 18)}
									</p>
								</div>
								<div className="text-stat__block">
									<p className="total-stat__text">GAMB</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.rewardDistributed?.GAMB || '', 2, 18)}
									</p>
								</div>
								<div className="text-stat__block">
									<p className="total-stat__text">GTANK</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.rewardDistributed?.GTANK || '', 2, 18)}
									</p>
								</div>
							</div>
						</div>
						<div className="total-stat">
							<div className="total-stat__header">
								<div className="total-stat__icon">
									<Miracl />
								</div>
								<div className="total-stat__text">
									<p>Unclaimed dividents reward</p>
								</div>
							</div>
							<div className="total-stat__content">
								<div className="text-stat__block">
									<p className="total-stat__text">GATO</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.unclaimedDividendReward?.GATO || '', 2, 18)}
									</p>
								</div>
								<div className="text-stat__block">
									<p className="total-stat__text">GAMB</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.unclaimedDividendReward?.GAMB || '', 2, 18)}
									</p>
								</div>
								<div className="text-stat__block">
									<p className="total-stat__text">GTANK</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.unclaimedDividendReward?.GTANK || '', 2, 18)}
									</p>
								</div>
							</div>
						</div>
						<div className="total-stat">
							<div className="total-stat__header">
								<div className="total-stat__icon">
									<Miracl />
								</div>
								<div className="total-stat__text">
									<p>Pool balances</p>
								</div>
							</div>
							<div className="total-stat__content">
								<div className="text-stat__block">
									<p className="total-stat__text">GATO</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.poll_balances?.GATO || '', 2, 8)}
									</p>
								</div>
								<div className="text-stat__block">
									<p className="total-stat__text">GAMB</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.poll_balances?.GAMB || '', 2, 8)}
									</p>
								</div>
								<div className="text-stat__block">
									<p className="total-stat__text">GTANK</p>
									<p className="total-stat__value">
										{moneyFormat(dividentsList?.poll_balances?.GTANK || '', 2, 8)}
									</p>
								</div>
							</div>
						</div>
					</div>
				)}
				{currentTab === 'expencies' && (
					<div className="statistic-container--button">
						<button
							onClick={() => setOpenModal(true)}
							type="button"
							className="button button--fw-500 button--width-auto2"
						>
							Add Expenses
							<span className="button__icon">
								<ButtonIcon />
							</span>
						</button>
					</div>
				)}
				{currentTab === 'total' && (
					<p className="block-subtitle block-subtitle--mt-16">Staking transactions</p>
				)}
				<div className="table-block table-block--inside-content">
					<div className="table-wrapper">{tabs[currentTab as keyof typeof tabs]}</div>
				</div>
			</div>
			{totalPages > 1 && <Pagination pageCount={totalPages} />}
			<Expenses openModal={openModal} closeModal={closeModal} />
		</div>
	);
};
