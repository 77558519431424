import { FC, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import Pagination from 'ui/Pagination';
import { Monintor, Diamant, Miracl } from 'assets/custom-icons';
import { getStaking } from 'redux/reducers/staking/selectors';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { StakingTransactions } from './StakingTransactions';

export const Staking: FC = () => {
	// const totalPages = walletsList?.last_page || 1;
	const [currentTab, setCurrentTab] = useState<string>('total');
	const stakingList = useSelector(getStaking);
	const totalPages = stakingList?.transactions.last_page || 1;
	const moneyFormat = useMoneyFormat();

	const tabs = {
		// general: <Liquidity  />,
		total: <StakingTransactions />,
	};
	const handleTab = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setCurrentTab(name);
	};

	return (
		<div className="main-content">
			<div className="title-block title-block--mb24">
				<p className="title">Staking</p>
			</div>
			<div className="tabs">
				{/* <button
					type="button"
					onClick={handleTab}
					name="general"
					className={`tabs__item ${currentTab === 'general' ? 'active' : ''}`}
				>
					General
				</button> */}
				<button
					type="button"
					onClick={handleTab}
					name="total"
					className={`tabs__item ${currentTab === 'total' ? 'active' : ''}`}
				>
					Total stats
				</button>
			</div>
			<div className="content-block content-block--mt0 ">
				{currentTab === 'total' && (
					<div className="statistic-container statistic-container--staking">
						<div className="total-stat">
							<div className="total-stat__icon">
								<Monintor />
							</div>
							<div className="total-stat__text">
								<p>Total GATO staked</p>
							</div>
							<p className="total-stat__value">{stakingList?.totalGatoStaked}</p>
						</div>
						<div className="total-stat">
							<div className="total-stat__icon">
								<Diamant />
							</div>
							<div className="total-stat__text">
								<p>Total reward distributed from staking</p>
							</div>
							<p className="total-stat__value">
								{moneyFormat(stakingList?.totalRewardDistributed[0]?.reward, 2, 18)}
							</p>
						</div>
						<div className="total-stat">
							<div className="total-stat__icon">
								<Miracl />
							</div>
							<div className="total-stat__text">
								<p>Unclaimed staking reward</p>
							</div>
							<p className="total-stat__value">
								{moneyFormat(stakingList?.currentBalanceAndReward[0]?.reward, 2, 18)}
							</p>
						</div>
					</div>
				)}
				{currentTab === 'total' && (
					<p className="block-subtitle block-subtitle--mt-16">Staking transactions</p>
				)}
				<div className="table-block table-block--inside-content">
					<div className="table-wrapper">{tabs[currentTab as keyof typeof tabs]}</div>
				</div>
			</div>
			{totalPages > 1 && <Pagination pageCount={totalPages} />}
		</div>
	);
};
