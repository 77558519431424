/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import FormControl from 'ui/Formik/FormConrol';
import { IAdminInput } from './types';
// ==========================================:
const Input: FC<IAdminInput> = (props) => {
	const {
		ariaLabel,
		type,
		name,
		field,
		placeholder,
		inputMode,
		onKeyUp,
		onChange,
		form,
		someName,
	} = props;
	const onValueChange = (phoneNumber: string): void => {
		form.setFieldValue(field?.name, phoneNumber);

		if (onChange !== null) {
			onChange(phoneNumber);
		}
	};
	return (
		<FormControl ariaLabel={ariaLabel} {...props}>
			<input
				className={`input-item ${name === 'password' ? 'input-item--right-icon' : ''}`}
				{...field}
				type={type}
				placeholder={placeholder}
				onKeyUp={onKeyUp}
				readOnly={someName === 'disable'}
				inputMode={inputMode || null}
			/>
		</FormControl>
	);
};

export default Input;
