import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	IReferralStore,
	TReferralsDataResponse,
	IReferralUser,
} from 'redux/reducers/referral/types';

// ==========================================:
const getWalletsState = (state: TStoreState): IReferralStore => state.referral;

// ====================================================:
export const getReferral = createSelector(
	[getWalletsState],
	(referral: IReferralStore): TReferralsDataResponse | null => referral.referrals,
);

export const getReferralLoader = createSelector(
	[getWalletsState],
	(referral: IReferralStore): boolean => referral.referralsLoading,
);

export const getUserReferralLoader = createSelector(
	[getWalletsState],
	(referral: IReferralStore): boolean => referral.userReferralsLoading,
);

export const getUserReferral = createSelector(
	[getWalletsState],
	(referral: IReferralStore): IReferralUser | null => referral.userReferral,
);

export const getUserReferralList = createSelector(
	[getWalletsState],
	(referral: IReferralStore): Array<IReferralUser> | null => referral.listOfReferral,
);
